import React from 'react';
import { Row, Container, Col ,Button} from 'reactstrap';
import { Link } from 'react-router-dom';

class PaymentConfirmation extends React.Component {
    constructor(props) {
      super(props);

      this.state = {  };
    }



    render() {
        return (
            <section className="section section-lg section-shaped ">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="text-white pb-10 mt-5" fluid="true" style={{ height:"auto", paddingLeft: "10%", paddingRight: "10%" }}>
              <div className="col px-0">
                <Row>
                  <Col lg="2" className="order-md-1"></Col>
                  <Col lg="8" className="order-md-2 text-center">
                    <div>                    
                        <img alt="Payment Confirmation" src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Fverified.svg?alt=media&token=03769393-3527-487b-a514-9685a8b9763f" height="50%" width="50%"/>
                    </div>
                    <h6 className="text-center mt-5">Payment Confirmed ! We have Recieved  <b>&#8377; {this.props.courseFee}</b>  for the course   <b>{this.props.course}</b>  under  <b>{this.props.trainingMode}</b>.</h6>
                    <h6 className="text-center mt-3">You will be receiving an Email regarding the course schedule shorlty from our team.</h6>
                    <Button
                      type="button"
                      color="secondary"
                      className="mt-5"
                    ><Link to ="/profile">
                     View my Dashboard</Link>
                    </Button>
                  </Col>
                  <Col lg="2" className="order-md-3"></Col>

                </Row>
              </div>
            </Container>
          </section>
        )
    }
}

export default PaymentConfirmation;