/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import CoursesList from "views/examples/CoursesList.js";
import ToolsCoursesList from "views/examples/toolsCoursesList.js";
import Register from "views/examples/Register.js";
import CourseDetail from "views/examples/courseDetail";
import EdutainmentLanding from 'views/edutainment/edutainmentLanding';
// import SteamLanding from 'views/edutainment/steam';
import Profile from 'views/examples/Profile';
// import LearnToCode from 'views/edutainment/learnToCode';
// import AdminPage from 'views/profile/admin';
import { withAuthentication ,withEmailVerification} from '../session';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import PrivacyPolicy from "views/policies/privacy";
import AboutUs from "views/policies/aboutus";
import TermsofUse from "views/policies/termsandconditions";
import Refunds from "views/policies/refunds";
import { compose } from 'recompose';
// import PaymentConfirmation from "../payment/paymentConfirmation";
import { AuthUserContext } from '../session';
import Investor from "views/policies/investor";



const Routing = (props) => (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <ProtectedRoutes authUser={authUser} /> : <NonProtectedRoutes />
      }
    </AuthUserContext.Consumer>
  );
  
  const ProtectedRoutes = ({ authUser }) =>
    (
        <Switch>
       
        <Route
            path="/profile"
            exact
            component = {Profile} />
        <Route 
            path="/" 
            exact 
            component = {Landing} />
        <Route
            path="/internship/robotics"
            exact
            component = {CoursesList} />
        />
        <Route
            path="/courses/programming-application-tools-domain"
            exact
            component = {ToolsCoursesList} />
        />
        <Route
            path="/internship/robotics/embedded-robotics-internship-in-india"
            exact
            component = {CourseDetail} />
        />
        <Route
            path="/internship/robotics/iot-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/raspberry-pi-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/roboticarm-internship-in-india"
            exact
            component = {CourseDetail} />
        {/* <Route
            path="/internship/robotics/roboticsmodelling-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/imageprocessing-internship-in-india"
            exact
            component = {CourseDetail} /> */}
        <Route
            path="/internship/robotics/embeddedsystems-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/courses/programming-application-tools-domain/sql"
            exact
            component = {CourseDetail} />
        <Route
            path="/courses/programming-application-tools-domain/tableau"
            exact
            component = {CourseDetail} />
        
        <Route
            path="/edutainment"
            exact
            component = {EdutainmentLanding} />
        {/* <Route
            path="/steam"
            exact
            component = {SteamLanding} />
        <Route
            path="/learn-to-code"
            exact
            component = {LearnToCode} /> */}

        {/* Privacy policies */}

        <Route
            path="/about-us"
            exact
            component = {AboutUs} />
        <Route
            path="/investors"
            exact
            component = {Investor} />
        <Route
            path="/privacy"
            exact
            component = {PrivacyPolicy} />
        <Route
            path="/termsofuse"
            exact
            component = {TermsofUse} />
        <Route
            path="/refunds"
            exact
            component = {Refunds} />
        <Redirect to="/" />
        </Switch>
    );
  
  const NonProtectedRoutes = () => (
    <Switch>
        <Route 
            path="/" 
            exact 
            component = {Landing} />
        <Route 
            path="/login" 
            exact 
            component = {Login} />
        <Route
            path="/register"
            exact
            component = {Register} />
        <Route
            path="/internship/robotics"
            exact
            component = {CoursesList} />
        />
        <Route
            path="/courses/programming-application-tools-domain"
            exact
            component = {ToolsCoursesList} />
        />
        <Route
            path="/internship/robotics/embedded-robotics-internship-in-india"
            exact
            component = {CourseDetail} />
        />
        <Route
            path="/internship/robotics/iot-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/raspberry-pi-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/roboticarm-internship-in-india"
            exact
            component = {CourseDetail} />
        {/* <Route
            path="/internship/robotics/roboticsmodelling-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/internship/robotics/imageprocessing-internship-in-india"
            exact
            component = {CourseDetail} /> */}
        <Route
            path="/internship/robotics/embeddedsystems-internship-in-india"
            exact
            component = {CourseDetail} />
        <Route
            path="/courses/programming-application-tools-domain/sql"
            exact
            component = {CourseDetail} />
        <Route
            path="/courses/programming-application-tools-domain/tableau"
            exact
            component = {CourseDetail} />
        
        <Route
            path="/edutainment"
            exact
            component = {EdutainmentLanding} />
        {/* <Route
            path="/steam"
            exact
            component = {SteamLanding} />
        <Route
            path="/learn-to-code"
            exact
            component = {LearnToCode} /> */}

        {/* Privacy policies */}

        <Route
            path="/about-us"
            exact
            component = {AboutUs} />
        <Route
            path="/investors"
            exact
            component = {Investor} />
        <Route
            path="/privacy"
            exact
            component = {PrivacyPolicy} />
        <Route
            path="/termsofuse"
            exact
            component = {TermsofUse} />
        <Route
            path="/refunds"
            exact
            component = {Refunds} />
        <Redirect to="/" />
        </Switch>
  );

  

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isUserLogin : false,
        }
    }
    componentDidMount() {
        const user = this.props.firebase.getCurrentUser();
        this.setState({isUserLogin : user})
    }

    render() {
        return (
            
            <BrowserRouter>
                <DemoNavbar />
                <Routing />
                <SimpleFooter />
            </BrowserRouter>
        )
    }
}

export default compose(
    withEmailVerification,withAuthentication)(App);
