/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {  Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FSample%20Certification.png?alt=media&token=a50c46bd-d8bf-4ea8-a9ca-3436522612d3",
    altText: "sample certificate",
    caption: "",
    header: ""
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FCertificate%20of%20Appericiation.png?alt=media&token=0d4635af-e2f2-4371-9fbc-a8c4bb9dc055",
    altText: "best performer",
    caption: "",
    header: ""
  }
];

class SampleCertificate extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped bg-secondary">
          
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className=" font-weight-light">
                  Get Certified!
                </h1>
                <p className="lead  mt-4">
                  Aviate also provides some other certifications by partner companies like Microsoft, Hp, Amazon etc.
                </p>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default SampleCertificate;
