import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Progress
} from "reactstrap";

// core components
import { withAuthentication , withEmailVerification} from '../../session';
import { compose } from 'recompose';

// index page sections

class EdutainmentLanding extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      on : false
    }
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    setInterval(() => {
      this.setState({on : !this.state.on})
    }, 1000);
  }
  render() {
    return (
      <>
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section pb-250" style={{backgroundColor:"rgb(17,43,80)"}}>
              <div className="shape">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex " fluid="true" style={{ marginLeft: "5%", marginRight: "5%"}}>
                <div >
                  <Row>
                    <Col lg="6" className="pl-5 mt-5 order-md-2">
                      <div style={{marginTop:"15vh"}}>
                      <h1 className="display-4 text-white">
                        <span style={{fontSize:"48px"}}>Edutainment{" "}</span>
                        <span>Get entertained while you get educated</span>
                      </h1>
                      <p className="lead text-white">
                        A suite of STEAM, coding fundamentals and curriculum-aligned lessons exclusively designed for age group of 7-15 years. 
                      </p>
                      {/* <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="success"
                          href="/edutainment"
                        >
                          <span className="btn-inner--text">Learn to Code</span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0 ml-1"
                          color="success"
                          href="/edutainment"
                        >
                          <span className="btn-inner--text">
                            DIY Learning
                          </span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0 ml-1"
                          color="success"
                          href="/edutainment"
                        >
                          <span className="btn-inner--text">
                            STEAM
                          </span>
                        </Button>
                      </div> */}
                      </div>
                    </Col>
                    <Col lg="6" className="order-md-1">
                      <img 
                      width ="100%"
                      height = "100%"
                      alt ="infographics"
                      src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2Fedutainment1.svg?alt=media&token=bf27d226-52ad-43de-805f-446080aec508" />
                    </Col>
                  </Row>
                  <Row style={{ backgroundColor: "rgb(255,204,0)", marginLeft:"5%",marginRight:"5%"}} className=" floating">
                    <Col lg="2">

                    </Col>
                    <Col lg="8">
                    <div className="progress-wrapper">
                      <div className="progress-info">
                        <div className="progress-label mt--3">
                          <h3>Launching Soon...!</h3>
                        </div>
                        <div className="progress-percentage mt--4">
                          <span>60%</span>
                        </div>
                      </div>
                      <Progress max="100" value="60" color="default" />
                    </div>
                    </Col>
                    <Col lg="2">

                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-primary text-uppercase">
                            STEAM
                          </h6>
                          <p className="description mt-3">
                            Our STEAM course is a suite of standards-aligned Lesson Packs, Starter Lessons, Standards Alignment maps and Grade overviews to support your planning. Ranging from Kindergarten to Grade 5, our STEAM course provides everything you need to implement STEAM in your classroom.
                          </p>

                          {/* <Button
                            className="mt-4"
                            color="primary"
                            href="/steam"
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-success text-uppercase">
                            Logical Coding
                          </h6>
                          <p className="description mt-3">
                            Follow us on a mission through CyberAviate, providing students with the opportunity to learn Computer Science concepts, code a program, reflect on and debug it.
                          </p>

                          {/* <Button
                            className="mt-4"
                            color="success"
                            href="/learn-to-code"                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-warning text-uppercase">
                            DIY Learning
                          </h6>
                          <p className="description mt-3">
                            DIY Learning Challenges Project Based Learning 75+ Task Cards for STEAM and logical Coding
                          </p>

                          {/* <Button
                            className="mt-4"
                            color="warning"
                            href="maker"
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            {/* <Container fluid="true" style={{marginRight:"10%",marginleft:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col lg="2" className="order-md-1"></Col>
                <Col lg="4" className="order-md-2 text-center ">
                  <div className="pl-5 ">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Introducing the first ever Education with Entertainment platform”</h1>
                    <p>
                      Make use of this platform with the main focus on three main areas involving Learn to Code, DIY Learning and STEAM Education.
                    </p>                    
                  </div>
                </Col>
                <Col lg="6" className="order-md-3 text-center">
                  {this.state.on?<img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2Fbulb.svg?alt=media&token=82131e45-e624-41ee-917c-110434ee84a2" />
                  :<img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2Fbulb1.svg?alt=media&token=55e09b80-001d-454c-94ac-88f4e8317ff9" />
                  }
                </Col>
              </Row>
            </Container>
            <Container fluid="true" style={{marginRight:"10%",marginleft:"10%"}}>
              <Row className="row-grid align-items-center pb-100">
                <Col lg="1" className="order-md-1"></Col>
                <Col lg="4" className="order-md-4 text-center">
                  <div className="pl-5 " >
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Let your children play a whole different educational games”</h1>
                    <p>
                      DIY learning provided by Aviate enables your children's analytical thinking by designing their own educational games and puzzles using robotics and programming as a medium.
                    </p>                    
                  </div>
                </Col>
                <Col lg="1" className="order-md-3"></Col>
                <Col lg="6" className="order-md-2 text-center">
                  <img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2F691.svg?alt=media&token=660f0107-67bc-44f0-9ebf-57b87c25207a" />
                </Col>
              </Row>
            </Container>
            <Container fluid="true" style={{marginRight:"10%",marginleft:"10%"}}>
              <Row className="row-grid align-items-center pb-100">
                <Col lg="1" className="order-md-1"></Col>
                <Col lg="4" className="order-md-2 text-center">
                  <div className="pl-5 ">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Get started with STEAM Education”</h1>
                    <p>
                      Aviate provides the best ever STEAM education to your children by combining STEAM with their academics that will enable the practical learning.
                    </p>                    
                  </div>
                </Col>
                <Col lg="1" className="order-md-3"></Col>
                <Col lg="6" className="order-md-4 text-center">
                  <img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2F40968.jpg?alt=media&token=ea3a3fcc-4aa7-4172-9e4b-ad09201d5ec9" />
                </Col>
              </Row>
            </Container>
            <Container fluid="true" style={{marginRight:"10%",marginleft:"10%"}}>
              <Row className="row-grid align-items-center pb-100">
                <Col lg="1" className="order-md-1"></Col>
                <Col lg="4" className="order-md-4 text-center">
                  <div className="pl-5 ">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“It's time for your children to start learning how to code”</h1>
                    <p>
                      Aviate teaches coding for children in a easy yet fast in understanding the logic by relating it to the real world examples that will boost their logical thinking.
                    </p>                    
                  </div>
                </Col>
                <Col lg="1" className="order-md-3"></Col>
                <Col lg="6" className="order-md-2 text-center">
                 <img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2F339250-PAJEZ6-735.svg?alt=media&token=7101743c-ca3c-482c-b63d-8c22bd825afb" />
                </Col>
              </Row>
            </Container>
            <Container fluid="true" style={{marginRight:"10%",marginleft:"10%"}}>
              <Row className="row-grid align-items-center pb-100">
                <Col lg="2" className="order-md-1"></Col>
                <Col lg="4" className="order-md-2 text-center">
                  <div className="pl-5 ">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Let your children excel in a whole different new way of learning”</h1>
                    <p>
                      There will be a numerous competitions for your children with us to drive them in a best possible way to reach their standards and boosting them continuously. 
                    </p>                    
                  </div>
                </Col>
                <Col lg="6" className="order-md-3 text-center">
                  <img 
                        width ="110%"
                        height = "100%"
                        alt ="infographics"
                        src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/edutainment%2F6646.svg?alt=media&token=398c568e-ead7-4511-84fb-59caf94ed3c5" />
                </Col>
              </Row>
            </Container> */}
          </section>
        </main>
      </>
    )
  }
}

export default compose(withEmailVerification, withAuthentication)(EdutainmentLanding);
