/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import Avatar from 'react-avatar';
// import { compose } from 'recompose';

// core components
import { withAuthentication ,withEmailVerification} from '../../session';
import { compose } from 'recompose';
import { AuthUserContext } from '../../session';
import EditProfilePage from '../profile/editProfile';
// import ChangePasswordPage from "../profile/changePassword";
import {
  // UncontrolledCollapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";
// import Invoices from "views/profile/invoices";
import CourseSubscriptions from "views/profile/courseSubscriptions";
import Certifications from "views/profile/certificates";
// import Badges from "../profile/badges";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfileTab: true,
      ChangePasswordTab: false,
      invoicesTab: false,
      courseSubscriptionsTab: false,
      certificationsTab: false,
      badgesTab: false
    }
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container fluid={true} style={{ paddingLeft: "10%", paddingRight: "10%", paddingBottom: "0vh"}}>
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? <div><Card className="card-profile shadow ">
                    <div className="px-4">
                      <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="3">
                          <div className="card-profile-image text-center">
                            <Avatar name={authUser.displayName} className="rounded-circle mt--5 text-center"  size="150"  />
                          </div>
                        </Col>
                        <Col className="order-lg-3" lg="4" >

                        </Col>
                        <Col className="order-lg-1" lg="4">

                        </Col>
                      </Row>
                      <div className="text-center" >
                        <h3>
                          {authUser.displayName}
                        </h3>
                        <div className="h6 font-weight-300">
                          <i className="ni ni-location_pin mr-2" />
                          <h6>{authUser.personalDetails.city}</h6>
                          <h6>{authUser.personalDetails.state}</h6> 
                          <h6>{authUser.personalDetails.country} </h6>
                    </div>
                        <div className="h6 mt-4">
                          <i className="ni business_briefcase-24 mr-2" />
                          {authUser.personalDetails.designation}
                    </div>
                        <div>
                          <i className="ni education_hat mr-2" />
                          {authUser.personalDetails.collegeCompany}
                        </div>
                      </div>
                      <Navbar className="navbar-main navbar-light border-top border-bottom pb-0 pt-0 mt-3" expand="lg" id="navbar-main">
                        <Container >
                          {/* <button className="navbar-toggler text-center" id="navbar_profile">
                            Menu
                          </button>
                          <UncontrolledCollapse
                            toggler="#navbar_profile"
                            navbar
                          > */}
                            <Nav className="navbar-nav-hover align-items-lg-center" id="tabs-icons-text" role="tablist" navbar>
                              <NavItem active = {this.state.editProfileTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: true, ChangePasswordTab: false, invoicesTab: false, courseSubscriptionsTab: false, certificationsTab: false ,badgesTab: false})}>Edit Profile</NavLink>
                              </NavItem>
                              {/* <NavItem active = {this.state.ChangePasswordTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: false, ChangePasswordTab: true, invoicesTab: false, courseSubscriptionsTab: false, certificationsTab: false ,badgesTab: false})} >Change Password</NavLink>
                              </NavItem> */}
                              {/* <NavItem active = {this.state.invoicesTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: false, ChangePasswordTab: false, invoicesTab: true, courseSubscriptionsTab: false, certificationsTab: false ,badgesTab: false})} >Invoices</NavLink>
                              </NavItem> */}
                              <NavItem active = {this.state.courseSubscriptionsTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: false, ChangePasswordTab: false, invoicesTab: false, courseSubscriptionsTab: true, certificationsTab: false ,badgesTab: false})} >My Course Subscriptions</NavLink>
                              </NavItem>
                              <NavItem active = {this.state.certificationsTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: false, ChangePasswordTab: false, invoicesTab: false, courseSubscriptionsTab: false, certificationsTab: true,badgesTab: false })}>My Certifications</NavLink>
                              </NavItem>
                              {/* <NavItem active = {this.state.badgesTab}>
                                <NavLink onClick={e => this.setState({ editProfileTab: false, ChangePasswordTab: false, invoicesTab: false, courseSubscriptionsTab: false, certificationsTab: false,badgesTab: true })}>Badges</NavLink>
                              </NavItem> */}
                            </Nav>
                          {/* </UncontrolledCollapse> */}
                        </Container>
                      </Navbar>

                    
                    <div>
                      {this.state.editProfileTab ? <div >
                        <EditProfilePage  authUser ={authUser.uid} />
                      </div> : ""}
                      {/* {this.state.ChangePasswordTab ? <div >
                        <ChangePasswordPage />
                                          </div> : ""} */}
                      {/* {this.state.invoicesTab ? <div >
                        <Invoices />
                                          </div> : ""} */}
                      {this.state.courseSubscriptionsTab ? <div >
                        <CourseSubscriptions />
                                          </div> : ""}
                      {this.state.certificationsTab ? <div >
                        <Certifications />
                                          </div> : ""}
                        {/* {this.state.badgesTab ? <div >
                        <Badges/>
                                          </div> : ""} */}
                    </div>

                    </div>
                  </Card>
                  </div> : ""}
              </AuthUserContext.Consumer>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

// export default compose(
//   withEmailVerification, withAuthentication)(Profile);

export default compose(withEmailVerification, withAuthentication)(Profile);
