const WhyAviate =  [
    {
        title : "Self-Paced Online Video",
        description : "A 360-degree learning approach that you can adapt to your learning style"
    },
    {
        title : "Live Virtual Classroom",
        description : "Engage and learn more with these live and highly-interactive classes alongside your peers",
    },
    {
        title : "24/7 Teaching Assistance",
        description : "Keep engaged with integrated teaching assistance in your learning",
    },
    {
        title : "Online Practice Labs",
        description : "Projects provide you with sample work to show prospective employers",
    },
    {
        title : "Applied Projects",
        description : "Real-world projects relevant to what you’re learning throughout the program",
    },
    {
        title : "Learner Social Forums",
        description : "A support team focused on helping you succeed alongside a peer community",
    }
]

export default WhyAviate;