import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Badge,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { Link } from "react-router-dom";
import FAQCard from './faqcard';


import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import CourseContentCard from './courseContentCard';
import CourseDetailsCard from './courseDetailsCard';
import CoursesDisplayCard from "./CoursesDisplayCard";
import CoursesJson from './coursesJson';
import ToolsCoursesJson from './toolsCoursesJson';
import SampleCertificate from './SampleCertificate';
import Testimonials from './testimonials';
import { AuthUserContext, withAuthentication } from '../../session';
import { FcStart, FcVideoCall, FcCustomerSupport, FcCommandLine, FcSerialTasks, FcCollaboration } from "react-icons/fc";
// import RazorPay from "../../payment/razorPayment";
import RazorPayPage from "../../payment/razorPayment";
import PaymentPopUpPage from "../../payment/paymentPopUp";
import WhyAviate from "./whyaviateJson";
const whyAviateIcons = [<FcStart style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />,
<FcVideoCall style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />,
<FcCustomerSupport style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />,
<FcCommandLine style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />,
<FcSerialTasks style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />,
<FcCollaboration style={{ height: "8vh", width: "8vh", marginBottom: "2vh" }} />
];



class CourseContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            panelCollapsedCourseDetails: false,
            panelCollapsedCourseSessions: false,
            panelCollapsedProjectDetails: false,
            modalPayment: [false, false, false],
            showPaymentButton: false,
            course: {
                certificationName: "",
                courseName: "",
                avtive: "",
                courseDisplayImageUrl: "",
                navigateUrl: "",
                duration: "",
                prerequisites: "",
                youtubeUrl: "",
                courseDetails: {
                    courseDescription: {
                        name: "",
                        introduction: ""
                    },
                    instructor: {
                        name: "",
                        introduction: ""
                    },
                    handouts: {
                        name: "",
                        handouts: []
                    },
                    assignments: {
                        name: "",
                        assignements: []
                    }
                },
                relatedCourses: [],
                certificates: [],
                faq: [],
                projectDetails: [],
                trainingOptions: [
                    {
                        name: "Self Placed Learning",
                        certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                        withoutMTA: "2999",
                        withMTA: "3999",
                        courseFee: "2999",
                        redirectUrl: "",
                        mtaChecked: false,
                        features: [
                            "Lifetime access to high-quality self-paced e-learning content curated by industry experts",
                            "24x7 learner assistance and support",
                            "Two hands-on projects",
                            "Course completion certificate",
                            "Assignments, course documentaion and resources",
                            "Learn at your choice of time",
                            "Exercises",
                            "Quiz sections",
                            "Discussion pages"
                        ]
                    },
                    {
                        name: "Mentor Led Live Sessions",
                        certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                        withoutMTA: "4999",
                        withMTA: "5999",
                        courseFee: "4999",
                        redirectUrl: "",
                        mtaChecked: false,
                        features: [
                            "90 days of flexible access live mentor led online classes",
                            "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                            "24x7 learner assistance and support",
                            "learner - mentor interaction sessions",
                            "Assignments & projects verification",
                            "best performer voucher",
                            "Two hands-on projects",
                            "Course completion certificate",
                            "Course documentaion and resources",
                        ]
                    },
                    {
                        name: "Recorded Sessions",
                        certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                        withoutMTA: "4999",
                        withMTA: "5999",
                        courseFee: "4999",
                        mtaChecked: false,
                        features: [
                            "90 days of flexible access to live mentor led online classes",
                            "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                            "24x7 learner assistance and support",
                            "learner - mentor interaction sessions",
                            "Assignments & projects verification",
                            "best performer voucher",
                            "Two hands-on projects",
                            "Course completion certificate",
                            "course documentaion and resources",
                        ]
                    }
                ],
                courseOverview: {
                    heading: "",
                    description: "",
                    keyFeaturesHeading: "",
                    courseKeyFeatures: [],
                    skillsCoveredHeading: "",
                    skillsCoveredList: []
                },
                benefits: {
                    description: "",
                },
                whyAviate: [],
                testimonials: [],
                courseCurriculum: {
                    heading: "",
                    description: "",
                    prerequisites: "",
                    courseContents: []
                }
            },
        }
    }
    _isMounted = false;
    getData() {
        setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                    course: this.props.course
                })
            }
        }, 1000)
    }



    componentDidMount = () => {
        this._isMounted = true;
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // this.refs.main.scrollTop = 0;
        this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    togglePayment = (e) => {
        const id = e.target.name;
        if (this._isMounted) {
            this.setState(prevState => ({
                modalPayment: {
                    ...prevState.modalPayment,
                    [id]: !this.state.modalPayment[id],
                },
            }));
        }
    }

    showPaymentButton = () => {
        if (this._isMounted) {
            this.setState({ showPaymentButton: true });
        }
    }


    handleCheck = (e, element) => {
        const { trainingOptions } = this.state.course;
        var trainingIndex = trainingOptions.indexOf(element);

        const newTrainingOptions = trainingOptions.slice();

        newTrainingOptions[trainingIndex].mtaChecked = !newTrainingOptions[trainingIndex].mtaChecked

        if (newTrainingOptions[trainingIndex].mtaChecked) {
            newTrainingOptions[trainingIndex].courseFee = newTrainingOptions[trainingIndex].withMTA
        }
        else {
            newTrainingOptions[trainingIndex].courseFee = newTrainingOptions[trainingIndex].withoutMTA
        }
        if (this._isMounted) {
            this.setState({ trainingOptions: newTrainingOptions })
        }
    }

    render() {
        return (
            <>

                <section className="section pb-0 pt-0">
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <Row className="row-grid ">
                            <Col className="order-md-1" md="7" >
                                <h3>{this.props.course.courseOverview.heading}</h3>
                                <p>{this.props.course.courseOverview.description}</p>
                            </Col>
                            <Col className="order-md-2" md="5" >

                            </Col>
                        </Row>
                        <Row className="row-grid ">
                            <Col className="order-md-1" md="7" >
                                <h4>{this.props.course.courseOverview.keyFeaturesHeading}</h4>
                                {this.props.course.courseOverview.courseKeyFeatures.map((element, i) => {
                                    return <Row key={i}>
                                        <Col className="order-md-1" md="1">

                                        </Col>
                                        <Col className="order-md-2" md="10">
                                            <div className="d-flex" >
                                                <div>
                                                    <Badge className="badge-circle" color="success" >
                                                        <i className="ni ni-check-bold" />
                                                    </Badge>
                                                </div>
                                                <p className="ml-3">{element}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                            <Col className="order-md-1" md="5" >
                                <h4>{this.props.course.courseOverview.skillsCoveredHeading}</h4>
                                {this.props.course.courseOverview.skillsCoveredList.map((element, i) => {
                                    return <Row key={i}>
                                        <Col className="order-md-1" md="1">

                                        </Col>
                                        <Col className="order-md-2" md="10">
                                            <div className="d-flex" >
                                                <div>
                                                    <Badge className="badge-circle" color="success" >
                                                        <i className="ni ni-check-bold" />
                                                    </Badge>
                                                </div>
                                                <p className="ml-3">{element}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section pb-0">
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%", paddingBottom: "0vh" }}>
                        <Row className="row-grid ">
                            <Col className="order-md-1" md="7" >
                                <h3>{this.props.course.courseCurriculum.heading}</h3>
                                {/* <p>{this.props.course.courseCurriculum.description}</p> */}
                            </Col>
                            <Col className="order-md-2" md="5" >

                            </Col>
                        </Row>
                        {/* </Row> */}
                        <Row className="mt-5 mb-5">
                            <Col className="order-md-1" md="6" >
                                <Card >
                                    <CardHeader style={{ backgroundColor: "rgb(108,126,235)", height: "6vh", padding: "1.5vh", color: "white" }} onClick={(e) => this.setState({ panelCollapsedCourseDetails: !this.state.panelCollapsedCourseDetails })}>
                                        <Row>
                                            <Col className="order-md-1" lg="10" md="10" sm="9" xs="8">
                                                Course Details :
                                                </Col>
                                            <Col className="order-md-2 text-white text-underline" lg="2" md="2" sm="3" xs="4">
                                                {this.state.panelCollapsedCourseDetails ? "Hide All" : "Show All"}
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                </Card>

                                <CourseDetailsCard name={this.props.course.courseDetails.courseDescription.name} content={this.props.course.courseDetails.courseDescription.introduction} panelCollapsed={this.state.panelCollapsedCourseDetails} />
                                {window.location.pathname==="/courses/programming-application-tools-domain/sql" || window.location.pathname==="/courses/programming-application-tools-domain/tableau" 
                                ? <CourseDetailsCard name={this.props.course.courseDetails.instructor.name} instructorName = {this.props.course.courseDetails.instructor.instructorName} content={this.props.course.courseDetails.instructor.introduction} panelCollapsed={this.state.panelCollapsedCourseDetails} />
                                :<CourseDetailsCard name={this.props.course.courseDetails.instructor.name} content={this.props.course.courseDetails.instructor.introduction} panelCollapsed={this.state.panelCollapsedCourseDetails} />}
                                <Card>
                                    <CardHeader style={{ backgroundColor: "rgb(108,126,235)", height: "6vh", marginTop: "10vh", padding: "1.5vh", color: "white" }} onClick={(e) => this.setState({ panelCollapsedProjectDetails: !this.state.panelCollapsedProjectDetails })}>
                                        <Row>
                                            <Col className="order-md-1" lg="10" md="10" sm="9" xs="8">
                                                Project/Exercise Details :
                                                </Col>
                                            <Col className="order-md-2 text-white text-underline" lg="2" md="2" sm="3" xs="4">
                                                {this.state.panelCollapsedProjectDetails ? "Hide All" : "Show All"}
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                </Card>
                                <div className="mb-5">
                                    {this.props.course.projectDetails.map((element, i) => {
                                        return <CourseContentCard key={i} contentDetails={element} panelCollapsed={this.state.panelCollapsedProjectDetails} />
                                    })}
                                </div>
                            </Col>

                            <Col className="order-md-2" md="6">
                                <Card>
                                    <CardHeader style={{ backgroundColor: "rgb(108,126,235)", height: "6vh", padding: "1.5vh", color: "white" }} onClick={(e) => this.setState({ panelCollapsedCourseSessions: !this.state.panelCollapsedCourseSessions })}>
                                        <Row>
                                            <Col className="order-md-1" lg="10" md="10" sm="9" xs="8">
                                                Course Sessions ({this.props.course.courseCurriculum.courseContents.length}) :
                                                </Col>
                                            <Col className="order-md-2 text-white text-underline" lg="2" md="2" sm="3" xs="4">
                                                {this.state.panelCollapsedCourseSessions ? "Hide All" : "Show All"}
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                                {this.props.course.courseCurriculum.courseContents.map((element, i) => {
                                    return <CourseContentCard key={i} contentDetails={element} panelCollapsed={this.state.panelCollapsedCourseSessions} />
                                })}
                            </Col>
                        </Row>
                    </Container>
                </section>
                {window.location.pathname==="/courses/programming-application-tools-domain/sql" || window.location.pathname==="/courses/programming-application-tools-domain/tableau" 
                ? ""
                :<section className="section section-lg pt-0 pb-0">
                    <Testimonials testimonials={this.props.course.testimonials} />
                </section>}
                <section className="section pt-0 section-lg">

                    <SampleCertificate />
                </section>
                <section className="section pt-0 pb-0">
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <Row className="row-grid ">
                            <Col className="order-md-1" md="7" >
                                <h3>Why Aviate ?</h3>
                                <p>Aviate's Blended Learning model brings classroom learning experience online with its world-class LMS. It combines instructor-led training, self-paced learning and personalized mentoring to provide an immersive learning experience.</p>
                            </Col>
                            <Col className="order-md-2" md="5">

                            </Col>
                        </Row>
                        <Row className="text-center mb-5">
                            {WhyAviate.map((element, i) => {
                                return <Col key={i} lg="4" className="mt-5">
                                    <Card className="card-lift--hover shadow border-0" >
                                        <CardBody className="py-3 mt-3 mb-3">
                                            {whyAviateIcons[i]}
                                            <h6 className="text-info text-uppercase text-center">
                                                {element.title}
                                            </h6>
                                            <p className="mt-3">
                                                {element.description}
                                            </p>
                                            <p style={{ height: "2vh" }} className="text-primary">{element.title === "Self-Paced Online Video" || element.title === "Online Practice Labs" ? "Launching Soon...!" : "   "}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            })}
                        </Row>
                    </Container>
                </section>
                <section className="section bg-gradient-secondary pb-100">
                    <Container fluid={true} style={{ paddingLeft: "8%",paddingRight:"8%", pdddingBottom :"5%" ,marginBottom:"5vh", height: "auto" }}>
                        <h3>{this.props.course.benefits.title}</h3>
                        <ul className="mt-5 mb-5">
                            {this.props.course.benefits.benefitsList.map((element, i) => {
                                return <li  className="mt-4" key={i}>{element}</li>
                            })}
                        </ul>
                    </Container>
                </section>
                <section className="section section-lg " >
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <h3>Training Options</h3>
                        <Row className="mt-5" id="payment">
                            {this.props.course.trainingOptions.map((element, i) => {
                                return <Col lg="4" key={i}>
                                    <Card className="card-lift--hover shadow text-center pt-3 mb-3" >
                                        <CardBody className="pl-5 pr-5">
                                            <h5 className=" text-center text-uppercase">
                                                {element.name}
                                            </h5>

                                            <Card height="1vh" className="mb-3"></Card>
                                            {element.features.map((feature, i) => {
                                                return <div className="d-flex" key={i}>
                                                    <div>
                                                        <i className="ni ni-check-bold" />
                                                    </div>
                                                    <p className="ml-3 text-justify description">{feature}</p>
                                                </div>
                                            })}
                                            <form className="text-left ">
                                                <h6>Select Certificates : </h6>
                                                <div className="d-flex">
                                                    <input className="mt-2 ml-3"
                                                        name="aare"
                                                        checked={true}
                                                        disabled
                                                        type="checkbox" />
                                                    <p className="ml-3 description">
                                                        {element.certificateTitles[0]}
                                                    </p>
                                                </div>
                                                {this.state.course.courseName !== "Tableau Training and Certification Course"
                                                ?<div className="d-flex">
                                                    <input className="mt-2 ml-3"
                                                        name="mta"
                                                        onChange={(e) => this.handleCheck(e, element)}
                                                        type="checkbox" />
                                                    <p className="ml-3 description">
                                                        {element.certificateTitles[1]}
                                                    </p>
                                                </div>
                                                :""}
                                            </form>
                                            <h5 className=" text-center text-uppercase pb-2" >
                                                &#8377; {this.state.course.trainingOptions[i].mtaChecked ? element.withMTA : element.withoutMTA}
                                            </h5>
                                            <Button
                                                className="mt-4 text-center"
                                                color="success"
                                                disabled={!this.state.course.active || this.state.course.trainingOptions[i].name === "Self Placed Learning" || this.state.course.trainingOptions[i].name === "Recorded Sessions"}
                                                name={i}
                                                onClick={this.togglePayment}
                                            >
                                                Enroll Now
                                        </Button>
                                            <div>{!this.state.course.active 
                                            || this.state.course.trainingOptions[i].name === "Self Placed Learning" 
                                            || this.state.course.trainingOptions[i].name === "Recorded Sessions" 
                                            ? <div style={{ backgroundColor: "rgb(255,204,0)", marginLeft:"5%",marginRight:"5%"}} className="text-center floating">
                                                    <h6 className="pb-2 pt-2 ">Launching Soon...!</h6>
                                                </div>
                                            : ""}</div>
                                            <AuthUserContext.Consumer>
                                                {authUser => authUser ?
                                                    <Modal isOpen={this.state.modalPayment[i]} centered modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }} name={i} >
                                                        <ModalHeader name={i} >Enroll for {this.state.course.courseName} </ModalHeader>
                                                        <ModalBody>
                                                            {!this.state.showPaymentButton
                                                                ? <PaymentPopUpPage course={this.state.course.courseName} togglePayment={this.togglePayment} showPaymentButton={this.showPaymentButton} />
                                                                : <div className="text-center">
                                                                    <div className="text-left" style={{paddingLeft:"5%",paddingRight:"5%"}}>
                                                                        <h6>Training Mode : {this.state.course.trainingOptions[i].name}</h6>
                                                                        <h6>Certificates : <ul><li>{this.state.course.trainingOptions[i].certificateTitles[0]}</li>
                                                                            {this.state.course.trainingOptions[i].mtaChecked ? <li>{this.state.course.trainingOptions[i].certificateTitles[1]}</li> : ""}
                                                                        </ul></h6>
                                                                        <h6>Course Price: &#8377;{this.state.course.trainingOptions[i].courseFee}</h6>
                                                                    </div>
                                                                    <RazorPayPage course={this.state.course} index={i} />
                                                                </div>
                                                            }
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <Button color="secondary" name={i} onClick={this.togglePayment}>Cancel</Button>
                                                        </ModalFooter>
                                                    </Modal>
                                                    : <Modal isOpen={this.state.modalPayment[i]} centered modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }} name={i} toggle={this.togglePayment}>
                                                        <ModalHeader name={i}>Please Sign In to continue</ModalHeader>
                                                        <ModalBody>
                                                            <div className="text-center"><Link to="/login"><Button>Sign in to continue</Button></Link></div>
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <Button color="secondary" name={i} onClick={this.togglePayment}>Cancel</Button>
                                                        </ModalFooter>
                                                    </Modal>
                                                }
                                            </AuthUserContext.Consumer>
                                        </CardBody>
                                    </Card>
                                </Col>
                            })}
                        </Row>
                    </Container>
                </section>
                <section className="section section-shaped bg-white">
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }} >
                        <Row className="justify-content-between align-items-center">
                            <Col className="mb-5 mb-lg-0 order-md-2" lg="2">
                                <h1 className="font-weight-light">
                                    Frequently Asked Questions
                                </h1>
                                <p className="lead mt-4">

                                </p>
                            </Col>
                            <Col className="mb-lg-auto order-md-1" lg="10">
                                <div className="rounded shadow-lg bg-secondary transform-perspective-left">
                                    <Row style={{ padding: "3vh" }}>
                                        {this.props.course.faq.map((element, i) => {
                                            return <FAQCard key={i} cardDetails={element} />
                                        })}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="fill-white" points=" 100 0 100 2560 0 2560" />
                        </svg>
                    </div>

                </section>

                <section className="section section-shaped bg-white">
                    <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }} >
                        <Row className="justify-content-between align-items-center">
                            <Col className="mb-5 mb-lg-0" lg="4">
                                <h1 className="font-weight-light">
                                    Related Courses
                                </h1>
                                <p className="lead mt-4">
                                    Find all the related courses and continue learning .
                                </p>
                                <Button
                                    className="btn-white mt-4"
                                    color="default"
                                >
                                    {window.location.pathname.startsWith("/courses/programming-application-tools-domain")
                                    ? <Link to="/courses/programming-application-tools-domain"> See all courses</Link>
                                    : <Link to="/internship/robotics"> See all courses</Link>
                                    }
                                </Button>
                            </Col>
                            <Col className="mb-lg-auto" lg="8">
                                <div className="rounded shadow-lg bg-secondary transform-perspective-right">
                                    <Row style={{ padding: "5vh" }}>
                                        {window.location.pathname.startsWith("/internship/robotics")
                                            ? CoursesJson.map((element, i) => {
                                                return this.props.course.relatedCourses.indexOf(element.courseName) !== -1 ? <CoursesDisplayCard key={i} element={element} i={i} /> : ""
                                            })
                                            : ToolsCoursesJson.map((element, i) => {
                                                return this.props.course.relatedCourses.indexOf(element.courseName) !== -1 ? <CoursesDisplayCard key={i} element={element} i={i} /> : ""
                                            })}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>
                </section>
            </>
        )
    }
}

export default withAuthentication(CourseContents);