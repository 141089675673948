/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialsJson from "./testimonialsJson";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class Testimonials extends React.Component {

    render() {
        return (
            <>
                <section className="section bg-white ">
                    <Container fluid="true" style={{ paddingLeft: "8%", paddingRight : "8%", height: "auto" }} className="pb-100">
                        <h3>Testimonials</h3>
                        <Carousel responsive={responsive}>
                            {TestimonialsJson.videoTestimonials.map((element, i) => {
                                return <div key={i}>
                                    <Container fluid="true" style={{ paddingLeft: "8%", paddingRight: "8%" }} >
                                        <Row className="justify-content-between align-items-center  mt-5">
                                            <Col className="mb-5 mb-lg-0 order-md-2 text-center mt--5 pl-3 pr-3" lg="5">
                                                <h4 className="">{element.name}</h4>
                                                <h5 className="mt-4" style={{fontFamily:"Source Serif Pro"}}>“{element.review}"</h5>
                                            </Col>
                                            <Col className="mb-lg-auto order-md-1 mt-5" lg="7">
                                                <div className="shadow-lg  bg-secondary transform-perspective-top" style={{ padding: "3vh", marginBottom : "10vh", borderRadius: "3vh" }}>
                                                    <video width="100%" height="auto" type="video/mp4" controls  controlsList="nodownload nofullscreen nopictureinpicture" src={element.videoUrl} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            })}
                            {TestimonialsJson.textTestimonials.map((element, i) => {
                                return <div key={i}>
                                    <Container fluid="true" style={{ paddingLeft: "8%", paddingRight: "8%" }} >
                                        <Row className="justify-content-between align-items-center mt-5" style={{paddingTop:"5%"}}>
                                            <Col className="mb-5 text-center mt-5 " lg="12" style={{paddingLeft:"15%",paddingRight:"15%"}}>
                                                <h1 className="">{element.name}</h1>
                                                <h4 className="  mt-4" style={{fontFamily:"Source Serif Pro"}}>“{element.review}"</h4>
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                            })}
                        </Carousel>

                    </Container>
                </section>
            </>
        );
    }
}

export default Testimonials;
