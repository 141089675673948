/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer bg-dark text-white">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="5">
                <h6 className="text-white">
                  About the company
                </h6>
                <p className="description mt-3">Aviate is the recently started and trusted platform set up by removing the barriers of cost, access and location while
                    transforming traditional education. Aviate delivers courses for curious minds on technologies including Robotics
                    and computer science along with Entrepreneurship and Managerial skills.</p><p> Aviate is your Second School.</p>

                
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/AviateRobotics"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/AviateRobotics"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.linkedin.com/company/aviate-robotics/"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-linkedin" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Subscribe us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="instagram"
                  href="https://www.instagram.com/aviate.robotics/"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Subscribe us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="youtube"
                  href="https://www.youtube.com/channel/UCy0hFWB_a5Ziv0fUu-7-Cfg"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-youtube" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Github
                </UncontrolledTooltip>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="7">
                <Row className="pl-3 pr-3">
                  <Col lg="6">
                    <h6 className="mt-3 text-left text-info">Our Policies</h6>
                    <div className="text-left  mt-2">
                      <Link to="/about-us" className="text-white description">About us</Link>
                    </div>
                    <div className="text-left  mt-2">
                      <Link to="/investors" className="text-white description">Our Investors</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/termsofuse" className="text-white description">Terms of use</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/privacy" className="text-white description">Privacy Policy</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/refunds" className="text-white description">Refund Policy</Link>
                    </div>

                    <h6 className="mt-3 text-left text-info">Contact Us</h6>
                    <div className="text-left mt-2">
                      <a href="mailto:team@aviaterobotics.com" className="text-white description">team@aviaterobotics.com</a>
                    </div>
                    <div className="text-left mt-2">
                      <a href="tel:+91 8688607921" className="text-white description">+91 8688607921</a>
                    </div>
                  </Col>
                  <Col lg="6">
                    <h6 className="mt-3 text-left text-info">Courses</h6>
                    <div className="text-left mt-2">
                      <Link to="/internship/robotics" className="text-white description">Robotics</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/courses/programming-application-tools-domain" className="text-white description">Programming & Application Tools</Link>
                    </div>

                    <h6 className="mt-3 text-left text-info">Edutainment</h6>
                    <div className="text-left mt-2">
                      <Link to="/edutainment" className="text-white description">STEAM</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/edutainment" className="text-white description">Logical Coding</Link>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/edutainment" className="text-white description">DIY Learning</Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="#"
                  >
                    Aviate Robotics
                  </a>
                </div>
              </Col>
              {/* <Col md="6">
                <Nav className=" nav-footer justify-content-md-between">
                  <NavItem>
                    <NavLink
                      to="/about-us"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/termsandconditions"
                    >
                      Terms and Conditions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/privacy"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="refunds-cancellations"
                    >
                      Refunds/cancellations
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col> */}
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
