import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Button, Container} from "reactstrap";
import { AuthUserContext } from '../../session';


// const enrolledCourses = ["Robotics", "Internet of Things"]
class Certifications extends Component {

    componentDidMount() {

    }

    render() {
        return <section >
            <Container fluid={true} className="pb-250 pt-5" style={{ height: "auto" }}>
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? <div className="text-center">
                            <h5>Your Certificates shelf is waiting for you to fill.</h5>
                            <p>Why not pick something to learn from our courses and get certified?</p>
                            <Button className ="btn-primary mb-5" ><Link to = "/courses/robotics">View All Courses</Link></Button>
                        </div> : ""}
                </AuthUserContext.Consumer>
            </Container>
        </section>
    }
}

export default Certifications;