/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container
} from "reactstrap";
// core components

class AboutUs extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section pb-10 pt-0">
                        <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                            <div className="mt-5 ">
                                <h5>Our Mission:</h5>
                                <p className="pl-5 pr-5">Increase access to high-quality Technical courses for everyone, everywhere at affordable cost.
                                    Enhance Learning Through Live and Self Placed Sessions from Industry Experts. Advance teaching and
                                    learning through research based Projects.
                                    Help Individuals with required skills to reach their full Potential.</p>
                                <h5>Who can Use Aviate?</h5>
                                <p className="pl-5 pr-5">Any Individual Learner or Student Teams can enroll to course</p>
                                <h5>Our Values:</h5>
                                <div className="pl-5 pr-5">
                                    <ul>
                                        <li><p className=""><b>Transparency : </b>We’re transparent with our learners and each other about what we’re doing and why.</p></li>
                                        <li><p className=""><b>Entitle Others: </b> We help our learners , and each other be the best they can be, welcominganyone to the our respective courses community.</p></li>
                                        <li><p className=""><b>Learn together : </b>We listen to and address the needs of our learners , celebrating our successes and learning from failures.</p></li>
                                        <li><p className=""><b>Transforming Lives :</b> We strive to transform education - and change our learners’ lives, and the world in the process.</p></li>
                                    </ul>
                                </div>

                                <h5>Our Story :</h5>
                                <p className="pl-5 pr-5">Aviate is the recently started and trusted platform for education and learning . Founded by
                                    Ganti Ravi Teja and Team which now became for a home for more than 3000+ learners across
                                    the globe. Removing the Barriers of Cost , Access and Location Aviate is Transforming
                                    Traditional education. Aviate delivers courses for curious minds on topics ranging from Robotics
                                    and computer science to Entrepreneurship. Aviate is your Second School.</p>
                            </div>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}

export default AboutUs;