import React, { Component } from 'react';
import axios from 'axios';
import * as emailjs from 'emailjs-com';

import {
    Button,
    Card,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import classnames from "classnames";


class RequestCallBack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestCallBack: {
                Name: "",
                Mobile: "",
                Email: "",
                Comments: ""
            },
            nameFocused: false,
            mobileFocused: false,
            emailFocused: false,
            successMessage: "",
            errorMessage: ""
        }
    }
    componentDidMount(){
        const {requestCallBack} = this.state;
        if(this.props.userDetails!==null){
            this.setState({requestCallBack : {...requestCallBack, Name: this.props.userDetails.userName, Mobile : this.props.userDetails.mobile, Email:this.props.userDetails.email}})
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const { requestCallBack } = this.state;

        this.setState({
            requestCallBack: { ...requestCallBack, [name]: value }
        });
        const success = this.state.requestCallBack.Name !== "" && this.state.requestCallBack.Mobile !== "" && this.state.requestCallBack.Email !== "" && this.state.requestCallBack.Comments !== "";
        if (success) {
            this.setState({ errorMessage: "" });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.register();
    }

    register = () => {
        var callbackUrl = "https://aviaterobotics.firebaseio.com/callback/" + this.props.course + ".json";
        const success = this.state.requestCallBack.Name !== "" && this.state.requestCallBack.Mobile !== "" && this.state.requestCallBack.Email !== "" && this.state.requestCallBack.Comments !== "";
        if (success) {
            axios.post(callbackUrl, this.state.requestCallBack).then(success => {
                // To make a refreshed page look after the submit button is pressed
                const { requestCallBack } = this.state;
                this.setState({ successMessage: "We will call back soon !", errorMessage: "", requestCallBack: { ...requestCallBack, Name: "", Mobile: "", Email: "", Comments: "" } })
                this.setState({ registeredStatus: true })
            }).catch(error => {
                if (error.response) {
                    if (this._isMounted) {
                        this.setState({ errorMessage: error.response.data.message, successMessage: "" });
                    }
                } else {
                    if ((this._isMounted)) {
                        this.setState({ errorMessage: error.message, successMessage: "" });
                    }
                }
            })

            var email1 = "support@aviaterobotics.com";
            var email2 = "tech@avaiterobotics.com";

            let templateParams = {
                from_email: email2,
                to_email: email1,
                from_name: email1.split('@')[0],
                to_name: email2.split('@')[0],
                email: this.state.requestCallBack.Email,
                name: this.state.requestCallBack.Name,
                mobile: this.state.requestCallBack.Mobile,
                comments: this.state.requestCallBack.Comments,
            }

            emailjs.send(
                'gmail',
                'callback',
                templateParams,
                'user_m6zIhA1aJ1gvO2NiWXgNw'
            )

        }
        else {
            this.setState({ errorMessage: "Please fill all the details & check email is valid one" })
        }
    }

    render() {
        return (
            <>
                <Card className="bg-default shadow border-0 mt-5 transform-perspective-right" style={{  height: "auto",borderRadius:"5%", backgroundColor: "white" }}>
                    <blockquote className="card-blockquote" style={{ backgroundColor: "rgb(224,239,241)", height: "auto", marginLeft: "1vh", marginRight: "2vh", marginBottom: "8vh", marginTop: "5vh"  }}>
                        <h5 className="font-weight-bold text-center">
                            Have any questions ?
                        </h5>
                        <FormGroup className={classnames("", { focused: this.state.nameFocused  })}>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-user-run" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Your name"
                                    type="text"
                                    name="Name"
                                    value={this.state.requestCallBack.Name}
                                    onChange={this.handleChange}
                                    onFocus={e => this.setState({ nameFocused: true })}
                                    onBlur={e => this.setState({ nameFocused: false })}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames("", { focused: this.state.mobileFocused })} >
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-mobile-button" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Your mobile"
                                    type="mobile"
                                    name="Mobile"
                                    value={this.state.requestCallBack.Mobile}
                                    onChange={this.handleChange}
                                    onFocus={e => this.setState({ mobileFocused: true })}
                                    onBlur={e => this.setState({ mobileFocused: false })}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.emailFocused })} >
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Email address"
                                    type="email"
                                    name="Email"
                                    value={this.state.requestCallBack.Email}
                                    onChange={this.handleChange}
                                    onFocus={e => this.setState({ emailFocused: true })}
                                    onBlur={e => this.setState({ emailFocused: false })}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <Input
                                className="form-control-alternative"
                                cols="80"
                                name="Comments"
                                autoFocus
                                placeholder="Type a message..."
                                rows="4"
                                type="textarea"
                                value={this.state.requestCallBack.Comments}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button
                                color="secondary"
                                onClick={this.handleSubmit}
                            >
                                Request call back
                        </Button>

                        </div>
                        <p className="text-danger text-center mt-3">{this.state.errorMessage}</p>
                        <p className="text-danger text-center mt-3">{this.state.successMessage}</p>
                    </blockquote>

                </Card>
                
            </>
        )
    }
}

export default RequestCallBack;