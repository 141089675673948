/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";
// core components

class TermsofUse extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section pb-10 pt-0">
                        <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <div className="mt-5">
                                <h3 className="">Aviate Terms of Use</h3>
                                <p>Last Updated: Sep 5, 2020</p>
                                <p className="description"><i>Our mission at Aviate is to provide access to high-quality Technical courses for everyone, everywhere at affordable cost.</i></p>
                            </div>
                        <div className="mt-5">
                                <div className="pl-5 pr-5">
                                <p><b>Please read the following carefully</b></p>
                                <p className="description">These terms and conditions ("Terms and Conditions") control your use of this website aviaterobotics.com ("Website").
                                 In these Terms and Conditions, "Aviate" is referred to as the "Company", "us," or "we."</p>
                                <p className="description">'You' refers to a user or a paying customer. If you are a company or another person who gives access to company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.</p>
                                <p className="description">The Aviates website (the 'Site'), the educational services made available through the site and the content (the 'Products') are owned, operated and maintained, as applicable, by Aviate ('we', 'our', 'us', or the 'Company'). The Site, Products and Content are, collectively, the 'Company Products'.</p>
                                <p className="description">By (a) using or accessing the Company Products, including, but not limited to downloading or accessing, (b) offering a Course through the Site or through Software; you agree to the terms and conditions set forth in these Terms of Use (the "Terms")</p>
                                <p className="description">By using this website or its Products and Services, You Agree and Warrant that you have read, understood, and agreed to be Bound by these terms. Company's privacy policy can be found at Privacy Policy. If you do not accept these terms, you must not use - and are not authorized to use - all or any portion of the company's website and its products or services (as defined below).</p>
                                <p><b>Please read them carefully before you use the services of this site.</b></p>
                                <ul>
                                    <li>
                                        <p className="description">You should not use this site in an unlawful manner; you must respect the website terms and conditions and follow the Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <p className="description">Under no situations or circumstances, will the company be liable for any change in the content which it provides on the website through its products and services, including but not limited to any errors, omissions, loss, or damage experienced in connection with the use of exposure, any content made available via our products, services, or various resources such as email, blog etc.</p>
                                    </li>
                                    <li>
                                        <p className="description">Our services are free to any user with access to the Internet. However, we are not responsible for the charges incurred for the usage of hardware, software, or internet services provider fee. Also, the user is fully responsible for the proper functioning of computer hardware and internet access.</p>
                                    </li>
                                    <li>
                                        <p className="description">You will be required to use login credentials for some of the sections on the site and the company reserves the right to block access to our services for any user who does not follow these conditions</p>
                                    </li>
                                    <li>
                                        <p className="description">We make sure that users get uninterrupted access to our service, but there is no obligation to do so.</p>
                                    </li>
                                    <li>
                                        <p className="description">Aviate is not responsible and is not obligated for issues in your network or server beyond certain limits.</p>
                                    </li>
                                </ul>
                                <p><b>Website usage guidelines</b></p>
                                <ul>
                                    <li>
                                        <p className="description">Do not insult, abuse, harass, stalk, threaten, or otherwise infringe the rights of others;</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not publish, post, distribute or disseminate any defamatory, infringing, indecent, offensive or unlawful material or information.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not upload, install, transfer files which are protected by Intellectual Property Laws or software which affect other computers.</p>
                                    </li>
                                    <li>
                                        <p className="description">It's prohibited to edit HTML source code, reverse engineer or attempt to hack</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not run Spam services/scripts or anything which could affect the infrastructure, and in turn, the users.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not communicate spam, advertise, or sell services such as digital downloads, eBooks, or phishing links.</p>
                                    </li>
                                    <li>
                                        <p className="description">You may not copy, distribute, and indulge in plagiarism with website content or user submitted content.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not use any content which violates any legal, regulatory, Governmental or network operator conditions or codes of practice.</p>
                                    </li>
                                </ul>

                                <p><b>Links and Hyperlinks Terms</b></p>
                                <p className="description">This website may have links to other websites. We do not undertake any control on the content of these websites; nor are we responsible for their website content. The sole purpose of the links included are to provide users information. Hence, Aviate will not be held responsible.</p>
                                <ul>
                                    <li>
                                        <p className="description">You may not mirror or frame the homepage or any other pages of this Site on any other website or webpage.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not link to Aviate pages and subpages with spam links/anchor text, which could provide a false impression. This may create misunderstanding for the users.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not use or include copyrighted or registered trademarks, or Intellectual property images, design or content as a link to Aviate website.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not link to pages which support racism, terrorism.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not link to pages which provide pornographic content and violate human and animal rights.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not link pages to content which infringes the intellectual property of any third party, person or entity.</p>
                                    </li>
                                    <li>
                                        <p className="description">Do not link pages to content which violates any legal, regulatory, Governmental or network operator conditions or codes of practice.</p>
                                    </li>
                                </ul>


                                <p><b>Copyright and Intellectual Property</b></p>
                                <p className="description">We value and respect others intellectual property and expect our users to do the same.</p>
                                <p className="description">The entire contents of the Site are protected by copyright and trademark laws. The owner of the copyrights and trademarks are aviaterobotics.com, its affiliates, or other third party licensors. The material on the site, including text, graphics, code and/or software is copyrighted and belongs to Aviate, therefore you may not duplicate, modify, publish, or reproduce the content in any manner.</p>
                                <p className="description">Aviate does not take any responsibility for the content on other sites (except our partners and affiliates), that you may find when searching or accessing Aviate products or services. The Privacy Policy and Terms of Use of the sites that you visit will administer that material.</p>
                                <p className="description">Aviate has all the rights to disable or prohibit access to the users who do not respect and involve in the infringement of Aviate intellectual property.</p>
                                <p className="description">You are not allowed to use any of the digital images or logos from the website. In case of copyright issues, there has to be a written consent from the trademark owner.</p>
                                

                                <p><b>Claims of Intellectual Property Violations</b></p>
                                <p className="description">If you believe that your work has been used without your permission in a way which prompts for copyright infringement. Please provide us with the information given below and we will act on it.</p>
                                <ul>
                                    <li>
                                        <p className="description">The authorized person who will act on behalf of the owner of the copyright, should send a digital or physical signature.</p>
                                    </li>
                                    <li>
                                        <p className="description">A description of the copyrighted work that you claim to be infringing your IP.</p>
                                    </li>
                                    <li>
                                        <p className="description">A description of where and how the material that you claim is infringing is located on the Aviate website, with enough detail that we may find it on the website.</p>
                                    </li>
                                    <li>
                                        <p className="description">Contact Details - Address, telephone number, and email address.</p>
                                    </li>
                                    <li>
                                        <p className="description">A statement by you, that the information which you provided is accurate and your claim of the copyright or intellectual property is on your owner's behalf</p>
                                    </li>
                                    <li>
                                        <p className="description">You can reach Aviate to notify your claims of copyright by writing to <a href="mailto:team@aviaterobotics.com">Help and Support Team</a>.</p>
                                    </li>
                                </ul>

                                <p><b>Transaction Terms</b></p>
                                <ul>
                                    <li>
                                        <p className="description">To make a transaction on Aviate website, you are bound to pay for that transaction.</p>
                                    </li>
                                    <li>
                                        <p className="description">Please pay close attention to your payment details such as total bill, taxes, shipping costs, and discounts.</p>
                                    </li>
                                    <li>
                                        <p className="description">There are certain products which require additional Terms and Conditions which you have to agree to before you make the purchase.</p>
                                    </li>
                                    <li>
                                        <p className="description">Invoice of the order placed can be viewed at user dashboard.</p>
                                    </li>
                                    <li>
                                        <p className="description">If a delegate has completed the course and is eligible for a certificate, then same shall be delivered to the address provided by the delegate within 30 working days.</p>
                                    </li>
                                </ul>
                                <p >WE MAKE NO WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, WITH RESPECT TO ANY PRODUCTS OR SERVICES SOLD ON OR THROUGH AVIATE.</p>
                                <p className="description">No additional or different terms contained in any purchase order, document, transmission or other communication shall be binding upon Aviate unless agreed to by Aviate in writing.</p>
                                <p className="description">Aviate reserves the right to modify, change without prior notice and in its sole discretion, to limit the order quantity on any item and to refuse service to anyone.</p>


                                <p><b>LIMIT OF LIABILITY</b></p>
                                <p className="description">You expressly understand and agree that the Company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, or services purchases or obtained or messages received or transactions entered into through or from the Website or otherwise; (iii) unauthorized access to or alteration of your transmissions or data or confidential information; (iv) statements or conduct of any third party on the Products; (v) or (v) any other matter relating to the Products.</p>

                                <p><b>INDEMNITY</b></p>
                                <p className="description">You agree to indemnify and hold the Company and our subsidiaries, affiliates, officers, directors, agents, and employees, harmless from any claim or demand (including legal expenses and the expenses of other professionals) made by a third party due to or arising out of your breach of this Terms of Use or the documents it incorporates by reference, or your violation of any law or the rights of a third party.</p>

                                <p><b>Pricing Disclaimer</b></p>
                                <p className="description">All prices, products, and offers of Aviate website are subject to change without notice.</p>
                                <p className="description">While we make sure to provide most accurate and up-to-date information, in some cases one or more items on our website may be priced incorrectly. This might happen due to human errors, digital images, technical errors, or a mismatch in pricing information received from our suppliers.</p>
                                <p className="description">Aviate reserves the right to change prices for all our products, offers, or deals. These changes are done due to market conditions, course termination, providers, price changes, errors in advertisements, and other mitigating circumstances. However, the price you paid at the time of purchase still holds for you.</p>
                        </div>
                        </div>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}

export default TermsofUse;