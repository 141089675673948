/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import CoursesJson from './coursesJson';
import CoursesDisplayCard from "./CoursesDisplayCard";
import { withAuthentication , withEmailVerification} from '../../session';
import { compose } from 'recompose';

class CoursesList extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <main className="profile-page" ref="main">
          <section className="section section-lg section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0 mt-3">
              <img 
                width ="100%"
                alt ="infographics"
                src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FCourses%20Page_Website_Background.svg?alt=media&token=36bc9f73-ae8c-422b-b07a-12ae78fe0bc2" />
                <Row className="mt-5 text-center">
                  <Col lg="12 ">
                    <h1 className="display-5 text-white">
                      Industry relevant Internship{" "}
                      <span>courses on Robotics </span>
                    </h1>
                    <p className="lead text-white">
                      Domain : Robotics development, Design.
                      </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>

          </section>
          <section className="section">
            <Container>
              <Row className="justify-content-center mt--100 row-grid">
                {CoursesJson.map((element, i) => {
                  return <CoursesDisplayCard  element = {element} key = {i} i= {i} />
                })}
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default compose(
  withEmailVerification,withAuthentication)(CoursesList) ;
