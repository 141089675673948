/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import FirebaseContext from '../../firebase/context';
import { withFirebase } from '../../firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignInGoogle } from "./Login";
import {SignInFacebook} from "./Login";

const SignUpPage = () => (
  <div>
    <FirebaseContext.Consumer>
      {firebase => <SignUpForm firebase={firebase} />}
    </FirebaseContext.Consumer>
  </div>
);

const INITIAL_STATE = {
  isAdmin : true,
  errorMessage : "",
  userInfo: {
    personalDetails: {
      userName: "",
      firstName: "",
      lastName: "",
      picture : "",
      mobile: '',
      email: '',
      city: "",
      state: "",
      country: "",
      collegeCompany: "",
      designation: "",
      acceptTerms: "",
    },
    socialMedia: {
      linkedin: "",
      facebook: "",
      twitter: "",
      website: ""
    },
    workExperience: {
      designation: "",
      company: "",
      jobRole: "",
      industry: "",
      experience: ""
    },
    invoices: [
      {
      invoice_id :"test",
      invoice_link : ""
      }
    ],
    courseSubscriptions: [
      "test"
    ],
    certificates: [
      "test"
    ],
    payments: [
        {
            payment_id : "test",
            order_id : "test",
            signature : "test",
            paidFor : "test",
            paidDate : Date().toLocaleString(),
            trainingMode : "test",
            amountPaid : "test"
        }
      ],
    badges: [
      "test"
    ],
    passwordInfo: {
      password: "",
      confirmPassword: ""
    }
  },
  userInfoErrorMessages: {
    firstNameMessage: '',
    lastNameMessage: '',
    userNameMessage: '',
    mobileMessage: '',
    emailMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
    acceptTermsMessage: ""
  },
  userInfoInputStatus: {
    firstNameStatus: false,
    lastNameStatus: false,
    userNameStatus: false,
    mobileStatus: false,
    emailStatus: false,
    passwordStatus: false,
    confirmPasswordStatus: false,
    acceptTermsStatus: false,
    enableSignUpButton : false,
  }

};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleChange = (event) => {
    this.setState({errorMessage:""});
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        personalDetails: {
          ...prevState.userInfo.personalDetails,
          [name]: value
        }
      }
    })
    );
    if(name==="acceptTerms"){
      this.validate(name, target.checked);
    }else{
    this.validate(name, value);
    }
  }

  handlePassword = (event) => {
    this.setState({errorMessage:""});
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        passwordInfo: {
          ...prevState.userInfo.passwordInfo,
          [name]: value
        }
      }
    })
    );
    this.validate(name, value);
  }

  validate = (name, value) => {
    let userInfoInputStatus = this.state.userInfoInputStatus;
    let userInfoErrorMessages = this.state.userInfoErrorMessages;
    let passwordInfo = this.state.userInfo.passwordInfo;

    switch (name) {
      case 'firstName':
        if (value !== "") {
          userInfoInputStatus.firstNameStatus = true;
          userInfoErrorMessages.firstNameMessage = "";
        } else {
          userInfoInputStatus.firstNameStatus = false;
          userInfoErrorMessages.firstNameMessage = "Enter First Name";
        }
        break;
      case 'lastName':
        if (value !== "") {
          userInfoInputStatus.lastNameStatus = true;
          userInfoErrorMessages.lastNameMessage = "";
        } else {
          userInfoInputStatus.lastNameStatus = false;
          userInfoErrorMessages.lastNameMessage = "Enter Last Name";
        }
        break;
      case 'userName':
        if (value !== "") {
          userInfoInputStatus.userNameStatus = true;
          userInfoErrorMessages.userNameMessage = "";
        } else {
          userInfoInputStatus.userNameStatus = false;
          userInfoErrorMessages.userNameMessage = "Enter User Name";
        }
        break;
      case 'email':
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value !== "" && emailRex.test(value)) {
          userInfoInputStatus.emailStatus = true;
          userInfoErrorMessages.emailMessage = "";
        } else if (value === "") {
          userInfoInputStatus.emailStatus = false;
          userInfoErrorMessages.emailMessage = "Enter Valid Email Id";
        } else {
          userInfoInputStatus.emailStatus = false
          userInfoErrorMessages.emailMessage = "Invalid Email Id!";
        }
        break;
      case 'mobile':
        if (value !== "" && (value >= 6000000000 && value <= 9999999999)) {
          userInfoInputStatus.mobileStatus = true;
          userInfoErrorMessages.mobileMessage = "";
        } else if (value === "") {
          userInfoInputStatus.mobileStatus = false;
          userInfoErrorMessages.mobileMessage = "Enter Mobile Number";
        } else {
          userInfoInputStatus.mobileStatus = false;
          userInfoErrorMessages.mobileMessage = "Invalid Mobile Number";
        }
        break;

      case 'password':
        if (value !== "" && (this.state.userInfo.passwordInfo.confirmPassword==="")) {
          userInfoInputStatus.passwordStatus = true;
          userInfoErrorMessages.passwordMessage = "";
          passwordInfo.confirmPassword =""
        } else if(value !== "" && this.state.userInfo.passwordInfo.confirmPassword!=="" && value===this.state.userInfo.passwordInfo.confirmPassword){
          userInfoInputStatus.passwordStatus = true;
          userInfoErrorMessages.passwordMessage = "";
          passwordInfo.confirmPassword =""

        } else{
          userInfoInputStatus.passwordStatus = false;
          userInfoErrorMessages.passwordMessage = "Enter Password";
          passwordInfo.confirmPassword =""
        }
        break;
      case 'confirmPassword':
        if(value==="") {
          userInfoInputStatus.confirmPasswordStatus = false;
          userInfoErrorMessages.confirmPasswordMessage = "Enter confirm Password";
        }
        else if (value !== "" && value === this.state.userInfo.passwordInfo.password) {
          userInfoInputStatus.confirmPasswordStatus = true;
          userInfoErrorMessages.confirmPasswordMessage = "";
        } 
        else if (value !== this.state.userInfo.passwordInfo.password) {
          userInfoInputStatus.confirmPasswordStatus = false;
          userInfoErrorMessages.confirmPasswordMessage = "Password did not match";
        }
        
        break;
      case 'acceptTerms':
        if (value) {
          userInfoInputStatus.acceptTermsStatus = true;
          userInfoErrorMessages.acceptTermsMessage = "";
        } else {
          userInfoInputStatus.acceptTermsStatus = false;
          userInfoErrorMessages.acceptTermsMessage = "Please Accept terms";
        }
        break;
      default:
        break;
    }
    const status = userInfoInputStatus.firstNameStatus && userInfoInputStatus.lastNameStatus && userInfoInputStatus.userNameStatus &&
    userInfoInputStatus.emailStatus && userInfoInputStatus.mobileStatus && userInfoInputStatus.passwordStatus && userInfoInputStatus.confirmPasswordStatus && userInfoInputStatus.acceptTermsStatus;

    if(status){
      this.setState({userInfoInputStatus : {...userInfoInputStatus,enableSignUpButton : true}})
    }
    else{
      this.setState({userInfoInputStatus : {...userInfoInputStatus,enableSignUpButton : false}})
    }
  }

  signup = (e) => {
    e.preventDefault();
    const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, badges, payments, passwordInfo } = this.state.userInfo;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(personalDetails.email, passwordInfo.password)
      .then(authUser => {
        authUser.user.updateProfile({ displayName: personalDetails.userName });
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            personalDetails,
            socialMedia,
            workExperience,
            invoices,
            courseSubscriptions,
            certificates,
            badges,
            payments
          });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({
          ...INITIAL_STATE
        });
        this.props.history.push('/');
      })
      .catch(error => {
        
        if(error.message ==="The email address is already in use by another account."){
          this.setState({errorMessage: error.message+" Please verify your email and sign in to continue or reset your password."})
        }else{
          this.setState({errorMessage : error.message})
        }
      });
  }


  render() {
    return (
      <>
        <main ref="main">
          <section className="section section-shaped section-lg pb-100" style={{zIndex:1}}>
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="lg-7 mt--5">
              <Row className="justify-content-center">
                <Col lg="6">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign up with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <SignInGoogle />
                        <SignInFacebook />
                      </div>
                      
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign up with credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.signup}>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-hat-3" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="First Name"
                                  type="text"
                                  onChange={this.handleChange}
                                  name="firstName"
                                  value={this.state.userInfo.personalDetails.firstName} />
                              </InputGroup>
                              <span className="text-danger">{this.state.userInfoErrorMessages.firstNameMessage}</span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-hat-3" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Last Name"
                                  type="text"
                                  onChange={this.handleChange}
                                  name="lastName"
                                  value={this.state.userInfo.personalDetails.lastName} />
                              </InputGroup>
                              <span className="text-danger">{this.state.userInfoErrorMessages.lastNameMessage}</span>
                            </FormGroup>
                          </Col>
                        </Row>


                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="User Name"
                              type="text"
                              onChange={this.handleChange}
                              name="userName"
                              value={this.state.userInfo.personalDetails.userName} />
                          </InputGroup>
                          <span className="text-danger">{this.state.userInfoErrorMessages.userNameMessage}</span>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              onChange={this.handleChange}
                              name="email"
                              value={this.state.userInfo.personalDetails.email} />
                          </InputGroup>
                          <span className="text-danger">{this.state.userInfoErrorMessages.emailMessage}</span>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-mobile-button" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Mobile"
                              type="tel"
                              onChange={this.handleChange}
                              name="mobile"
                              value={this.state.userInfo.personalDetails.mobile} />
                          </InputGroup>
                          <span className="text-danger">{this.state.userInfoErrorMessages.mobileMessage}</span>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              onChange={this.handlePassword}
                              name="password"
                              value={this.state.userInfo.passwordInfo.password}
                            />
                          </InputGroup>
                          <span className="text-danger">{this.state.userInfoErrorMessages.passwordMessage}</span>

                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              autoComplete="off"
                              onChange={this.handlePassword}
                              name="confirmPassword"
                              value={this.state.userInfo.passwordInfo.confirmPassword}
                            />
                          </InputGroup>
                          <span className="text-danger">{this.state.userInfoErrorMessages.confirmPasswordMessage}</span>
                        </FormGroup>
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                                onChange={this.handleChange}
                                name="acceptTerms"
                                value={this.state.userInfo.personalDetails.acceptTerms}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span>
                                  I agree with the{" "}
                                  <a
                                    href="/privacy"
                                    
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>
                              <span className="text-danger">{this.state.userInfoErrorMessages.acceptTermsMessage}</span>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="primary"
                            type="submit"
                            disabled = {!this.state.userInfoInputStatus.enableSignUpButton }
                          >
                            Create account
                          </Button>
                          {this.state.errorMessage!==""?<Alert className="mt-3" color="danger">{this.state.errorMessage}</Alert>:""}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="mailto:team@aviaterobotics.com"
                      >
                        <small>Need help?</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link
                        className="text-light"
                        to="/login"
                      >
                        <small>Already have an account? Sign In</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;
export { SignUpForm };