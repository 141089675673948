import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Collapse,
    CardBody,
    Card,
    CardHeader
} from "reactstrap";


import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { withAuthentication } from '../../session';

class CourseDetailsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            panelCollapsed: false

        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }
    render() {
        return (
            <>
                <Card>
                    <CardHeader style={{ backgroundColor: (this.props.panelCollapsed || this.state.panelCollapsed ? "rgb(239,237,230)" : "rgb(224,239,241)"), marginTop: "0.5vh", borderRadius:"5%", height: "5vh", padding: "1vh" }} onClick={(e) => this.setState({ panelCollapsed: !this.state.panelCollapsed })}>
                        <Row>
                            <Col className="order-md-1 ml-3" md="10" xs="10">
                                {this.props.name}
                            </Col>
                            <Col className="order-md-2" md="1" xs="1">
                                {this.props.panelCollapsed || this.state.panelCollapsed ? <i className="ni ni-fat-delete"></i> : <i className="ni ni-fat-add"></i>}
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={this.props.panelCollapsed || this.state.panelCollapsed}>
                        <CardBody >
                            {window.location.pathname==="/courses/programming-application-tools-domain/sql" || window.location.pathname==="/courses/programming-application-tools-domain/tableau" 
                                ?<h5>{this.props.instructorName}</h5>
                                :""
                            }
                            {this.props.content}
                        </CardBody>
                    </Collapse>
                </Card>
            </>
        )
    }
}

export default withAuthentication(CourseDetailsCard);