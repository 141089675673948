/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import { withAuthentication ,withEmailVerification} from '../../session';
import { Link } from "react-router-dom";
import { compose } from 'recompose';

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  Container,
  Row,
  Col
} from "reactstrap";


class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped ">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex" fluid="true" style={{paddingLeft:"10%",paddingRight:"10%"}}>
                <div className="col px-0">
                  <Row>
                    <Col lg="5">
                      <h1 className="display-3 text-white" >
                      Build Robotic application{" "}
                        <span >while using programming and application tools</span>
                      </h1>
                      <p className="lead text-white">
                      Breaking the pattern and making quality education more accessible and affordable.
                      </p>
                      <div >
                        <Button
                          className="btn-white btn-icon mb-3 mt-3 mb-sm-0 ml-1"
                          color="default"
                        >
                          <Link to = "/internship/robotics" >Robotics</Link>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3  mt-3 mb-sm-0 ml-1"
                          color="default"  
                        >
                          <Link to = "/courses/programming-application-tools-domain" >Programming & Application Tools</Link>
                        </Button>
                      </div>
                    </Col>
                    <Col lg="7">
                      <Card className="shadow border-0 transform-perspective-right" style={{  height: "auto",borderRadius:"4%", backgroundColor: "rgb(3,3,3)" }}>
                        <div style={{ height: "auto", marginLeft: "3vh", marginRight: "3vh", marginBottom: "3vh", marginTop: "3vh"  }}>
                        <CardImg
                      alt="..."
                      height = "auto"
                      src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FFB01.jpg?alt=media&token=51d28bbc-862e-4584-b010-a4f1fbbe4344"
                      
                    />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          
          <section className="section section-lg pb-100">
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 " md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FWebsite_BG01.png?alt=media&token=6efbe98a-ac69-4564-aa76-4997e39c5bae"
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“You can learn anything at your desk when you have the best resources”</h1>
                    <p>
                    Build a deep, solid understanding with the resources provided by us in Technologies of Robotics and Computer science along with Entreprenuership and Managerial skills
                    </p>                    
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pb-100" >
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="8" lg="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FWebsite%20BG02.png?alt=media&token=7882ad3f-1938-4171-98d2-86db7c80f5d5"
                  />
                </Col>
                <Col className="order-md-2  text-center" md="4" lg="6">
                  <div className="text-center">
                    
                  <h1 style={{fontFamily:"Source Serif Pro"}}>“Building any application is as easy as ABC when you understand the core concepts behind it”</h1>
                    <p>
                      You will be able to differentiate your own learning pace.The happiness & satisfaction you get through actual learning & making things happen is priceless for being engaged in this practical world.
                    </p>
                    
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pb-100">
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FWebsite_Background_03.svg?alt=media&token=54c352ee-3a82-47ff-b2b1-0b800c7801c2"
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div> */}
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Every student desires the chance to prove”</h1>
                    <p>
                      You can see an Opportunity, Invitation, Persistence and Reflection  with us in your journey towards your goal in a right path, at a right time and through the right platform.    
                    </p>
                    
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-100">
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-fluid floating"
                      src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Fill-2.svg?alt=media&token=f8c37680-de6a-4614-968d-fbd6d7844fab"
                    />
                  </div>
                </Col>
                <Col className="order-lg-2" lg="6">
                  <div className="d-flex px-3">
                    <div className="pl-4">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Learn to program by observing algorithms”</h1>
                      <p className="">
                        You can program anything if you know what you want. Let us help  you to grab the functional requirement of the task and understand the algorithm behind it.
                      </p>
                    </div>
                  </div>
                  
                </Col>
              </Row>
            </Container>
           
          </section>
          <section className="section section-lg pb-100">
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FWebsite_Background_04.svg?alt=media&token=1fa9c9fc-d476-4d15-acac-4518e370feac"
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div> */}
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“May be you are in the right platform, and at right time, Just choose the right path that best suits you”</h1>
                    <p>
                    Believe it or not, you will be on <i>cloud nine</i> once you start travelling the choosen path with us towards your course of learning. 
                    </p>
                    
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-200">
            <Container fluid="true" style={{marginLeft:"10%",marginRight:"10%"}}>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2FWebsite_Background_05.svg?alt=media&token=45655937-6e63-4885-b2ed-b29055ff500a"
                    />
                  </div>
                </Col>
                <Col className="order-lg-2" lg="6">
                  <div className="d-flex px-3">
                    <div className="pl-4">
                    <h1 style={{fontFamily:"Source Serif Pro"}}>“Add value to your resume through our certificates”</h1>
                      <p className="">
                        Getting an experience with technology related tools and applications is always an extra add on reward to your skill bag.
                      </p>
                    </div>
                  </div>
                  
                </Col>
              </Row>
            </Container>
           
          </section>
        </main>
      </>
    );
  }
}

export default compose(
  withEmailVerification, withAuthentication)(Landing);
