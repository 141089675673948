import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDwAL_qlbtGl8s1XZyxHcyk0k6amuNDcWA",
  authDomain: "aviaterobotics.firebaseapp.com",
  databaseURL: "https://aviaterobotics.firebaseio.com",
  projectId: "aviaterobotics",
  storageBucket: "aviaterobotics.appspot.com",
  messagingSenderId: "138717722364",
  appId: "1:138717722364:web:0892347eaa93c52dc64979",
  measurementId: "G-ZQZT0RCP4B"
};

class Firebase {
    constructor() {
      firebase.initializeApp(firebaseConfig);
   
      this.auth = firebase.auth();
      this.db = firebase.database();

      this.googleProvider = new firebase.auth.GoogleAuthProvider();
      this.facebookProvider = new firebase.auth.FacebookAuthProvider();

    }



    onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
 
            
 
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              displayName : authUser.displayName,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
 
            next(authUser);
          });
      } else {
        fallback();
      }
    });


    getCurrentUser = () => firebase.auth().currentUser


    doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
 
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSendEmailVerification = () =>
      this.auth.currentUser.sendEmailVerification({
        url: "https://aviaterobotics.com/",
      });
    doResetPassword = (email) => 
        this.auth.sendPasswordResetEmail(email);
    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
      this.auth.signInWithPopup(this.facebookProvider);
    
    doSignOut = () => this.auth.signOut();
    
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
    
    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    user = uid => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');
  }
export default Firebase;

