/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext } from '../session';
import axios from "axios";

const PaymentPopUpPage = (props) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => authUser
        ? <PaymentPopUpForm showPaymentButton={props.showPaymentButton} course={props.course} index={props.index} authUser={authUser.uid} />
        : <PaymentPopUpForm showPaymentButton={props.showPaymentButton} course={props.course} index={props.index} />}
    </AuthUserContext.Consumer>
  </div>
);

const INITIAL_STATE = {
  errorMessage: "",
  successMessage: "",
  hidePaymentButton: true,
  userInfo: {
    personalDetails: {
      userName: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      city: "",
      state: "",
      country: "",
      collegeCompany: "",
      designation: ""
    },
    socialMedia: {
      linkedin: "",
      facebook: "",
      twitter: "",
      website: ""
    },
    workExperience: {
      designation: "",
      company: "",
      jobRole: "",
      industry: "",
      experience: ""
    },
    invoices: [
      {
        invoice_id: "test",
        invoice_link: ""
      }
    ],
    courseSubscriptions: [
      "test"
    ],
    certificates: [
      "test"
    ],
    payments: [
      {
        payment_id: "test",
        order_id: "test",
        signature: "test",
        paidFor: "test",
        paidDate: Date().toLocaleString(),
        trainingMode: "test",
        amountPaid: "test"
      }
    ],
    badges: [
      "test"
    ]
  },
  userInfoErrorMessages: {
    userNameMessage: '',
    mobileMessage: '',
    emailMessage: ''
  },
  userInfoInputStatus: {
    userNameStatus: true,
    mobileStatus: false,
    emailStatus: true,
    btnActive: false,
  }
};

class PaymentPopUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    await this.props.firebase.user(this.props.authUser).on('value', snapshot => {
      const user = snapshot.val();
      if (user) {
        const { userInfo, userInfoInputStatus } = this.state;
        if (this._isMounted) {
          this.setState({
            userInfo: {
              ...userInfo,
              personalDetails: user.personalDetails,
              socialMedia: user.socialMedia,
              workExperience: user.workExperience,
              badges: user.badges,
              certificates: user.certificates,
              courseSubscriptions: user.courseSubscriptions,
              invoices: user.invoices,
              payments: user.payments,
            }
          })
          if(user.personalDetails.userName!=="" && user.personalDetails.mobile!=="" && user.personalDetails.email!==""){
            this.setState({userInfoInputStatus: {...userInfoInputStatus, btnActive : true}})
          }
        }
      } else {
        if (this._isMounted) {
          this.setState({ ...INITIAL_STATE })
        }
      }
    })

  }
  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.user(this.props.authUser).off();
  }
  togglePayment = () => {
    if (this._isMounted) {
      this.setState({ hidePaymentButton: false });
    }
    this.updateProfile();
    this.saveToDB();
  }
  backToConfirmDetails = () => {
    if (this._isMounted) {
      this.setState({ hidePaymentButton: true });
    }
  }

  handleChange = (event) => {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (this._isMounted) {
      this.setState(prevState => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          personalDetails: {
            ...prevState.userInfo.personalDetails,
            [name]: value
          }
        }
      })
      )
    }
    this.validate(name, value);
  }

  validate = (name, value) => {
    let userInfoInputStatus = this.state.userInfoInputStatus;
    let userInfoErrorMessages = this.state.userInfoErrorMessages;

    switch (name) {
      case 'userName':
        if (value !== "") {
          userInfoInputStatus.userNameStatus = true;
          userInfoErrorMessages.userNameMessage = "";
        } else {
          userInfoInputStatus.userNameStatus = false;
          userInfoErrorMessages.userNameMessage = "Enter User Name";
        }
        break;
      case 'email':
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value !== "" && emailRex.test(value)) {
          userInfoInputStatus.emailStatus = true;
          userInfoErrorMessages.emailMessage = "";
        } else if (value === "") {
          userInfoInputStatus.emailStatus = false;
          userInfoErrorMessages.emailMessage = "Enter Valid Email Id";
        } else {
          userInfoInputStatus.emailStatus = false
          userInfoErrorMessages.emailMessage = "Invalid Email Id!";
        }
        break;
      case 'mobile':
        if (value !== "" && (value >= 6000000000 && value <= 9999999999)) {
          userInfoInputStatus.mobileStatus = true;
          userInfoErrorMessages.mobileMessage = "";
        } else if (value === "") {
          userInfoInputStatus.mobileStatus = false;
          userInfoErrorMessages.mobileMessage = "Enter Mobile Number";
        } else {
          userInfoInputStatus.mobileStatus = false;
          userInfoErrorMessages.mobileMessage = "Invalid Mobile Number";
        }
        break;
      default:
        break;
    }
    const regBtn = userInfoInputStatus.userNameStatus && userInfoInputStatus.emailStatus && userInfoInputStatus.mobileStatus;
    if (regBtn && this._isMounted) {
      this.setState({
        userInfoInputStatus: { ...userInfoInputStatus, btnActive: true }
      })
    } else {
      if (this._isMounted) {
        this.setState({
          userInfoInputStatus: { ...userInfoInputStatus, btnActive: false }
        })
      }
    }
  }

  updateProfile = async () => {
    const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, badges, payments } = this.state.userInfo;
    await this.props.firebase
      .user(this.props.authUser)
      .set({
        personalDetails: personalDetails,
        socialMedia,
        workExperience,
        invoices,
        courseSubscriptions,
        certificates,
        badges,
        payments
    })
  }

  saveToDB = () => {
    var date = new Date();
    var courseWiseUrl = "https://aviaterobotics.firebaseio.com/enroll/" + this.props.course + "/" + this.state.userInfo.personalDetails.email.split('@', 1) + ".json";
    var datewiseUrl = "https://aviaterobotics.firebaseio.com/dailytracker/" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + "/" + this.props.course + "/" + this.state.userInfo.personalDetails.email.split('@', 1) + ".json";
    axios.get(courseWiseUrl).then((user) => {
      if (user.data === null) {
        axios.post(courseWiseUrl, {
          UserName: this.state.userInfo.personalDetails.userName,
          Mobile: this.state.userInfo.personalDetails.mobile,
          Email: this.state.userInfo.personalDetails.email,
          Time: Date().toLocaleString()
        }).then(success => {
          // To make a refreshed page look after the submit button is pressed
          if (this._isMounted) {
            this.setState({ successMessage: success.message })
          }
        }).catch(error => {
          if (error.response) {
            if (this._isMounted) {
              this.setState({ errorMessage: error.response.data.message, successMessage: "" });
            }
          } else {
            if (this._isMounted) {
              this.setState({ errorMessage: error.message, successMessage: "" });
            }
          }
        })

        axios.post(datewiseUrl, {
          UserName: this.state.userInfo.personalDetails.userName,
          Mobile: this.state.userInfo.personalDetails.mobile,
          Email: this.state.userInfo.personalDetails.email,
          Time: Date().toLocaleString()
        }).then(success => {
          // To make a refreshed page look after the submit button is pressed
          if (this._isMounted) {
            this.setState({ successMessage: success.message })
          }
        }).catch(error => {
          if (error.response) {
            if (this._isMounted) {
              this.setState({ errorMessage: error.response.data.message, successMessage: "" });
            }
          } else {
            if (this._isMounted) {
              this.setState({ errorMessage: error.message, successMessage: "" });
            }
          }
        })

      }
      else {
        if (user.data.userName !== this.state.userInfo.personalDetails.userName && user.data.mobile !== this.state.userInfo.personalDetails.mobile && user.data.email !== this.state.userInfo.personalDetails.email) {

          axios.put(courseWiseUrl, {
            UserName: this.state.userInfo.personalDetails.userName,
            Mobile: this.state.userInfo.personalDetails.mobile,
            Email: this.state.userInfo.personalDetails.email,
            Time: Date().toLocaleString()
          }).then(success => {
            // To make a refreshed page look after the submit button is pressed
            if (this._isMounted) {
              this.setState({ successMessage: success.message })
            }
          }).catch(error => {
            if (error.response) {
              if (this._isMounted) {
                this.setState({ errorMessage: error.response.data.message, successMessage: "" });
              }
            } else {
              if (this._isMounted) {
                this.setState({ errorMessage: error.message, successMessage: "" });
              }
            }
          })

          axios.put(datewiseUrl, {
            UserName: this.state.userInfo.personalDetails.userName,
            Mobile: this.state.userInfo.personalDetails.mobile,
            Email: this.state.userInfo.personalDetails.email,
            Time: Date().toLocaleString()
          }).then(success => {
            // To make a refreshed page look after the submit button is pressed
            if (this._isMounted) {
              this.setState({ successMessage: success.message })
            }
          }).catch(error => {
            if (error.response) {
              if (this._isMounted) {
                this.setState({ errorMessage: error.response.data.message, successMessage: "" });
              }
            } else {
              if (this._isMounted) {
                this.setState({ errorMessage: error.message, successMessage: "" });
              }
            }
          })
        }
      }
    }).catch((error) => {
      console.log(error)
    })

  }

  render() {
    return (
      <>
        <section >
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ?
                <Form role="form" onSubmit={this.updateProfile}>
                  {this.state.hidePaymentButton
                    ? <div><h4 className="text-center">Confirm Details</h4>
                      <FormGroup>
                        <label>User Name</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="User Name"
                            type="text"
                            onChange={this.handleChange}
                            name="userName"
                            value={this.state.userInfo.personalDetails.userName} />
                        </InputGroup>
                        <span className="text-danger" >{this.state.userInfoErrorMessages.userNameMessage}</span>
                      </FormGroup>

                      <FormGroup>
                        <label>Email</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            onChange={this.handleChange}
                            name="email"
                            value={this.state.userInfo.personalDetails.email} />
                        </InputGroup>
                        <span className="text-danger" >{this.state.userInfoErrorMessages.emailMessage}</span>
                      </FormGroup>

                      <FormGroup>
                        <label>Mobile</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Mobile"
                            type="mobile"
                            onChange={this.handleChange}
                            name="mobile"
                            value={this.state.userInfo.personalDetails.mobile} />
                        </InputGroup>
                        <span className="text-danger" >{this.state.userInfoErrorMessages.mobileMessage}</span>
                      </FormGroup>
                      <div className="text-center mt-5">
                        <Button color="secondary" disabled={!this.state.userInfoInputStatus.btnActive} onClick={this.togglePayment}>Proceed</Button>
                      </div>
                    </div>
                    : <div className="text-center">
                      <h4 className="text-center ">Proceed to Payment</h4>
                      <div className="text-left mt-5" style={{paddingLeft:"5%",paddingRight:"5%"}}>
                        <h6 className="mt-2">User Name : {this.state.userInfo.personalDetails.userName}</h6>
                        <h6 className="mt-2">Email : {this.state.userInfo.personalDetails.email}</h6>
                        <h6 className="mt-2">Mobile : {this.state.userInfo.personalDetails.mobile}</h6>
                      </div>
                      <div className="d-flex text-center mt-5" style={{paddingLeft:"5%",paddingRight:"5%"}}>
                        <Button color="secondary" onClick={this.backToConfirmDetails}>Back</Button>
                        {this._isMounted ? <Button color="secondary" className="ml-2"  onClick={this.props.showPaymentButton}>Make Payment</Button> : ""}
                      </div>
                    </div>}
                </Form> : ""}
          </AuthUserContext.Consumer>
        </section>
      </>
    );
  }
}

const PaymentPopUpForm = compose(
  withRouter,
  withFirebase,
)(PaymentPopUpFormBase);

export default PaymentPopUpPage;
export { PaymentPopUpForm };