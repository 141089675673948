/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import React, { Component } from "react";
import axios from 'axios';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../firebase';
import { compose } from 'recompose';

// core components


const SignInPage = () => (
  <div>
    <section className="section section-shaped section-lg pb-100" style={{ zIndex: 1 }}>
      <div className="shape shape-style-1 shape-default">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <Container className="mt--3">
        <Row className="justify-content-center">
          <Col lg="5">
            <SignInForm />

          </Col>
        </Row>
      </Container>
    </section>
  </div>
);
const INITIAL_STATE = {
  credentials: {
    email: '',
    password: ''
  },
  credentialErrorMessages: {
    emailMessage: '',
    passwordMessage: ''
  },
  credentialInputStatus: {
    emailStatus: false,
    passwordStatus: false
  },
  error: "",
  success: ""
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
  }

  handleChange = (event) => {
    this.setState({ error: "", success: "" })
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const { credentials } = this.state;
    this.setState({
      credentials: { ...credentials, [name]: value }
    });
    this.validate(name, value);

  }

  validate = (name, value) => {
    let credentialInputStatus = this.state.credentialInputStatus;
    let credentialErrorMessages = this.state.credentialErrorMessages;

    switch (name) {
      case 'email':
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
          credentialInputStatus.emailStatus = false
        } else {
          credentialInputStatus.emailStatus = true
        }
        break;
      case 'password':
        if (value === "") {
          credentialInputStatus.passwordStatus = false;
          credentialErrorMessages.passwordMessage = "Enter Password";
        } else {
          credentialInputStatus.passwordStatus = false;
          credentialErrorMessages.passwordMessage = "";
        }
        break;
      default:
        break;
    }
  }

  login = (e) => {
    e.preventDefault();
    const { email, password } = this.state.credentials;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((u) => {
        this.setState({
          ...INITIAL_STATE
        });
        this.props.history.push('/');
      }).catch((error) => {
        this.setState({ error: "Invalid Credentials! Try again or click forgot password or try with different sign in provider" })
      });
  }

  doResetPassword = (email) => {
    this.props.firebase
      .doResetPassword(email)
      .then((success) => {
        this.setState({ success: "We have sent you a password reset link to your mail. Please reset it and sign in again.", error: "" })
      }).catch((error) => {
        this.setState({ error: error.message, success: "" })
      })
  }

  render() {
    return (
      <>

        <Card className="bg-secondary shadow border-0" >
          <CardHeader className="bg-white pb-5">
            <div className="text-muted text-center mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <SignInGoogle />
              <SignInFacebook />
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={this.login}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={this.state.credentials.email}
                    onChange={this.handleChange} />
                </InputGroup>
                {!this.state.credentialInputStatus.emailStatus ? "" : ""}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    name="password"
                    value={this.state.credentials.password}
                    onChange={this.handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center mt-4">
                <Link
                  to="/register"
                >
                  <small>New User ? Create account</small>
                </Link>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  Sign in
                          </Button>
              </div>
              {this.state.error !== "" ? <Alert color="danger">{this.state.error}</Alert> : ""}
              {this.state.success !== "" ? <Alert color="success">{this.state.success}</Alert> : ""}
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="8">
            <Link
              to="#"
              className="text-light"
              onClick={() => this.doResetPassword(this.state.credentials.email)}
            >
              <small>Forgot password?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="2">
            <Link
              className="text-light"
              to="/privacy"
            >
              <small>Privacy</small>
            </Link>
          </Col>
          <Col className="text-right" xs="2">
            <Link
              className="text-light"
              to="/termsofuse"
            >
              <small>Terms</small>
            </Link>
          </Col>
        </Row>

      </>
    );
  }
}

const GOOGLE_INITIAL_STATE = {
  userInfo: {
    personalDetails: {
      userName: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      city: "",
      state: "",
      country: "",
      collegeCompany: "",
      designation: ""
    },
    socialMedia: {
      linkedin: "",
      facebook: "",
      twitter: "",
      website: ""
    },
    workExperience: {
      designation: "",
      company: "",
      jobRole: "",
      industry: "",
      experience: ""
    },
    invoices: [
      {
        invoice_id: "test",
        invoice_link: ""
      }
    ],
    courseSubscriptions: [
      "test"
    ],
    certificates: [
      "test"
    ],
    payments: [
      {
        payment_id: "test",
        order_id: "test",
        signature: "test",
        paidFor: "test",
        paidDate: Date().toLocaleString(),
        trainingMode: "test",
        amountPaid: "test"
      }
    ],
    badges: [
      "test"
    ],
    error: null
  }
}
class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...GOOGLE_INITIAL_STATE
    }
  }

  onSubmit = event => {

    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, payments, badges } = this.state.userInfo;

        axios.get("https://aviaterobotics.firebaseio.com/users/" + socialAuthUser.user.uid + ".json").then(user => {
          if (user.data !== null) {
            return this.props.firebase
              .user(socialAuthUser.user.uid)
              .set({
                personalDetails: user.data.personalDetails,
                socialMedia: user.data.socialMedia,
                workExperience: user.data.workExperience,
                invoices: user.data.invoices,
                courseSubscriptions: user.data.courseSubscriptions,
                certificates: user.data.certificates,
                payments: user.data.payments,
                badges: user.data.badges
              });
          } else {
            return this.props.firebase
              .user(socialAuthUser.user.uid)
              .set({
                personalDetails: {
                  userName: socialAuthUser.user.displayName,
                  firstName: socialAuthUser.user.displayName.split(" ")[0],
                  lastName: socialAuthUser.user.displayName.split(" ").pop(),
                  mobile: personalDetails.mobile,
                  email: socialAuthUser.user.email,
                  city: personalDetails.city,
                  state: personalDetails.state,
                  country: personalDetails.country,
                  collegeCompany: personalDetails.collegeCompany,
                  designation: personalDetails.designation
                },
                socialMedia,
                workExperience,
                invoices,
                courseSubscriptions,
                certificates,
                payments,
                badges
              });
          }
        })
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push('/');
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;
    return (
      <>
        <Button
          className="btn-neutral btn-icon ml-1"
          color="default"
          onClick={this.onSubmit}
        >
          <span className="btn-inner--icon mr-1">
            <img
              alt="..."
              src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Fgoogle.svg?alt=media&token=4398968c-67ac-4b55-a365-2d0fa989cf30"
            />
          </span>
          <span className="btn-inner--text">Google</span>

        </Button>
        {error && <p>{error.message}</p>}
      </>

    );
  }
}

const FACEBOOK_INITIAL_STATE = {
  userInfo: {
    personalDetails: {
      userName: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      city: "",
      state: "",
      country: "",
      collegeCompany: "",
      designation: ""
    },
    socialMedia: {
      linkedin: "",
      facebook: "",
      twitter: "",
      website: ""
    },
    workExperience: {
      designation: "",
      company: "",
      jobRole: "",
      industry: "",
      experience: ""
    },
    invoices: [
      {
        invoice_id: "test",
        invoice_link: ""
      }
    ],
    courseSubscriptions: [
      "test"
    ],
    certificates: [
      "test"
    ],
    payments: [
      {
        payment_id: "test",
        order_id: "test",
        signature: "test",
        paidFor: "test",
        paidDate: Date().toLocaleString(),
        trainingMode: "test",
        amountPaid: "test"
      }
    ],
    badges: [
      "test"
    ],
    error: null
  }
}
class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...FACEBOOK_INITIAL_STATE
    };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, payments, badges } = this.state.userInfo;
        axios.get("https://aviaterobotics.firebaseio.com/users/" + socialAuthUser.user.uid + ".json").then(user => {
          if (user.data !== null) {
            return this.props.firebase
              .user(socialAuthUser.user.uid)
              .set({
                personalDetails: user.data.personalDetails,
                socialMedia: user.data.socialMedia,
                workExperience: user.data.workExperience,
                invoices: user.data.invoices,
                courseSubscriptions: user.data.courseSubscriptions,
                certificates: user.data.certificates,
                payments: user.data.payments,
                badges: user.data.badges
              });
          } else {
            return this.props.firebase
              .user(socialAuthUser.user.uid)
              .set({
                personalDetails: {
                  userName: socialAuthUser.additionalUserInfo.profile.name,
                  firstName: socialAuthUser.additionalUserInfo.profile.name.split(" ")[0],
                  lastName: socialAuthUser.additionalUserInfo.profile.name.split(" ").pop(),
                  email: socialAuthUser.additionalUserInfo.profile.email,
                  mobile: personalDetails.mobile,
                  city: personalDetails.city,
                  state: personalDetails.state,
                  country: personalDetails.country,
                  collegeCompany: personalDetails.collegeCompany,
                  designation: personalDetails.designation
                },
                socialMedia,
                workExperience,
                invoices,
                courseSubscriptions,
                certificates,
                payments,
                badges
              });
          }
        })
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push('/');
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <>
        <Button
          className="btn-neutral btn-icon ml-1"
          color="default"
          onClick={this.onSubmit}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-facebook" />
          </span>
          <span className="btn-inner--text">Facebook</span>
        </Button>
        {error && <p>{error.message}</p>}
      </>
    );
  }
}


const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook };
