/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";
// core components

class Refunds extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section pb-0 pt-0">
                        <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <div className="mt-5 ">
                                <h3 className="">Aviate Refund Policy</h3>
                                <p>Last Updated: Sep 5, 2020</p>
                                <p className="description"><i>Our mission at Aviate is to provide access to high-quality Technical courses for everyone, everywhere at affordable cost.</i></p>
                            </div>
                        <div className="mt-5">
                                <div className="pl-5 pr-5">
                                <p className="description"> Thank you for buying our courses. We ensure that our users have an awful experience while 
                                they discover, assess, and purchase our courses, whether it is an mentor-led or self-paced training.</p>
                                <p className="description" >As with any online purchase experience, there are terms and conditions that govern the Refund Policy. When you buy a training course on Aviate, you agree to our Privacy Policy, Terms of Use and refund policy. </p>
                                </div>
                        </div>
                        <div className="mt-5">
                            <p className="mt-3"><b>Cancellation & Refunds: Online Training</b></p>
                                <div className="pl-5 pr-5">
                                <p className="mt-3"><b>For Self Placed Learning:</b></p>
                                <p className="description ">Raise refund request within 7 days of purchase of course. Money back guarantee is void if the participant has accessed more than 25% content or downloaded the E-Book. Any refund request beyond 7 days of purchasing the course will not be accepted and no refund will be provided.</p>
                                
                                <p className="mt-3"><b>For Mentor led Learning:</b></p>
                                <p className="description ">Raise refund request within 7 days of purchase of course. Money back guarantee is void if the participant has accessed more than 25% content of any e-learning course or has attended Online Classrooms/received recordings for more than 1 day.
Also, In case a user downloads the E-Book for the course the money back guarantee will be void. Any refund request beyond 7 days of purchasing the course will not be accepted and no refund will be provided.</p>
                                
                                <p className="mt-3"><b>Cancellation & Refunds: Online Training</b></p>
                                <p className="description ">Aviate, reserves the right to postpone/cancel a course, or change the timings of course because of insufficient enrollments, instructor illness or force majeure events (like floods, earthquakes, political instability, etc)</p>
                                <ul>
                                    <li className="mt-3">
                                    <p className="description">In case Aviate cancels a course, 100% of course fees will be refunded to the delegate if the refund raise request is within 10 days of purchase of course. However logistics or any personal expense incurred by learners/participants will not be refunded.</p>
                                    </li>
                                    <li className="mt-3">
                                    <p className="description">In case Aviate cancels/postpones a course, the participants who are ahead of 10 days of purchase, of course, will be rescheduled to any upcoming batch without any extra charges.</p>
                                    </li>
                                    <li className="mt-3">
                                    <p className="description">If a cancellation is done by a delegate 10 business days (or more) prior to the course, 10% of the total paid fee will be deducted and the remaining amount will be refunded to the delegate.</p>
                                    </li>
                                    <li className="mt-3">
                                    <p className="description">If a cancellation is done by a delegate within 10 business days (or less) of the event, no refunds will be made.</p>
                                    </li>
                                </ul>

                                <p className="mt-3"><b>How to initate Refund request</b></p>
                                <p className="description">Please reach out to our support team through our website chat bot & Support email address on the website.</p>
                                
                                <p className="mt-3"><b>Refunds: Duplicate payment</b></p>
                                <p className="description">Refund of the duplicate payment made by the delegate will be processed via the same source (original method of payment) in 7-10 working days post intimation by the customer.</p>
                                
                                <p className="mt-3"><b>Aviate reserves the right to revise the terms & conditions of this policy without any prior notice.</b></p>
                                <p className="pb-5"><i>Note: All refunds will be initiated and processed within 10 working days after the request is approved by Aviate.</i></p>
                                
                                </div>
                            </div>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}

export default Refunds;