
// 1. Embedded Robotics using AVR Controllers
// 2. Internet of Things and Automation
// 3. Embedded Robotics using ARM Cortex SIngle Board Computer
// 4. Robotic Arm Modeling and Simulation 
// 5. Embedded Systems
// 6. Robotics Modelling and Control using MATLAB
// 7. Image Processing Theory and Practice using matlab


const CoursesJson = [





    // ***********************************************************************************************************************************

    // 1. Embedded Robotics using AVR Controllers

    // *********************************************************************************************************************************** 






    {
        certificationName: "Aviate's Internship Certification program in Embedded Robotics using AVR Controllers",
        courseName: "Embedded Robotics using AVR Controllers",
        active: true, 
        nextBatchStart:  "December",
        demoPosterUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FArduino_Course%20Video.mp4?alt=media&token=ea85bdc5-362f-44ff-ae7f-0ce39d431f31",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FArduino_Robotics.svg?alt=media&token=58b457ea-9e1e-4e71-8ac6-ae78fb07ab79",
        navigateUrl: "/internship/robotics/embedded-robotics-internship-in-india",
        duration: "15 hours",
        prerequisites: "This is a Beginner Level Course for all circuit branches and mechanical engineering. No Pre Requisites Needed.",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Embedded Robotics using AVR Controllers",
            description: "Robotics is on the overlap of engineering fields such as Computer Science, Mechanical and Electronics Engineering. Right from creating the framework of a robot to Programming it with Arduino, Robotics is a complete package of innovation!",
            keyFeaturesHeading: "Robotics Key Features",
            courseKeyFeatures: ["15 hours of in-depth blended learning", "Three real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Arduino In and Out", "Sense Think and Act Cycles of Robots", "Sensor Integration", "Actutators Design and Working", "Programming the Control Board in Depth", "Interrupts and Usage", "Communication Protocols"]
        },
        relatedCourses: ["Internet of Things and Automation", "Robotic Arm Modeling and Simulation 			"],
        benefits: {
            title: "Why should I Learn Arduino Uno concepts? ",
            benefitsList: ["Arduino is a prototype platform (open-source) based on an easy-to-use hardware and software.",
                "You can control your board functions by sending a set of instructions to the microcontroller on the board via Arduino IDE.",
                "The Arduino IDE uses a simplified version of C++, making it easier to learn to program.",
                "There are a lot of jobs out there where the ability to design and build a prototype system for control or data logging would be very useful.",
                "Arduino has a tremendous potential, mainly because of the simplicity it brings to the table."
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2000",
                withMTA: "3200",
                courseFee: "2000",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2500",
                withMTA: "3700",
                courseFee: "2500",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "1000",
                withMTA: "2200",
                courseFee: "1000",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Robotics from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Robotics plays a vital role in Automation Technology, learning robotics adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Robotics."
            },
            {
                question: "What are the benefits of learning Robotics?",
                answer: "Robotics is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Robotics? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Robotics training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building robotic applications, then this Embedded Robotics using AVR Controllers course will put you on the basics of robotics platform. Robotics is one of the hottest and fastest-growing technologies for improving real-time business operations and processes. This course will give you an overview of Arduino concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Course Curriculum - Embedded Robotics using AVR Controllers",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "Lecture 1",
                    title: "Introduction",
                    duration: "1 hr",
                    description: "In this lecture , we will look into the history of Robotics.Future Scope of Robotics across all the Industry. Classisfication of Robotics and their Applications dissected in depth.",
                },
                {
                    name: "Lecture 2",
                    title: "Begginer Level MicroController for Robotics.",
                    duration: "1 hr",
                    description: "In this Lecture ,we will see about Sense Think and Act cycle. Exploring the Features of Various Micro Controllers used in the Starting phase of Prototyping",
                },
                {
                    name: "Lecture 3",
                    title: "Programming for Controller",
                    duration: "1 hr",
                    description: "In this lecture we will at a look to all the possible methods of working and uploading program into our micro controller. In depth about all the functions required for successful programming will be discussed.",
                },
                {
                    name: "Lecture 4",
                    title: "Exploring Various Sensors",
                    duration: "1 hr",
                    description: "In this lecture , we will look into the working of various Sensors along with their physcial Principles. ",
                },
                {
                    name: "Lecture 5",
                    title: "Exploring various types of Actuator Systems",
                    duration: "1 hr",
                    description: "In this Lecture we will look intoVarious Types of Actuator Systems along with their Program Structures in IDE is discussed",
                },
                {
                    name: "Lecture 6",
                    title: "Communication Protocols",
                    duration: "1 hr",
                    description: "In this lecture we will explore few of the possible Communication Protocols using our controller",
                },
                {
                    name: "Lecture 7",
                    title: "Wireless Communication",
                    duration: "1 hr",
                    description: "In this lecture we will look into the possible ways of connecting the bluetooth technology and transfer data birectionally.",
                },
                {
                    name: "Lecture 8",
                    title: "Featured Peripherals :",
                    duration: "1 hr",
                    description: "In this lecture we work  on connecting our GPRS and GPS to our Arduino and Their Programs in depth",
                },
                {
                    name: "Lecture 9",
                    title: "Featured Peripherals :",
                    duration: "1 hr",
                    description: "In this lecture we will look into In depth understanding of Intertia Measuring Units and their Programs using Microcontroller will be explained",
                },
                {
                    name: "Lecture 10",
                    title: "Hardware Implementation",
                    duration: "1 hr",
                    description: "Project - 01",
                },
                {
                    name: "Lecture 11",
                    title: "Hardware Implementation",
                    duration: "2 hrs",
                    description: "Project - 02",
                },
                {
                    name: "Lecture 12",
                    title: "Hardware Implementation",
                    duration: "2 hr",
                    description: "Project - 03",
                },
                {
                    name: "Lecture 13",
                    title: "Domestic Product Development",
                    duration: "1 hr",
                    description: "Product Analysis and Development Steps.",
                },
            ]
        }
    },





    // ***********************************************************************************************************************************

    // 2. Internet of Things and Automation

    // *********************************************************************************************************************************** 





    {
        certificationName: "Aviate's Internship Certification program in Internet of Things and Automation",
        courseName: "Internet of Things and Automation",
        active: true, 
        nextBatchStart:  "December",
        demoPosterUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FInternet_of_Things_%20Video.mp4?alt=media&token=2de44d4f-b853-4966-976a-ee7c15c691d7",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FInternet%20of%20Things.svg?alt=media&token=51760dbd-8df2-42cb-a34a-511158ac9db1",
        navigateUrl: "/internship/robotics/iot-internship-in-india",
        duration: "15 Hours",
        prerequisites: "Students having Little knowledge about Developer Boards Like Arduino can enroll this course",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Internet of Things and Automation",
            description: "The Internet of things (IoT) is the network of physical devices, vehicles, home appliances and other items embedded with electronics, software, sensors, actuators, and connectivity which enables these objects to connect and exchange data. Each thing is uniquely identifiable through its embedded computing system but is able to inter-operate within the existing Internet infrastructure.",
            keyFeaturesHeading: "Key Features ",
            courseKeyFeatures: ["15 hours of in-depth blended learning", "Two real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Understading IOT Architecture", "Different Wireless and Network protocols", "Managing Notification Alert using IFTTT", "Sensor and cloud Integration", "IoT Security"]
        },
        relatedCourses: ["Embedded Robotics using AVR Controllers"],
        benefits: {
            title: "Why Internet of Things ? ",
            benefitsList: ["There are over a billion devices globally, generating trillions of gigabytes of data every single day. Tons of companies out there are channelizing their funds and efforts on the Internet of Things to bring in precision and optimized wok-flow mechanisms.",
                "The IoT sector is disrupting the way we live and work and for any task that needs more human efforts is being reduced with the help of data and apps.This has ultimately led to the requirement of skilled IoT data professionals.",
                "From the time spreadsheets were considered niche skills and people with skills in Excel and Tally were playing the industry on God-mode, it is time for the IoT experts today to take over their roles.",
                "We believe you do understand the fact that a career switch into the IoT sector cannot happen without the development of industry-relevant skills."
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate IOT Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2500",
                withMTA: "3700",
                courseFee: "2500",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate IOT Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3000",
                withMTA: "4200",
                courseFee: "3000",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate IOT Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "1500",
                withMTA: "2700",
                courseFee: "1500",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Internet of Things from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Internet of things plays a vital role in Home Automation Technology, learning internet of things adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Internet of Things online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Internet of Things."
            },
            {
                question: "What are the benefits of learning Internet of Things?",
                answer: "Internet of Things is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Internet of Things? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Internet of Things training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building Home automation or any cloud data handling applications, then this Internet of Things and Automation course will put you on the basics of IoT platform. Internet of things is one of the hottest and fastest-growing technologies for improving real-time data operations and processes. This course will give you an overview of Thingspeak concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators."
            }
        },
        courseCurriculum: {
            heading: "Course Curriculum - Internet of Things and Automation",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "Lecture 1",
                    title: "IoT definitions:",
                    duration: "1 hr",
                    description: "What is IoT? Applications of IoT. IoT Market Analysis ",
                },
                {
                    name: "Lecture 2",
                    title: "IoT Architecture.",
                    duration: "1 hr",
                    description: "Four layer architecture.",
                },
                {
                    name: "Lecture 3",
                    title: "IoT Wireless Protocols (Advanced)",
                    duration: "1 hr",
                    description: "Wi-Fi 802.11, RFID, NFC, Blue Tooth, BLE and ZigBee",
                },
                {
                    name: "Lecture 4",
                    title: "IoT Network Protocols (Advanced)",
                    duration: "1 hr",
                    description: "MQTT/MQTTS, TCP, UDP, HTTP/s",
                },
                {
                    name: "Lecture 5",
                    title: "TCP/UDP Transport layer Protocol",
                    duration: "1 hr",
                    description: "Introduction of TCP & UDP Difference between TCP/UDP Transport layer protocol",
                },
                {
                    name: "Lecture 6",
                    title: "Working with Micro Controller and Sensors",
                    duration: "1 hr",
                    description: "Node MCU Architecture and Data acquistion from various sensors",
                },
                {
                    name: "Lecture 7",
                    title: "IoT Cloud Platform (ThingSpeak)",
                    duration: "1 hr",
                    description: "Establish HTTPS connection using Wi Fi, Send Data to ThingSpeak Rest API over HTTP.",
                },
                {
                    name: "Lecture 8",
                    title: "IoT Cloud Platform (ThingSpeak)",
                    duration: "1 hr",
                    description: "Create rules in ThingSpeak for reacts.",
                },
                {
                    name: "Lecture 9",
                    title: "IFTTT",
                    duration: "2 hrs",
                    description: "Managing Notification Alert",
                },
                {
                    name: "Lecture 10",
                    title: "Fire Alert system with IoT",
                    duration: "2 hrs",
                    description: "Develop Fire alert system with IoT",
                },
                {
                    name: "Lecture 11",
                    title: "Hardware Implementation",
                    duration: "2 hrs",
                    description: "Work with mentor for the IOT project",
                },
                {
                    name: "Lecture 12",
                    title: "IoT Security",
                    duration: "1 hr",
                    description: "How can we handle the security in IoT",
                },

            ]
        }
    },





    // ***********************************************************************************************************************************

    // 3. Embedded Robotics using ARM Cortex SIngle Board Computer

    // *********************************************************************************************************************************** 





    {
        certificationName: "Aviate's  Internship Certification program in Embedded Robotics using ARM Cortex SIngle Board Computer",
        courseName: "Embedded Robotics using ARM Cortex SIngle Board Computer",
        active: true, 
        nextBatchStart:  "December",
        demoPosterUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FRaspberrypi_Course%20Video.mp4?alt=media&token=d9eaeaf8-7170-4d16-a453-ebcd2d62b67f",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FRaspberrypi_Course.svg?alt=media&token=6c52e1c0-1e39-49b0-ae67-11e6fbcce36d",
        navigateUrl: "/internship/robotics/raspberry-pi-internship-in-india",
        duration: "20 Hours",
        prerequisites: "Students having Little knowledge about Developer Boards Like Arduino can enroll this course",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Embedded Robotics using ARM Cortex SIngle Board Computer",
            description: "This course is an introduction to the Raspberry Pi platform. It is catered for all levels and those interested in learning about the Raspberry Pi and its capabilities.The Raspberry Pi is a supremely versatile machine and perfect for any kind of side project or hobby where cost, size or power consumption is a limitation. There is never a shortage of ideas as the ever growing community of enthusiasts continue to find new and imaginative ways to put their Pi’s to work.",
            keyFeaturesHeading: "Key Features",
            courseKeyFeatures: ["20 hours of in-depth blended learning", "Two real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Understading The requirements of a Single Board Computer", "Differences in Operating Systems for a Pocket computers", "Linux Essentials for a Quick Start of Hardware.", "Hooking Up Different Hardwares to the GPIO", "Understanding Various Editors and Programming Languages to Run the Hardware.", "Sensor Integration", "Serial Communications"]
        },
        relatedCourses: ["Image Processing Theory and Practice using matlab", "Embedded Robotics using AVR Controllers"],
        benefits: {
            title: "Why you must Learn about Embedded Robotics using ARM Cortex SIngle Board Computer ? ",
            benefitsList: ["You will be using Raspberry Pi as an extremely valuable device if you are an advanced hacker with an embedded application that needs a credit card-size full-blown computing environment.",
                "Raspi is a controller which will not only help you throughout your BE for making various projects but would also make a pathway for you to learn more complex and powerful controllers.",
                "Learning these boards early in your engineering opens up your mind to the many possibilities of innovation. Your brain will see various machines differently.",
                "Raspberry Pi has a great real time product development compnies, mainly because of the simplicity it brings to the table.",
                "There are a lot of jobs out there where the ability to design and build a prototype using Raspberry Pi."
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2500",
                withMTA: "3700",
                courseFee: "2500",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3000",
                withMTA: "4200",
                courseFee: "3000",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Engineer", "MTA - Microsoft Technology Associate"],
                withoutMTA: "1500",
                withMTA: "2700",
                courseFee: "1500",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Embedded Robotics using ARM Cortex SIngle Board Computer from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Robotics plays a vital role in Automation Technology, learning robotics adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Robotics."
            },
            {
                question: "What are the benefits of learning Embedded Robotics using ARM Cortex SIngle Board Computer?",
                answer: "Robotics is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Embedded Robotics using ARM Cortex SIngle Board Computer? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Embedded Robotics using ARM Cortex SIngle Board Computer training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building robotic applications using Raspberry Pi, then this Embedded Robotics using ARM Cortex SIngle Board Computer course will put you on the basics of robotics platform. Robotics is one of the hottest and fastest-growing technologies for improving real-time business operations and processes. This course will give you an overview of Raspberry Pi concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Course Curriculum - Embedded Robotics using ARM Cortex SIngle Board Computer",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "Lecture 1",
                    title: "Essentials",
                    duration: "1 hr",
                    description: "In this lecture we will consider all the essentials that are required to start up with a Raspberry Pi.",
                },
                {
                    name: "Lecture 2",
                    title: "Programming Raspberry Pi",
                    duration: "1.5 hrs",
                    description: "In this lecture we see about Linux commands . Creating , Saving and Manipulating using Files and We will start Programming in Python",
                },
                {
                    name: "Lecture 3",
                    title: "Introduction C/C++ ",
                    duration: "1.5 hrs",
                    description: "In this Lecture we will see abou the basics of C++ Programming Language and Importance In Raspi",
                },
                {
                    name: "Lecture 4",
                    title: "Working with Speech Input and Output using raspberry pi",
                    duration: "2 hrs",
                    description: "In this lecture we will try to implement a Speech to text and back using various Libraries of raspberry pi",
                },
                {
                    name: "Lecture 5",
                    title: "Add Vision",
                    duration: "2 hrs",
                    description: "In this lecture we will discuss various ways of hooking up cameras and their dependencies for installation and working with raspi camera.",
                },
                {
                    name: "Lecture 6",
                    title: "Mobile Robot ",
                    duration: "2 hrs",
                    description: "In this lecture we will try to Connect extra peripherals like motor controller and Program them using our raspberry pi",
                },
                {
                    name: "Lecture 7",
                    title: "Sensor Integration ",
                    duration: "2 hrs",
                    description: "In this lecture we will try to integrate multiple Sensors and display on a Dashboard.",
                },
                {
                    name: "Lecture 8",
                    title: "Localization",
                    duration: "2 hrs",
                    description: "Using GPS with raspberry pi to record the location.",
                },
                {
                    name: "Lecture 9",
                    title: "Hardware Implementation",
                    duration: "1.5 hrs",
                    description: "Project - 01",
                },
                {
                    name: "Lecture 10",
                    title: "Serial Communications",
                    duration: "2 hrs",
                    description: "Using the Serial Commnunications like SPI and I2c for the slave device using Raspberry pi and controls",
                },
                {
                    name: "Lecture 11",
                    title: "Hardware Implementation",
                    duration: "1.5 hrs",
                    description: "Project - 02",
                },
                {
                    name: "Lecture 12",
                    title: "Domestic Product Contest",
                    duration: "1 hr",
                    description: "Analysis and Ideation of a Prototype for a Problem Statement",
                },

            ]
        }
    },





    // ***********************************************************************************************************************************

    // 4. Robotic Arm Modeling and Simulation 

    // *********************************************************************************************************************************** 





    {
        certificationName: "Aviate's  Internship Certification program in Robotic Arm Modeling and Simulation ",
        courseName: "Robotic Arm Modeling and Simulation 			",
        active: true, 
        nextBatchStart:  "December",
        demoPosterUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FRobotic_Arm_%20Video.mp4?alt=media&token=0c69c018-0de0-462c-9927-37d3c7ec5351",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FRobotic%20Arm_course.svg?alt=media&token=fe4f6444-4986-4d2b-a8a5-eb25a66c969c",
        navigateUrl: "/internship/robotics/roboticarm-internship-in-india",
        duration: "12 Hours",
        prerequisites: "Basic knowledge on coding is required.",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Robotic Arm Modeling and Simulation 	",
            description: "Robotics is on the overlap of engineering fields such as Computer Science, Mechanical and Electronics Engineering.It is all about integrating hardware and software. In this course, you’ll design, build and program a simple robot, with at least two joints, that can carry a pen or pencil and draw a coordinated line on a sheet of a paper.",
            keyFeaturesHeading: "key features",
            courseKeyFeatures: ["12 hours of in-depth blended learning", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Robotic Arm Kinematics", "Forward Kinematics", "Inverse Kinematics ", "Trajectory and Path", "DH Parameters"]
        },
        relatedCourses: ["Robotics Modelling and Control using MATLAB", "Image Processing Theory and Practice using matlab"],
        benefits: {
            title: "Why to Master Robotic Arm? ",
            benefitsList: ["For the young engineering student looking to enter robotics, there are key areas of study that one should focus on is ROBOTIC ARM.",
                "The International Federation of Robotics (IFR) estimates that by 2020, there were around 2.8 million stand-alone industrial robots installed worldwide. Where Majority holds by Robotic Arms.",
                "Robotic Arm is one of the key Technology of Automobile and Other Big Manufacturing Technology.",
                "Lot of Countries have small Technology startups who need young Engineers with Proficient Knowledge on Robotic Arm.",
                "Companies Like Funac , Cobots , Kuka Need Young Professionals with core knowledge of Robotic Arm"
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3700",
                withMTA: "4900",
                courseFee: "3700",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "4250",
                withMTA: "5450",
                courseFee: "4250",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2700",
                withMTA: "3900",
                courseFee: "2700",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Robotic Arm  from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Robotics plays a vital role in Automation Technology, learning robotics adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Robotics."
            },
            {
                question: "What are the benefits of learning Robotic Arm?",
                answer: "Robotics is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Robotic Arm? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Robotic Arm training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building robotic applications by building Robotic Arm, then this Robotic Arm Modeling and Simulation course will put you on the basics of robotics platform. Robotics is one of the hottest and fastest-growing technologies for improving real-time business operations and processes. This course will give you an overview of Robotic Arm concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Course Curriculum - Robotic Arm Modeling and Simulation 			",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "lecture 1",
                    title: "Introduction",
                    duration: "2.5 hrs",
                    description: "In this lecture we will go back to the history and see the future of Robotic Arm Applications. Various Companies and Their Robotic Arms are being investigated",
                },
                {
                    name: "lecture 2",
                    title: "Foundations for Understanding Indepth about Robotic arm",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discet indepth about all the mathematical concepts required to understand a robotic arm operations",
                },
                {
                    name: "lecture 3",
                    title: "Robotic Arm Kinematics",
                    duration: "2.5 hrs",
                    description: "In this lecture we will make discuss the basics of Kinematics that are used for caluculating the robotic arm pose",
                },
                {
                    name: "lecture 4",
                    title: "Forward Kinematics ",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discuss in depth about the Kinematics of 3Dimensional Robotic Arm.",
                },
                {
                    name: "lecture 5",
                    title: "Inverse Kinematics ",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discuss in depth about the Inverse Kinematics of 3D and 2D Robotic arm",
                },
                {
                    name: "lecture 6",
                    title: "Trajectory and Path",
                    duration: "2.5 hrs",
                    description: "In this lecture we will discuss about the Path planning for a Robotic arm to pass via all the given points in the trajectory along with the Joint space motion and Cartesian Motion.",
                },
                {
                    name: "lecture 7",
                    title: "DH Parameters",
                    duration: "2.5 hrs",
                    description: "In this lecture we will discuss the impotance of DH parameter and also the modifications of a Industrial Robotic arm and their Effects.",
                },
                {
                    name: "lecture 8",
                    title: "Smart Application",
                    duration: "2.5 hrs",
                    description: "Here we will demonstrate how a simple walking robot with different joints and links can be executed in matlab.",
                }
            ]
        }
    },




    // ***********************************************************************************************************************************

    //                                                      5. Embedded Systems

    // *********************************************************************************************************************************** 




    {
        certificationName: "Aviate's  Internship Certification program in Embedded Systems",
        courseName: "Embedded Systems in Depth ",
        active: true, 
        nextBatchStart:  "December",
        demoPosterUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FEmbedded_Systems_%20Video.mp4?alt=media&token=22c38f3f-d855-436f-b924-82caef10f804",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FEmbedded%20Systems.svg?alt=media&token=9f92c371-a42b-4358-ad01-aeb469e8d7c4",
        navigateUrl: "/internship/robotics/embeddedsystems-internship-in-india",
        duration: "18 Hours",
        prerequisites: "Basic Knowledge of MicroController and Microprocessor",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Embedded Systems",
            description: "Embedded Systems is on the overlap of engineering fields such as Computer Science, Mechanical and Electronics Engineering.It is all about integrating hardware and software. In this course, you’ll design, build and program a simple embedded systems application, starting from power supply to getting input from sensor data.",
            keyFeaturesHeading: "key features",
            courseKeyFeatures: ["18 hours of in-depth blended learning", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Gain comprehensive knowledge about architecture and addressing modes of 8051", "Write assembly language program in 8051 for various embedded system applications", "Implement the middle level programming and interfacing concepts in 8051", "Use external interfaces in various embedded system projects", "Create the memory interfacing techniques with 8051", "Create the IO interfacing techniques with 8051", " Overview about ARM Controllers"]
        },
        relatedCourses: [],
        benefits: {
            title: "Why Should you Learn Embedded systems? ",
            benefitsList: ["Embedded systems are where the software meets the physical world. As we put tiny computers into all sorts of systems (door locks, airplanes, pacemakers), how we implement the software is truly, terrifyingly important.",
                "Embedded Systems has brought about a revolution in Science. It is also a part of a Internet of Things (IoT) – a technology in which objects, animals or people are provided with unique identifiers and the ability to transfer data over a network without requiring human-to-human or human-to-computer interaction.",
                "8051 is the first step to introduce yourself to the embedded system world and it makes you familiar to all  developed embedded technologies.",
                "It is best path for choosing the career wise  because,value of the embedded systems industries  in coming years will be 160 billion usd ,so be the part of revolution."
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Embedded Systems Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3500",
                withMTA: "4700",
                courseFee: "3500",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Embedded Systems Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "4000",
                withMTA: "5200",
                courseFee: "4000",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Embedded Systems Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "2500",
                withMTA: "3700",
                courseFee: "2500",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Embedded Systems ? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. The demand side is in place; BEL, ECIL for Defense Electronics, BPL for Telecom, Videocon (after Thompson Picture Tubes plant acquisition, Videocon is the worldís No 3 TV tube manufacturer) for consumer electronics, VXL for thin clients (No 3 globally, as per Gartner) are examples of Indian corporations; Motorola, Philips, Siemens, GE, Nokia, Samsung, Elcoteq, Flextronics are present and increasing their stake in India; that should take care of demand; of course the global demand is there too. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Embedded Systems."
            },
            {
                question: " Why AVIATE for Online Training on Embedded Systems ? ",
                answer: "We teach basics and underlying concepts of Embedded Systems in detail, and provide real-time experience to students. This will help each student to not only understand the concept, but also the knowledge of applying concepts in real time situation. We cover the Micro controllers in great depth: Intel 8051 and ARM 9 series. In addition we go for the hardware interfacing in detail.Our continuous liaison with Embedded industry has enabled us to know their expectations of each candidate. So, our highly experienced faculties have made sure that our students come out as industry ready professionals."
            },
            {
                question: "What are the benefits of learning Embedded Systems?",
                answer: "A recent Merrill Lynch report on India IT services, places the revenues from core technology including revenues from high-end software development and hardware solutions for Indian software companies between $800 million and $1 billion in fiscal 2001 and $4 billion by 2005. Sure, the Indian IT players especially those operating in the embedded software, DSP and telecom industry have set their eyes clearly on the emerging hardware design services industry."
            },
            {
                question: "What is the eligibility to pursue Embedded systems & VLSI Design Courses? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Embedded Systems training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building Embedded Systems applications, then this Embedded Systems In Depth course will put you on the Embedded Depth platform. Embedded Systems is one of the hottest and fastest-growing technologies for improving real-time business operations and processes through processors. This course will give you an overview of Embedded system concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Embedded Systems Certification Course",
            description: "Embedded systems are self-contained programs that are embedded within a piece of hardware. We can say itís a specialized computer system that is part of a larger system or machine. Typically, an embedded system is housed on a single microprocessor board with the programs stored in ROM. Embedded system can also be defined as a computer system that is created with optimal efficiency, thereby allowing it to complete specific functions as quickly as possible. Embedded systems microprocessors are programmed to automate certain tasks. Cell phones, computers, copiers, medical equipment, programmable logic controllers, and numerous other products rely on embedded systems. Real time embedded systems can be used to replace traditional software applications.",
            courseContents: [
                {
                    name: "Lecture 1",
                    title: "Introduction",
                    duration: "1 hr",
                    description: "understanding the evolution of embedded systems and family of Microcontrollers ,different types of instructions and architectures",
                },
                {
                    name: "Lecture 2",
                    title: "8051 introduction",
                    duration: "2 hrs",
                    description: "Sailent features of 8051,Pin diagram and Architecture",
                },
                {
                    name: "Lecture 3",
                    title: "Instructions and adressing Modes",
                    duration: "3 hrs",
                    description: "Understanding different types of addressing modes and instruction set to program the 8051",
                },
                {
                    name: "Lecture 4",
                    title: "Introduction to keil compiler and proteus simulator",
                    duration: "1 hr",
                    description: "Learning about keil compiler and proteus simulator",
                },
                {
                    name: "Lecture 5",
                    title: "Interfacing LCD with 8051",
                    duration: "1 hr",
                    description: "Interfacing 16x2 Hitachi lcd to 8051 to display the data depend on user",
                },
                {
                    name: "Lecture 6",
                    title: "keypad module ",
                    duration: "1 hr",
                    description: "Developng an keypad and interfacing with 8051",
                },
                {
                    name: "Lecture 7",
                    title: "Introduction to timers",
                    duration: "1 hr",
                    description: "Understanding the operation of timers in 8051 and different operating modes",
                },
                {
                    name: "Lecture 8",
                    title: "Introduction to Serial communication",
                    duration: "1 hr",
                    description: "Overview on different types of communications and understanding serial communication suuported by 8051 . ",
                },
                {
                    name: "Lecture 9",
                    title: "Introduction to interrupts",
                    duration: "1 hr",
                    description: "Understanding the interrupt mechanism ,vectored interrupts,interrpts of 8051 and its priority management",
                },
                {
                    name: "Lecture 10",
                    title: "Communication Protocols",
                    duration: "2 hrs",
                    description: "Understanding different types of communication protocols ,its data speeds  and no of wires.",
                },
                {
                    name: "Lecture 11",
                    title: "I2c programming ",
                    duration: "1 hr",
                    description: "Developing an I2C protocol using bitbang method and interfacing to I2C supported device",
                },
                {
                    name: "Lecture 12",
                    title: "Introduction to ARM",
                    duration: "1 hr",
                    description: "Understanding different versions of ARM and features supported by particular version,pipeline method",
                },
                {
                    name: "Lecture 13",
                    title: "Programming",
                    duration: "1 hr",
                    description: "Procedure to install required drivers using cubemx or keil and writing the programs",
                },
                {
                    name: "Lecture 14",
                    title: "Introduction to stm32 controller",
                    duration: "1 hr",
                    description: "Understanding the features of stm32 and programming led blinking",
                }



            ]

        }
    },




    // ***********************************************************************************************************************************

    //                                           6. Robotics Modelling and Control using MATLAB

    // *********************************************************************************************************************************** 





    {
        certificationName: "Aviate's  Internship Certification program in Robotics Modelling and Control using MATLAB",
        courseName: "Robotics Modelling and Control using MATLAB",
        active: false,
        nextBatchStart:  "January",
        demoPosterUrl: "",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FComing_Soon_02.svg?alt=media&token=d85b124a-dfc6-4f74-ac5a-586be1cfcce7",
        navigateUrl: "/internship/robotics/roboticsmodelling-internship-in-india",
        duration: "20 hours",
        prerequisites: "No Pre Requisites Needed. Having some basic knowledge about robotics is well enough.",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Robotics Modelling and Control using MATLAB",
            description: "Robotics is on the overlap of engineering fields such as Computer Science, Mechanical and Electronics Engineering.It is all about integrating hardware and software. In this course, you’ll design, build and program a simple robot, with at least two joints, that can carry a pen or pencil and draw a coordinated line on a sheet of a paper.",
            keyFeaturesHeading: "key Features",
            courseKeyFeatures: ["20 hours of in-depth blended learning", "Two real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Understanding Indepth about Robotic arm", "Robot Arm Kinematics", "Forward Kinematics", "Inverse Kinematics", "Trajectory and path", "DH Paramters"]
        },
        relatedCourses: ["Robotic Arm Modeling and Simulation 			", "Kalman Filters", "Image Processing Theory and Practice using matlab"],
        benefits: {
            title: "Why to learn Robotic Modelling? ",
            benefitsList: ["For the young engineering student looking to enter robotics, there are key areas of study that one should focus on is ROBOTICS MODELLING.",
                "The International Federation of Robotics (IFR) estimates that by 2020, there were around 2.8 million stand-alone industrial robots installed worldwide. Where Majority holds by Robotic Modelling.",
                "Robotic Arm is one of the key Technology of Automobile and Other Big Manufacturing Technology.",
                "Lot of Countries have small Technology startups who need young Engineers with Proficient Knowledge on Robotic Modelling.",
                "Companies Like Funac , Cobots , Kuka Need Young Professionals with core knowledge of Robotic Modelling"
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "1",
                withMTA: "2",
                courseFee: "1",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "5999",
                withMTA: "6999",
                courseFee: "5999",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Robotics Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3999",
                withMTA: "4999",
                courseFee: "3999",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Robotics Modelling from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Robotics plays a vital role in Automation Technology, learning robotics adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Robotics."
            },
            {
                question: "What are the benefits of learning Robotics Modelling?",
                answer: "Robotics is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Robotics Modelling? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Robotics Modelling training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building robotic applications, then this Robotics Modelling and Controlling using Matlab course will put you on the basics of robotics modelling platform. Robotics is one of the hottest and fastest-growing technologies for improving real-time business operations and processes. This course will give you an overview of Robotics Modelling concepts, the value-add it brings with relevant business use cases and tools understanding."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Course curriculum - Robotics Modelling and Control using MATLAB",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "lecture 1",
                    title: "Introduction",
                    duration: "2.5 hrs",
                    description: "In this lecture we will go back to the history and see the future of Robotic Arm Applications. Various Companies and Their Robotic Arms are being investigated",
                },
                {
                    name: "lecture 2",
                    title: "Foundations for Understanding Indepth about Robotic arm",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discet indepth about all the mathematical concepts required to understand a robotic arm operations",
                },
                {
                    name: "lecture 3",
                    title: "Robotic Arm Kinematics",
                    duration: "2.5 hrs",
                    description: "In this lecture we will make discuss the basics of Kinematics that are used for caluculating the robotic arm pose",
                },
                {
                    name: "lecture 4",
                    title: "Forward Kinematics ",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discuss in depth about the Kinematics of 3Dimensional Robotic Arm.",
                },
                {
                    name: "lecture 5",
                    title: "Inverse Kinematics ",
                    duration: "2.5 hrs",
                    description: "In this Lecture we will discuss in depth about the Inverse Kinematics of 3D and 2D Robotic arm",
                },
                {
                    name: "lecture 6",
                    title: "Trajectory and Path",
                    duration: "2.5 hrs",
                    description: "In this lecture we will discuss about the Path planning for a Robotic arm to pass via all the given points in the trajectory along with the Joint space motion and Cartesian Motion.",
                },
                {
                    name: "lecture 7",
                    title: "DH Parameters",
                    duration: "2.5 hrs",
                    description: "In this lecture we will discuss the impotance of DH parameter and also the modifications of a Industrial Robotic arm and their Effects.",
                },
                {
                    name: "lecture 8",
                    title: "Smart Application",
                    duration: "2.5 hrs",
                    description: "Here we will demonstrate how a simple walking robot with different joints and links can be executed in matlab.",
                },

            ]
        }
    },





    // ***********************************************************************************************************************************

    //                                    7. Image Processing Theory and Practice using matlab

    // *********************************************************************************************************************************** 





    {
        certificationName: "Aviate's  Internship Certification program in Image Processing Theory and Practice using matlab",
        courseName: "Image Processing Theory and Practice using matlab",
        active: false,
        nextBatchStart:  "January",
        demoPosterUrl: "",
        courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FComing_Soon_02.svg?alt=media&token=d85b124a-dfc6-4f74-ac5a-586be1cfcce7",
        navigateUrl: "/internship/robotics/imageprocessing-internship-in-india",
        duration: "20 Hours",
        prerequisites: "Basic knowledge in coding.",
        youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
        courseOverview: {
            heading: "Course Overview - Image Processing Theory and Practice using matlab",
            description: "In recent years, various models of image processing systems have been developed.In this Matlab course for Image processing Theory and Practice, one can understand a comprehensive set of reference-standard algorithms and workflow apps for image processing, analysis, visualization, and algorithm development.",
            keyFeaturesHeading: "key features",
            courseKeyFeatures: ["20 hours of in-depth blended learning", "Two real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
            skillsCoveredHeading: "Concepts Covered",
            skillsCoveredList: ["Shadow Removal", "Image Formation", "Camera Calibration", "Image Histograms", "Morphology", "Image Feature Extraction"]
        },
        relatedCourses: ["Robotics Modelling and Control using MATLAB", "Robotic Arm Modeling and Simulation 			",],
        benefits: {
            title: "Why to learn Image Processing? ",
            benefitsList: ["For the young engineering student looking to enter robotics, there are key areas of study that one should focus on is Image Processing.",
                "The International Federation of Robotics (IFR) estimates that by 2020, there were around 2.8 million stand-alone industrial robots installed worldwide. Where Majority of them includes image processing.",
                "Image Processing is one of the key Technology of Automobile and Other Big Manufacturing Technology involving vision based automation.",
                "Lot of Countries have small Technology startups who need young Engineers with Proficient Knowledge on Image Processing.",
                "Companies Like Funac , Cobots , Kuka Need Young Professionals with core knowledge of Robotic Modelling"
            ]
        },
        trainingOptions: [
            {
                name: "Self Placed Learning",
                certificateTitles: ["AARE - Aviate Associate Image Processing Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "1",
                withMTA: "2",
                courseFee: "1",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "One year access to high-quality self-paced e-learning content curated by industry experts",
                    "24x7 learner assistance and support",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Assignments, course documentaion and resources",
                    "Learn at your choice of time",
                    "Exercises",
                    "Quiz sections",
                    "Discussion pages"
                ]
            },
            {
                name: "Mentor Led Live Sessions",
                certificateTitles: ["AARE - Aviate Associate Image Processing Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "5999",
                withMTA: "6999",
                courseFee: "5999",
                redirectUrl: "",
                mtaChecked: false,
                features: [
                    "90 days of flexible access live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "Course documentaion and resources",
                ]
            },
            {
                name: "Recorded Sessions",
                certificateTitles: ["AARE - Aviate Associate Image Processing Professional", "MTA - Microsoft Technology Associate"],
                withoutMTA: "3999",
                withMTA: "4999",
                courseFee: "3999",
                mtaChecked: false,
                features: [
                    "90 days of flexible access to live mentor led online classes",
                    "One year access to high-quality self-paced e-learning content and live class recordings",
                    "24x7 learner assistance and support",
                    "learner - mentor interaction sessions",
                    "Assignments & projects verification",
                    "best performer voucher",
                    "Two hands-on projects",
                    "Course completion certificate",
                    "course documentaion and resources",
                ]
            }
        ],
        faq: [
            {
                question: "Why should I learn Image Processing from Aviate? ",
                answer: " Aviate is one of the Fastest Growing Edutech Companies in India. Image Processing plays a vital role in Automation Technology, learning robotics adapt the students to the current production processes which are related to the use of the mechanical, electronic and the computer-based operation and control of the production. Aviate's Robotics online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Image Processing."
            },
            {
                question: "What are the benefits of learning Image Processing?",
                answer: "Robotics is one of the most advanced technologies that shapes a better future. Nowadays, organizations are realizing the enormous potential of employing Robotics process Enginner and Robotics Professional Engineer to leverage the large volume of applications available to them. Robotics helps in this effort by offering basics on robotics applications, operations and usecases, handling tasks instantly and concisely."
            },
            {
                question: "What is the eligibility to pursue Image Processing? ",
                answer: "BE/B.Tech (ECE,EEE,EIE,CSE,IT); MCA; MSc (CS, Electronics, Instrumentation, Physics with specialization in electronics); M.Tech (Electronics, CS)."
            },
            {
                question: " Who Provides Certification and How Long it is Valid ? ",
                answer: "Upon successful completion of the Image Processing training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
            },
            {
                question: "Does AVIATE Offer Placement ? ",
                answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
            },
            {
                question: "What kind of projects are included as part of the training?",
                answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
            }
        ],
        projectDetails: [
            {
                name: "Project 1",
                title: "Aviate's industry related project -1",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 2",
                title: "Aviate's industry related project -2",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your project."
            },
            {
                name: "Project 3",
                title: "Aviate's industry related project -3",
                duration: "2 hrs",
                description: "This project will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your project."
            }
        ],
        courseDetails: {
            courseDescription: {
                name: "Course Description",
                introduction: "If you want to expand your expertise in building Image Processing applications, then this Image Processing Theory and practice using Matlab course will put you on the basics of Image Processing platform. Image Processing is one of the hottest and fastest-growing technologies for improving real-time vision based identification operations and processes. This course will give you an overview of Image Processing concepts, the value-add it brings with relevant business use cases and tools understanding. One can understand a comprehensive set of reference-standard algorithms and workflow apps for image processing, analysis, visualization, and algorithm development."
            },
            instructor: {
                name: "Instructor",
                introduction: "This course is recorded and held live by technical team of aviate robotics educators. "
            }
        },
        courseCurriculum: {
            heading: "Course curriculum - Image Processing Theory and Practice using matlab",
            description: "Aviate's Robotics live course is well suited for professionals willing to gain expertise in the field of robotics, along with automation, Internet of things.",
            courseContents: [
                {
                    name: "lecture 1",
                    title: "Introduction",
                    duration: "1 hr",
                    description: "In this lecture we will discuss in depth about the Importance of Image Processing and its Applications.",
                },
                {
                    name: "lecture 2",
                    title: "Light and Color",
                    duration: "1 hr",
                    description: "In this Lecture we will discuss about the spectral Representation and Laws of Light Absorption , Reflectance and Luminance.",
                },
                {
                    name: "lecture 3",
                    title: "Color and Advanced Topics",
                    duration: "1 hr",
                    description: "In this Lecture we will discuss about the Measurement of Light , Reproduction of Colors , Chromocity Space and Color Names , Transforming between Different Primaries , Color Temperatur and Color Constancy , Dichromatic Reflectance",
                },
                {
                    name: "lecture 4",
                    title: "Shadow Removal",
                    duration: "1 hr",
                    description: "In this Lecture we will work on how to remove the shadow in a Image by application of Color Image",
                },
                {
                    name: "lecture 5",
                    title: "Image Formation",
                    duration: "1 hr",
                    description: "In this lecture we will work on Camera Prespective , Camera Matrix and Lens Distortion , Projecting Points.",
                },
                {
                    name: "lecture 6",
                    title: "Camera Calibration ",
                    duration: "1 hr",
                    description: "In this Lecture we will learn how to calibrate a camera using Homogeneous Transformation Approach , Decomposing a callibration Matrix  and Pose estimation",
                },
                {
                    name: "lecture 7",
                    title: "Different Image Views",
                    duration: "1 hr",
                    description: "In this Lecture we will learn about Fish Eye lens Camera and Catadioptric Cameras and Sperical Imaging . Mapping a Wide angle Images to Sphere and Vice Versa",
                },
                {
                    name: "lecture 8",
                    title: "Images and Image Processing ",
                    duration: "1 hr",
                    description: "In this Lecture we will start manipulating and extracting Data from an Image . Obtaining an Image from Various Sources",
                },
                {
                    name: "lecture 9",
                    title: "Image Histograms",
                    duration: "1 hr",
                    description: "In this Lecture we will learn about the Image Histograms and Algorithms ",
                },
                {
                    name: "lecture 10",
                    title: "Operations on Images",
                    duration: "1 hr",
                    description: "In this lecture we will perform various Image operations like Spatial Operations ,Mondiac and Didaic Operations on Images",
                },
                {
                    name: "lecture 11",
                    title: "Morphology",
                    duration: "1 hr",
                    description: "In this lecture we will work on Mathematical Morphology of Images . Noise removal , Boundary Detection , Distance Transform on Images",
                },
                {
                    name: "lecture 12",
                    title: "Transforming of Images ",
                    duration: "1 hr",
                    description: "In this Lecture we will work on Shape Changing of Images like Cropping , Image Resizing , Image Pyramids and Image Wraping.",
                },
                {
                    name: "lecture 13",
                    title: "Image Feature Extraction ",
                    duration: "1 hr",
                    description: "In this lecture we will discuss about the Region Features such as Representation , Descriptions and Classifications",
                },
                {
                    name: "lecture 14",
                    title: "Line and Point Features ",
                    duration: "1 hr",
                    description: "In this lecture we will discuss about the Line Feature Extraction from an Image. Classical Corner Detection and Scale Space Corner Detection is Discussed",
                },
                {
                    name: "lecture 15",
                    title: "Advanced Topics -01",
                    duration: "1 hr",
                    description: "In this Lecture we will discuss about Using Multiple Images , Geoametry of Multiple Images .",
                },
                {
                    name: "lecture 16",
                    title: "Advanced Topics -02",
                    duration: "1 hr",
                    description: "In this Lecture we will discuss about the use of Stereo Vision and Point Clouds in Depth",
                },
                {
                    name: "lecture 17",
                    title: "Project - 01",
                    duration: "2 hr",
                    description: "Hardware Project",
                },
                {
                    name: "lecture 18",
                    title: "Project - 02",
                    duration: "2 hr",
                    description: "Hardware Project",
                },
            ]
        }
    }
]


export default CoursesJson;