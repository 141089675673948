import React, { Component } from 'react';
import axios from 'axios';
import * as emailjs from 'emailjs-com'


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerform: {
                Course: this.props.courseName,
                Name: "",
                Mobile: "",
                Email: "",
                College: "",
                Branch: "",
                date: new Date().toLocaleDateString(),
            },
            registerformMessage: {
                CourseMessage: "",
                NameMessage: "",
                MobileMessage: "",
                EmailMessage: "",
                CollegeMessage: "",
                BranchMessage: ""
            },
            registerformStatus: {
                displayTitle: false,
                CourseStatus: false,
                NameStatus: false,
                MobileStatus: false,
                EmailStatus: false,
                CollegeStatus: false,
                BranchStatus: false,
                registerBtnActive: false
            },
            registeredStatus: false,
            successMessage: "",
            errorMessage: "",
        }
    }
   
    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const { registerform } = this.state;
        
        this.setState({
            registerform: { ...registerform, [name]: value }
        });
        this.validate(name, value);

    }

    validate(name, value) {
        const { registerformStatus } = this.state;
        // let formRegister = this.state.registerform;
        let formValid = this.state.registerformStatus;
        let formMsg = this.state.registerformMessage;

        switch (name) {
            case 'Name':
                if (value !== "") {
                    formValid.NameStatus = true;
                    formMsg.NameMessage = "";
                }
                else {
                    formValid.NameStatus = false;
                    formMsg.NameMessage = "Enter your Name";
                }
                break;
            case 'Mobile':
                if (value !== "" && (value >= 6000000000 && value <= 9999999999)) {
                    formValid.MobileStatus = true;
                    formMsg.MobileMessage = "";
                } else if (value === "") {
                    formValid.MobileStatus = false;
                    formMsg.MobileMessage = "Enter Mobile Number";
                } else {
                    formValid.MobileStatus = false;
                    formMsg.MobileMessage = "Invalid Mobile Number";
                }
                break;
            case 'Email':
                if (value !== "" && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value))) {
                    formValid.EmailStatus = true;
                    formMsg.EmailMessage = "";
                }
                else if (value === "") {
                    formValid.EmailStatus = false;
                    formMsg.EmailMessage = "Enter Valid Email Id";
                } else {
                    formValid.EmailStatus = false;
                    formMsg.EmailMessage = "Invalid Email Id";
                }
                break;
            default:
                break;
        }

        const regBtn = formValid.NameStatus && formValid.MobileStatus && formValid.EmailStatus  ;
        if (regBtn) {
            this.setState({
                registerformStatus: { ...registerformStatus, registerBtnActive: true },
            });
        } else {
            this.setState({
                registerformStatus: { ...registerformStatus, registerBtnActive: false },
            });
        }
    }
    register = () => {
        var date = new Date();
        var RegUrl = "https://aviaterobotics.firebaseio.com/demos/"+this.props.courseName +"/" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + "/" + this.state.registerform.Name + ".json";


        var email1 = "support@aviaterobotics.com";
        var email2 = "tech@avaiterobotics.com";

        let templateParams = {
            from_email: email2,
            to_email: email1,
            from_name: email1.split('@')[0],
            to_name: email2.split('@')[0],
            student_email: this.state.registerform.Email.split('@')[0],
            courseName: this.state.registerform.Course,
            college: this.state.registerform.College,
            branch: this.state.registerform.Branch,
            mobile: this.state.registerform.Mobile,
        }


        emailjs.send(
            'gmail',
            'template_RiFOD4pg',
            templateParams,
            'user_m6zIhA1aJ1gvO2NiWXgNw'
        )

        axios.post(RegUrl, this.state.registerform).then(success => {
            // To make a refreshed page look after the submit button is pressed
                const {registerform} = this.state;
                this.setState({ successMessage: "Successfully Registered", errorMessage: "" })
                this.setState({registerform : {...registerform, 
                    Name: "",
                    Mobile: "",
                    Email: "",
                    College: "",
                    Branch: ""
                }})
                this.setState({ registeredStatus: true })
        }).catch(error => {
            if (error.response) {
                this.setState({ errorMessage: error.response.data.message, successMessage: "" });
            } else {
                this.setState({ errorMessage: error.message, successMessage: "" });
            }
        })
    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.register();
        setTimeout(function() {
            window.location.reload();
        }, 5000);
    }

    render() {
        return (
            <div>
                <form className="form-group form-bordered" onSubmit={this.handleSubmit} >
                    <div className="form-group">
                        <label>Name*</label>
                        <input
                            type="text"
                            className="form-control"
                            name="Name"
                            value={this.state.registerform.Name}
                            placeholder="Your Name"
                            onChange={this.handleChange} />
                        <span className="text-danger">{this.state.registerformMessage.NameMessage}</span>
                    </div>
                    <div className="form-group">
                        <label>Mobile*</label>
                        <input
                            type="tel"
                            min="6666666666"
                            max="9999999999"
                            name="Mobile"
                            value={this.state.registerform.Mobile}
                            className="form-control"
                            placeholder="Your Mobile No"
                            onChange={this.handleChange} />
                        <span className="text-danger">{this.state.registerformMessage.MobileMessage}</span>
                    </div>
                    <div className="form-group">
                        <label>Email id*</label>
                        <input
                            type="text"
                            name="Email"
                            value={this.state.registerform.Email}
                            className="form-control"
                            placeholder="Your Email id"
                            onChange={this.handleChange} />
                        <span className="text-danger">{this.state.registerformMessage.EmailMessage}</span>
                    </div>
                    <div className="form-group">
                        <label>College</label>
                        <input
                            type="text"
                            name="College"
                            value={this.state.registerform.College}
                            className="form-control"
                            placeholder="Your College name"
                            onChange={this.handleChange} />
                        <span className="text-danger">{this.state.registerformMessage.CollegeMessage}</span>
                    </div>
                    <div className="form-group">
                        <label>Branch</label>
                        <input
                            type="text"
                            name="Branch"
                            value={this.state.registerform.Branch}
                            className="form-control"
                            placeholder="Your Branch name"
                            onChange={this.handleChange} />
                        <span className="text-danger">{this.state.registerformMessage.BranchMessage}</span>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!this.state.registerformStatus.registerBtnActive}
                    >Register</button>
                    <div className="text-success"
                        name="successMessage"
                        value={this.state.successMessage}
                    >{this.state.successMessage}
                    </div>
                    <div className="text-danger"
                        name="errorMessage"
                        value={this.state.errorMessage}
                    >{this.state.errorMessage}
                    </div>
                </form>
            </div>
        )
    }
}

export default Register;