// 1. SQL For Absolute Beginners
// 2. Tableau Made Easy


const ToolsCoursesJson =[





    // ***********************************************************************************************************************************
    
                                                   // 1. SQL For Absolute Beginners
    
    // *********************************************************************************************************************************** 
    
    
    
    
    
    
        {
            certificationName : "Aviate's certification program in Mastering Fundamentals of SQL Database",
            courseName: "Mastering Fundamentals of SQL Database",
            active : true,
            nextBatchStart:  "October",
            demoPosterUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FSQL_Course_%20Video.mp4?alt=media&token=c3ce1f12-02f8-4e55-b162-d470876fda3d",
            courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2Fsql.svg?alt=media&token=a0b325e1-b9e9-42c7-9466-e1c6d2d07193",
            navigateUrl: "/courses/programming-application-tools-domain/sql",
            duration: "18 hours",
            prerequisites: "No Prerequisites Needed.",
            youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
            courseOverview:{
                heading : "Course Overview - Mastering Fundamentals of SQL Database",
                description : "SQL certification course gives you all of the information you need to successfully start working with SQL databases and make use of the database in your applications. Learn how to correctly structure your database, author efficient SQL statements, and clauses, and manage your SQL database for scalable growth.",
                keyFeaturesHeading : "Key Features",
                courseKeyFeatures : ["18 hours of in-depth blended learning","In-depth coverage of SQL fundamentals","Covers all of the important query tools and SQL commands","Industry-recognized course completion certificate","Lifetime access to self-paced learning"],
                skillsCoveredHeading : "Concepts Covered",
                skillsCoveredList : ["Database and relationships","DML Commands","DDL Commands","Views","Stored Proceedures","Indexes","Db Constraints","Normalization" ]
            },
            relatedCourses : ["Tableau Training and Certification Course"],
            benefits: {
                title :"Why should You Learn SQL? ",
                benefitsList : ["SQL is the must required skills when it comes to Database operations & Business Intelligence tool in the market. Everyone who has to deal with database should have to learn SQL",
                                "After the completion of your course, with this skill set in Hand you will get services to help you in resume-making, connecting to companies for interview, mock interview sessions by SMEs to prepare you for the interview.",
                                "Learning SQL enhances the knowledge on handling large data in production and operations required to maintain it.",
                                "SQL is the must required skill for any technical job interview. You will get a clear idea when you do hands-on sessions with us."
                ]
            },
            trainingOptions : [
                {
                    name : "Self Placed Learning",
                    certificateTitles : ["AADE - Aviate Associate Database Engineer","MTA - Microsoft Technology Associate"],
                    withoutMTA : "2500",
                    withMTA : "3700",
                    courseFee : "2500",
                    redirectUrl :"",
                    mtaChecked : false,
                    features : [
                        "Lifetime access to high-quality self-paced e-learning content curated by industry experts",
                        "24x7 learner assistance and support",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "Assignments, course documentaion and resources",
                        "Learn at your choice of time",
                        "Exercises",
                        "Quiz sections",
                        "Discussion pages"                    
                    ]
                },
                {
                    name : "Mentor Led Live Sessions",
                    certificateTitles : ["AARE - Aviate Associate Database Engineer","MTA - Microsoft Technology Associate"],
                    withoutMTA : "2000",
                    withMTA : "3200",
                    courseFee : "2000",
                    redirectUrl :"",
                    mtaChecked : false,
                    features : [
                        "90 days of flexible access live mentor led online classes",
                        "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                        "24x7 learner assistance and support",
                        "learner - mentor interaction sessions",
                        "Assignments & projects verification",
                        "best performer voucher",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "Course documentaion and resources",
                    ]
                },
                {
                    name : "Recorded Sessions",
                    certificateTitles : ["AARE - Aviate Associate Database Engineer","MTA - Microsoft Technology Associate"],
                    withoutMTA : "1500",
                    withMTA : "2700",
                    courseFee : "1500",
                    mtaChecked : false,
                    features : [
                        "90 days of flexible access to live mentor led online classes",
                        "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                        "24x7 learner assistance and support",
                        "learner - mentor interaction sessions",
                        "Assignments & projects verification",
                        "best performer voucher",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "course documentaion and resources",
                    ]
                }
            ],
            faq : [
                {
                    question: "Why should I learn SQL database from Aviate? ",
                    answer: " Aviate is one of the Fastest Growing Edutech Companies in India. In the field of data manipulation operations, clearly, learning SQL is a must required skill. Aviate's SQL online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn SQL Database."
                },
               {
                    question: "What are the benefits of learning SQL database?",
                    answer: "SQL Database is the must required skill we it comes to using database. Nowadays, organizations are realizing the enormous potential of employing database administrators and database engineer to leverage the large volume of data available to them. SQL helps in this effort by offering basics on database operations and usecases, handling data instantly and concisely."
                },
                {
                    question: "What is the eligibility to pursue SQL Database? ",
                    answer: "This SQL database course is designed for beginners as well and gives you a foundational understanding of data manipulation and definition concepts."
                },
                {
                    question: " Who Provides Certification and How Long it is Valid ? ",
                    answer: "Upon successful completion of the SQL Database training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
                },
               {
                    question: "Does AVIATE Offer Placement ? ",
                    answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
                },
               {
                    question: "What kind of projects are included as part of the training?",
                    answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
                }
            ],
            projectDetails: [
                {
                    name: "Exercise 1",
                    title: "Aviate's  Real time databse related Exercise -1",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your exercise."
                },
                {
                    name: "Exercise 2",
                    title: "Aviate's Real time databse related Exercise -2",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your exercise."
                },
                {
                    name: "Exercise 3",
                    title: "Aviate's Real time databse related Exercise -3",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your exercise."
                }
            ],
            courseDetails: {
                courseDescription: {
                    name: "Course Description",
                    introduction: "If you want to expand your expertise in using SQL database, then this Mastering Fundamentals of SQL course will put you on the basics of databsse handling platform. SQL is the structured database for storing and handling real-time business operations and processes data. This course will give you an overview of SQL database concepts, the value-add it brings with relevant business use cases and tools understanding."
                },
                instructor: {
                    name: "Instructor",
                    instructorName : "Ram.G ( 5+Years in SQL and .NET)",
                    introduction: "Ram.G is an experienced Proffesional in .net and SQL for more than 5 years. Along Side his professional work he would like to contribute his knowledge by training Young Engineers in SQL. He is currently working as an Operations Engineering analyst at UHG. He has great command on enhancing and maintaining Devops Application as well as Build and deploy Workflows using PMG tool. Previously he used work as .Net developer and SQL developer at DXC(formerly known as CSC)."
                }
            },
            courseCurriculum: {
                heading : "Course Curriculum - Mastering Fundamentals of SQL Database",
                description : "",
                courseContents: [
                    {
                        name: "Lecture 1",
                        title : "Understanding database ",
                        duration: "1 hr",
                        description: "A walkthrough of the core database concepts and in depth understanding of how data being stored",
                    },
                   {
                        name: "Lecture 2",
                        title : "Relational Database concepts",
                        duration: "1 hr",
                        description: "What is RDMS and why it is needed. What are the different relations we can define", 
                    },
                     {
                        name: "Lecture 3",
                        title : "Database objects",
                        duration: "1 hr",
                        description: "Understanding the different types of database objects used while performing DB operations", 
                    },
                   {
                        name: "Lecture 4",
                        title : "DML commands",
                        duration: "2 hrs",
                        description: "What does DML stands for? Keywords and commands. Illustrating with different examples",
                    },
                   {
                        name: "Lecture 5",
                        title : "DDL commands",
                        duration: "1 hr",
                        description: "What are DDL commands? Differece between DML and DDL . Understanding how T-SQL commands used in DBMS", 
                    },
                     {
                        name: "Lecture 6",
                        title : "Hands-on session.Assignments discussion",
                        duration: "2 hrs",
                        description: "Hands on session on performing different operations.Discussing the assignment questions", 
                    },
                   {
                        name: "Lecture 7",
                        title : "Views",
                        duration: "2 hrs",
                        description: "What is a view? How to create a view and access it",
                    },
                   {
                        name: "Lecture 8",
                        title : "Stored Procedures and Functions",
                        duration: "2 hrs",
                        description: "What is a SP? What are function? Understanding the difference and creating them. Assignment problems", 
                    },
                     {
                        name: "Lecture 9",
                        title : "UNION and Normalization",
                        duration: "1 hr",
                        description: "What is UNION? What is Normalization? Understanding the concepts", 
                    },
                   {
                        name: "Lecture 10",
                        title : "DB Constraints and their Role",
                        duration: "1 hr",
                        description: "Understanding the DB Key constraints and the role they play",
                    },
                   {
                        name: "Lecture 11",
                        title : "Indexes",
                        duration: "1 hr",
                        description: "What are indexes in DB and how are they used", 
                    },
                     {
                        name: "Lecture 12",
                        title : "Securtity, Restore and Backup",
                        duration: "1 hr",
                        description: "How to secure, restore and back up the databases", 
                    },
                    {
                        name: "Lecture 13",
                        title : "Assignments Discussion",
                        duration: "2 hrs",
                        description: "We all together will solve assignment problems and do a hands on session on all fundemental concepts", 
                    }
                ]
            }
        },
    
    
    
    
    
    // ***********************************************************************************************************************************
    
                                                   // 2. Tableau
    
    // *********************************************************************************************************************************** 
    
    
    
    
    
    {
            certificationName: "Aviate's certification program in Tableau Training and Certification Course",
            courseName: "Tableau Training and Certification Course",
            active : true,
            nextBatchStart:  "October",
            demoPosterUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FTableau_Course_%20Video.mp4?alt=media&token=061efed1-c325-4bb4-a9cf-c84cea17b2af",
            courseDisplayImageUrl: "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/courses%20display%20images%2FTablue%20Course.svg?alt=media&token=f08cbf50-7656-44d0-84f5-864720f4cb1c",
            navigateUrl: "/courses/programming-application-tools-domain/tableau",
            duration: "20 Hours",
            prerequisites: "No Prerequisites Required",
            youtubeUrl: "https://www.youtube.com/embed/Lpfo2KSiORg",
            courseOverview:{
                heading : "Course Overview - Tableau Training and Certification Course",
                description : "In 2020 the world will generate 50 times the amount of data as in 2011. And 75 times the number of information sources (IDC, 2011). Being able to use this data provides huge opportunities and to turn these opportunities into reality, people need to use data to solve problems.This Course,  is intended for newcomers to data visualization with no prior experience using Tableau. We leverage our resources to demonstrate best practices for data visualization and data storytelling. You will view examples from real world business cases and journalistic examples .By the end of this Course, you will be able to generate powerful reports and dashboards that will help people make decisions and take action based on their business data. You will use Tableau to create high-impact visualizations of common data analyses to help you see and understand your data.",
                keyFeaturesHeading : "Key Features ",
                courseKeyFeatures : ["20 hours of in-depth blended learning", "Two real-time Industry projects", "Dedicated project live mentoring sessions", "Lifetime access to self placed learning", "24*7 learning support"],
                skillsCoveredHeading : "Concepts Covered",
                skillsCoveredList : ["Tableau statistics" , "Building interactive dashboards" , "Arithmetic logical LOD calculations" , "Heat map waterfall Pareto" , "Use the Tableau interface/paradigm to effectively create powerful visualizations." ,"Create basic calculations including basic arithmetic calculations, custom aggregations and ratios, date math, and quick table calculations", " Understand Tableau terminology in depth"]
            },
            relatedCourses : ["Mastering Fundamentals of SQL Database"],
            benefits: {
                title :"Why should You Learn Tableau? ",
                benefitsList : ["Tableau is the most popular Data Visualization & Business Intelligence tool in the market. From data scientists to business analysts, everyone uses Tableau",
                                "After the completion of your course, with this skill set in Hand you will get services to help you in resume-making, connecting to companies for interview, mock interview sessions by SMEs to prepare you for the interview.",
                                "Learning tableau enhances the growth of any organization by scrutinizing its data in an appropriate way and analyzing the reasons for the drawback of the organization/business.",
                                "Data analytics tools are the main foundation for any digital business because they allow businesses to really understand what their customers are doing on their website or an application. Therefore, accurate tracking of directions within a data analytics tool is essential for any online business.",
                                "Tableau leverages visual analytics that enables users to interact with data. This practice helps users to visually interact with data to make crucial decisions and get faster insights."
                ]
            },
            trainingOptions : [
                {
                    name : "Self Placed Learning",
                    certificateTitles : ["ACTP - Aviate Certified Tableau Professional"],
                    withoutMTA : "6000",
                    withMTA : "6000",
                    courseFee : "6000",
                    redirectUrl :"",
                    mtaChecked : false,
                    features : [
                        "Lifetime access to high-quality self-paced e-learning content curated by industry experts",
                        "24x7 learner assistance and support",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "Assignments, course documentaion and resources",
                        "Learn at your choice of time",
                        "Exercises",
                        "Quiz sections",
                        "Discussion pages"                    
                    ]
                },
                {
                    name : "Mentor Led Live Sessions",
                    certificateTitles : ["ACTP - Aviate Certified Tableau Professional"],
                    withoutMTA : "5000",
                    withMTA : "5000",
                    courseFee : "5000",
                    redirectUrl :"",
                    mtaChecked : false,
                    features : [
                        "90 days of flexible access live mentor led online classes",
                        "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                        "24x7 learner assistance and support",
                        "learner - mentor interaction sessions",
                        "Assignments & projects verification",
                        "best performer voucher",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "Course documentaion and resources",
                    ]
                },
                {
                    name : "Recorded Sessions",
                    certificateTitles : ["ACTP - Aviate Certified Tableau Professional"],
                    withoutMTA : "4000",
                    withMTA : "4000",
                    courseFee : "4000",
                    mtaChecked : false,
                    features : [
                        "90 days of flexible access to live mentor led online classes",
                        "Lifetime access to high-quality self-paced e-learning content and live class recordings",
                        "24x7 learner assistance and support",
                        "learner - mentor interaction sessions",
                        "Assignments & projects verification",
                        "best performer voucher",
                        "Two hands-on projects",
                        "Course completion certificate",
                        "course documentaion and resources",
                    ]
                }
            ],
            faq : [
                {
                    question: "Why should I learn Tableau from Aviate? ",
                    answer: " Aviate is one of the Fastest Growing Edutech along with Tableau Course. As you know, Tableau has been consistently placed among the Leaders in the Gartner Magic Quadrant for Business Intelligence domain. In the field of data visualization, clearly, Tableau is unbeatable. Aviate's Tableau online training is the most comprehensive course packed with real-time and highly relevant projects and assignments, and the course material is specially designed by industry experts to help you learn Tableau."
                },
               {
                    question: "What are the benefits of learning Tableau?",
                    answer: "Tableau is one of the most advanced business intelligence and analytical tools around. Nowadays, organizations are realizing the enormous potential of employing data analysts and data scientists to leverage the large volume of data available to them. Tableau helps in this effort by offering simple visualization data representation, creating meaningful insights instantly and concisely."
                },
                {
                    question: "What is the eligibility to pursue Tableau? ",
                    answer: "A bachelor's degree in computer science or business intelligence field is recommended. However, this Tableau course is designed for beginners as well and gives you a foundational understanding of data visualization concepts."
                },
                {
                    question: " Who Provides Certification and How Long it is Valid ? ",
                    answer: "Upon successful completion of the Tableau training course, you will be awarded an industry-recognized course completion certificate from Aviate which has a lifelong validity."
                },
               {
                    question: "Does AVIATE Offer Placement ? ",
                    answer: "No, we do not offer any Placement Assistance; people must not assume that by enrolling to the course will entitle them for a job."
                },
               {
                    question: "What kind of projects are included as part of the training?",
                    answer: "Aviate is offering you the most updated, relevant, and high-value real-world projects as part of the training program. This way, you can implement the learning that you have acquired in real-world industry setup. All training comes with multiple projects that thoroughly test your skills, learning, and practical knowledge, making you completely industry-ready."
                }
            ],
            projectDetails: [
                {
                    name: "Exercise 1",
                    title: "Aviate's  Real time data visualization related Exercise -1",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in first one-third of the sessions. You will be provided complete assistance while developing your exercise."
                },
                {
                    name: "Exercise 2",
                    title: "Aviate's Real time data visualization related Exercise -2",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in second one-third of the sessions. You will be provided complete assistance while developing your exercise."
                },
                {
                    name: "Exercise 3",
                    title: "Aviate's Real time data visualization related Exercise -3",
                    duration : "2 hrs",
                    description: "This exercise will be based on the concepts covered in last one-third of the sessions. You will be provided complete assistance while developing your exercise."
                }
            ],
            courseDetails: {
                courseDescription: {
                    name: "Course Description",
                    introduction: "In 2020 the world will generate 50 times the amount of data as in 2011. And 75 times the number of information sources (IDC, 2011). Being able to use this data provides huge opportunities and to turn these opportunities into reality, people need to use data to solve problems.This Course,  is intended for newcomers to data visualization with no prior experience using Tableau. We leverage our resources to demonstrate best practices for data visualization and data storytelling. You will view examples from real world business cases and journalistic examples .By the end of this Course, you will be able to generate powerful reports and dashboards that will help people make decisions and take action based on their business data. You will use Tableau to create high-impact visualizations of common data analyses to help you see and understand your data."
                },
                instructor: {
                    name: "Instructor",
                    instructorName : "Sriram  ( 5+Years in Business Intelligence and Data Analytics)",
                    introduction: "Sriram is a BI developer with a record of 5+Years of experience in Business intelligence and Data Analytics related projects with proven ability to identify business needs and develop valuable solutions to drive accuracy and process efficiency. As his interest and passion towards training young engineers. He successfully trained more than 500 students in Business Intelegence Solutions and Process Documentation to produce required report and analytical deliverables using Power BI and Tableau"
                }
            },
            courseCurriculum: {
                heading : "Course Curriculum - Tableau Certification Course",
                description : "",
                courseContents: [
                    {
                        name: "Lecture 1",
                        title : "Getting Started with Tableau",
                        duration: "2 Hour",
                        description: "Introduction to Tableau and an Overview of the Different Versions Installing Tableau Desktop Tableau Help and Online Resources",
                    },
                   {
                        name: "Lecture 2",
                        title : "Working with Tableau",
                        duration: "3 Hours",
                        description: "Understanding Tableau User Interface Exploring Tableau File Types Understanding Green and Blue Pills Working with Available Data Sources Working with Extracts Instead of Live Connections", 
                    },
                     {
                        name: "Lecture 3",
                        title : "Data and Connections",
                        duration: "3 Hours",
                        description: "Working with Excel Data Interpreter Understanding Metadata and Sharing Data Source Connections Filtering Data From Your Data Source Learning How to Split Fields Pivoting Data", 
                    },
                   {
                        name: "Lecture 4",
                        title : "Creating Charts",
                        duration: "3 Hour",
                        description: "The Show Me Feature ,Crosstabs and Heat Maps ,Using Bar, Stacked Bar, and Side-by-side Bars ,Pie Charts ,Line and Area Charts ,Working with Packed Bubblee ,Using Treemaps ,Creating a Basic Scatter Plot ,Creating a Basic Map",
                    },
                   {
                        name: "Lecture 5",
                        title : "Adding calculation to your workbook",
                        duration: "3 Hour",
                        description: "Introduction to Calculations (this will explain the various types of calculations and the mechanics of each) Understanding Basic Calculations ,Understanding String Calculations ,Learning About Boolean, If-Then Calculations, and Case Statements ,Understanding the Basics of Date Calculations Understanding ,Aggregation and Disaggregation Using Calculations to Add Insight to Your Visualizations", 
                    },
                     {
                        name: "Lecture 6",
                        title : "Dashboard and Stories",
                        duration: "3 Hour",
                        description: "Introduction to Dashboards,Understanding Dashboard Actions ,Understanding Dashboard Formatting Basics,Understanding Workbook Level ,Formatting (i.e. font styling, titles, lines) ,Assembling Your Dashboards Into A Story", 
                    },
                   {
                        name: "Lecture 7",
                        title : "Visualizations For An Audience",
                        duration: "3 Hour",
                        description: "Focusing on the Narrative ,Using color with a Purpose ,Understanding the Importance of Using Tooltips for Your Audience,Using a Parameter To Focus the Audienceís Attention ,Removing Clutter in Your Dashboard ,How to Export Your Dashboard to Another File Type",
                    }
                ]    
            }
        }
    ]    

export default ToolsCoursesJson;