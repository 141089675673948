/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { FaLinkedin,FaFacebook, FaTwitter } from "react-icons/fa";

// core components
import { withFirebase } from '../../firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext } from '../../session';


const EditProfilePage = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => <EditProfileForm authUser={authUser.uid}/>}
    </AuthUserContext.Consumer>
  </div>
);

const INITIAL_STATE = {
    userInfo: {
      personalDetails : {
      userName: "",
      firstName : "",
      lastName : "",
      mobile: "",
      email: "",
      city:"",
      state : "",
      country : "",
      collegeCompany : "",
      designation : ""
    },
    socialMedia : {
      linkedin :"",
      facebook : "",
      twitter : "",
      website : ""
    },
    workExperience :{
      designation :"",
      company : "",
      jobRole : "",
      industry : "",
      experience : ""
    },
    invoices: [
      {
      invoice_id :"test",
      invoice_link : ""
      }
    ],
    courseSubscriptions: [
      "test"
    ],
    certificates: [
      "test"
    ],
    payments: [
        {
            payment_id : "test",
            order_id : "test",
            signature : "test",
            paidFor : "test",
            paidDate : Date().toLocaleString(),
            trainingMode : "test",
            amountPaid : "test"
        }
      ],
    badges: [
      "test"
    ]
  },
  userInfoErrorMessages: {
    userNameMessage: '',
    mobileMessage: '',
    emailMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
    acceptTermsMessage: false
  },
  userInfoInputStatus: {
    userNameStatus: '',
    mobileStatus: '',
    emailStatus: '',
    passwordStatus: '',
    confirmPasswordStatus: '',
    acceptTermsStatus: false
  },
  displayWorkExperience : false,
  userDetailsUpdated : false
};

class EditProfileFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }
  componentDidMount() {
    this.props.firebase.user(this.props.authUser).on('value', snapshot => {
      const user = snapshot.val();    
      if(user){
      const {userInfo} = this.state;
      this.setState({
        userInfo: {...userInfo,
        personalDetails : user.personalDetails,
        socialMedia : user.socialMedia,
        workExperience : user.workExperience,
        badges : user.badges,
        certificates : user.certificates,
        courseSubscriptions : user.courseSubscriptions,
        invoices : user.invoices,
        payments : user.payments
      }
      });
    }else{
      this.setState({...INITIAL_STATE})
    }
  })
}
componentWillUnmount(){
  this.props.firebase.user(this.props.authUser).off();
}

  handleChangePersonalDetails = (event) => {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        personalDetails: {
          ...prevState.userInfo.personalDetails,
          [name]: value
        }
      }
    })
    );
    this.validate(name, value);
  }

  handleChangeSocialMedia = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        socialMedia: {
          ...prevState.userInfo.socialMedia,
          [name]: value
        }
      }
    })
    );
    this.validate(name, value);
  }

  handleChangeWorkExperience = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        workExperience: {
          ...prevState.userInfo.workExperience,
          [name]: value
        }
      }
    })
    );
    this.validate(name, value);
  }

  validate = (name, value) => {
    let userInfoInputStatus = this.state.userInfoInputStatus;
    let userInfoErrorMessages = this.state.userInfoErrorMessages;

    switch (name) {
      case 'userName':
        if (value === "") {
          userInfoInputStatus.userNameStatus = false;
          userInfoErrorMessages.userNameMessage = "Enter User Name";
        } else {
          userInfoInputStatus.userNameStatus = true;
          userInfoErrorMessages.userNameMessage = "";
        }
        break;
      case 'email':
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
          userInfoInputStatus.emailStatus = true;
          userInfoErrorMessages.emailMessage = "";
        } else {
          userInfoInputStatus.emailStatus = false
          userInfoErrorMessages.emailMessage = "Invalid Email Id!";
        }
        break;
      case 'mobile':
        if (value === "") {
          userInfoInputStatus.mobileStatus = false;
          userInfoErrorMessages.mobileMessage = "Enter Mobile";
        } else {
          userInfoInputStatus.mobileStatus = true;
          userInfoErrorMessages.mobileMessage = "";
        }
        break;
      case 'password':
        if (value === "") {
          userInfoInputStatus.passwordStatus = false;
          userInfoErrorMessages.passwordMessage = "Enter Password";
        } else {
          userInfoInputStatus.passwordStatus = true;
          userInfoErrorMessages.passwordMessage = "";
        }
        break;
      case 'confirmPassword':
        if (value === "") {
          userInfoInputStatus.confirmPasswordStatus = false;
          userInfoErrorMessages.confirmPasswordMessage = "Enter Password";
        } else if (value !== this.state.userInfo.password) {
          userInfoInputStatus.confirmPasswordStatus = false;
          userInfoErrorMessages.confirmPasswordMessage = "Password did not match";
        }
        else {
          userInfoInputStatus.confirmPasswordStatus = true;
          userInfoErrorMessages.confirmPasswordMessage = "";
        }
        break;
      case 'acceptTerms':
        if (value === false) {
          userInfoInputStatus.acceptTermsStatus = false;
          userInfoErrorMessages.acceptTermsMessage = "Please Accept terms";
        } else {
          userInfoInputStatus.acceptTermsStatus = true;
          userInfoErrorMessages.acceptTermsMessage = "";
        }
        break;
      default:
        break;
    }
  }

  toggleModal = () => {
    this.setState({userDetailsUpdated : false });  
  }


 updateProfile = (event)=>{

    event.preventDefault();
    const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, badges, payments } = this.state.userInfo;
   this.props.firebase
    .user(this.props.authUser)
    .set({
      personalDetails : personalDetails,
      socialMedia,
      workExperience,
      invoices,
      courseSubscriptions,
      certificates,
      badges,
      payments
  })
  this.setState({userDetailsUpdated : true});
 }



  render() {
    return (
      <>
          <section >
            <Container fluid={true} className="pb-250 pt-5">
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? <Form role="form" onSubmit={this.updateProfile}>
                    <Row >
                      <h4 className="text-center">Personal Details</h4>
                    </Row>
                      <Row className="mt-3">
                      <Col lg="1" className="order-md-1">
                      </Col>
                      <Col lg="5" className="order-md-2">
                        <FormGroup >
                          <label>First Name</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="First Name"
                              type="text"
                              onChange={this.handleChangePersonalDetails}
                              name="firstName"
                              value={this.state.userInfo.personalDetails.firstName} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup >
                          <label>Last Name</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Last Name"
                              type="text"
                              onChange={this.handleChangePersonalDetails}
                              name="lastName"
                              value={this.state.userInfo.personalDetails.lastName} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>User Name</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="User Name"
                              type="text"
                              onChange={this.handleChangePersonalDetails}
                              name="userName"
                              value={this.state.userInfo.personalDetails.userName} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                        <label>Email</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              onChange={this.handleChangePersonalDetails}
                              name="email"
                              value={this.state.userInfo.personalDetails.email} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <label>Mobile</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-mobile-button" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Mobile"
                              type="mobile"
                              onChange={this.handleChangePersonalDetails}
                              name="mobile"
                              value={this.state.userInfo.personalDetails.mobile} />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col lg="1" className="order-md-4"></Col>
                      <Col lg="5" className="order-md-3">
                        <FormGroup>
                          <label>Location</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-square-pin" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="City"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangePersonalDetails}
                              name="city"
                              value={this.state.userInfo.personalDetails.city}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>State</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-pin-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="State"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangePersonalDetails}
                              name="state"
                              value={this.state.userInfo.personalDetails.state}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>Country</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-pin-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Country"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangePersonalDetails}
                              name="country"
                              value={this.state.userInfo.personalDetails.country}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>Designation</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Designation"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangePersonalDetails}
                              name="designation"
                              value={this.state.userInfo.personalDetails.designation}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>College/Company</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-building" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="College/Company"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangePersonalDetails}
                              name="collegeCompany"
                              value={this.state.userInfo.personalDetails.collegeCompany}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <h4 className="text-center">Web presence</h4>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="1" className="order-md-1">

                      </Col>
                      <Col lg="6" className="order-md-2">
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaLinkedin />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your Linkedin Profile Url"
                              type="mobile"
                              onChange={this.handleChangeSocialMedia}
                              name="linkedin"
                              value={this.state.userInfo.socialMedia.linkedin} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaFacebook />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your Facebook Profile Url"
                              type="mobile"
                              onChange={this.handleChangeSocialMedia}
                              name="facebook"
                              value={this.state.userInfo.socialMedia.facebook} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaTwitter />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your Twitter Profile Url"
                              type="mobile"
                              onChange={this.handleChangeSocialMedia}
                              name="twitter"
                              value={this.state.userInfo.socialMedia.twitter} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-world-2" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your Website Url"
                              type="mobile"
                              onChange={this.handleChangeSocialMedia}
                              name="website"
                              value={this.state.userInfo.socialMedia.website} />
                          </InputGroup>
                        </FormGroup>
                        </Col>
                        <Col lg="2" className="order-md-3">

                      </Col>
                    </Row>  
                    <Row >
                      <h4 className="text-center">Work Experience</h4>
                    </Row>
                    <Row>
                      <p className="ml-5  text-primary" onClick = {e => this.setState({displayWorkExperience : true})}>+ add work experience</p>
                    </Row>
                      {this.state.displayWorkExperience?<Row className="mt-3">
                      <Col lg="1" className="order-md-1">
                      </Col>
                      <Col lg="5" className="order-md-2">
                      <FormGroup>
                          <label>Designation</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Designation"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangeWorkExperience}
                              name="designation"
                              value={this.state.userInfo.workExperience.designation}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>Company</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-building" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Company"
                              type="text"
                              autoComplete="off"
                              onChange={this.handleChangeWorkExperience}
                              name="company"
                              value={this.state.userInfo.workExperience.company}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup >
                          <label>Job Role</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Job Role"
                              type="text"
                              onChange={this.handleChangeWorkExperience}
                              name="jobRole"
                              value={this.state.userInfo.workExperience.jobRole} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup >
                          <label>Industry</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Industry"
                              type="text"
                              onChange={this.handleChangeWorkExperience}
                              name="industry"
                              value={this.state.userInfo.workExperience.industry} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>Years of Experience</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Years of Experience"
                              type="text"
                              onChange={this.handleChangeWorkExperience}
                              name="experience"
                              value={this.state.userInfo.workExperience.experience} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>:""}
                    <div className="text-center">
                          <Button
                            className="mt-4 mb-5"
                            color="primary"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                    </div>
                  </Form> : ""}
              </AuthUserContext.Consumer>
              <Modal isOpen={this.state.userDetailsUpdated} centered modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }} toggle={this.toggleModal}>
                  <ModalBody>
                      <div className="text-center">                    
                          <img alt="Profile Updated" src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Fverified.svg?alt=media&token=03769393-3527-487b-a514-9685a8b9763f" height="30%" width="30%"/>
                      </div>
                      <div className="text-center mt-5"><h6>Your profile updated successfully!</h6></div>
                  </ModalBody>
                  <ModalFooter>
                      <Button color="secondary" onClick={this.toggleModal}>close</Button>
                  </ModalFooter>
              </Modal>

            </Container>
          </section>
      </>
    );
  }
}

const EditProfileForm = compose(
  withRouter,
  withFirebase,
)(EditProfileFormBase);

export default EditProfilePage;
export { EditProfileForm };