import React, {  Component } from 'react';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext } from '../session';
import axios from "axios";
import PaymentConfirmation from './paymentConfirmation';

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const __DEV__ = document.domain === 'localhost'

const RazorPayPage = (props) => (
	<div>
		<AuthUserContext.Consumer>
			{authUser => authUser ? <RazorPayForm course={props.course} index={props.index} authUser={authUser.uid} />
				: <RazorPayForm course={props.course} index={props.index} />
			}
		</AuthUserContext.Consumer>
	</div>
);

const INITIAL_STATE = {
	index: "",
	paymentConfirmed : false,
	userInfo: {
		personalDetails: {
			userName: "",
			firstName: "",
			lastName: "",
			mobile: "",
			email: "",
			city: "",
			state: "",
			country: "",
			collegeCompany: "",
			designation: ""
		},
		socialMedia: {
			linkedin: "",
			facebook: "",
			twitter: "",
			website: ""
		},
		workExperience: {
			designation: "",
			company: "",
			jobRole: "",
			industry: "",
			experience: ""
		},
		invoices: [],
		courseSubscriptions: [],
		certificates: [],
		badges: [],
		payments: [],
	},
	course: {
		certificationName: "",
		courseName: "",
		courseDisplayImageUrl: "",
		navigateUrl: "",
		duration: "",
		prerequisites: "",
		youtubeUrl: "",
		courseDetails: {
			courseDescription: {
				name: "",
				introduction: ""
			},
			instructor: {
				name: "",
				introduction: ""
			},
			handouts: {
				name: "",
				handouts: []
			},
			assignments: {
				name: "",
				assignements: []
			}
		},
		relatedCourses: [],
		certificates: [],
		faq: [],
		projectDetails: [],
		trainingOptions: [],
		courseOverview: {
			heading: "",
			description: "",
			keyFeaturesHeading: "",
			courseKeyFeatures: [],
			skillsCoveredHeading: "",
			skillsCoveredList: []
		},
		benefits: {
			description: "",
			source: "",
			jobRoles: []

		},
		whyAviate: [],
		testimonials: [],
		courseCurriculum: {
			heading: "",
			description: "",
			prerequisites: "",
			courseContents: []
		}
	}
}
class RazorPayFormBase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE
		}
	}

	getData() {
		
			this.setState({
				course: this.props.course,
				index: this.props.index
			})
	}

	componentDidMount() {
		this.getData()
		this.props.firebase.user(this.props.authUser).on('value', snapshot => {
			const user = snapshot.val();
			if (user) {
				const { userInfo } = this.state;
				this.setState({
					userInfo: {
						...userInfo,
						personalDetails: user.personalDetails,
						socialMedia: user.socialMedia,
						workExperience: user.workExperience,
						badges: user.badges,
						certificates: user.certificates,
						courseSubscriptions: user.courseSubscriptions,
						invoices: user.invoices,
						payments: user.payments,
					}
				});
			} else {
				this.setState({ ...INITIAL_STATE })
			}
		})
	}

	componentWillUnmount() {
		this.props.firebase.user(this.props.authUser).off();
	}


	verifySignature= (response,id)=>{
		axios.post('https://us-central1-aviaterobotics.cloudfunctions.net/razorpayApi/confirmPayment'
		,{...response,id}
		)
		.then((res)=>{
			console.log("PAYMENT RESPONSE", res)
			this.setState({paymentConfirmed : true})
			console.log(this.state.paymentConfirmed)
		})
		.catch((err)=>{console.log('error')})
	  }

	displayRazorpay = async () => {
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ payment_capture: 1, amount: this.state.course.trainingOptions[this.state.index].courseFee * 100, currency: 'INR' })
		};

		const data = await fetch('https://us-central1-aviaterobotics.cloudfunctions.net/razorpayApi', requestOptions).then((t) =>
			t.json()
		)



		const options = {
			key: __DEV__ ? process.env.REACT_APP_RAZORPAY_TEST_API_KEY : process.env.REACT_APP_RAZORPAY_LIVE_API_KEY,
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: this.state.course.courseName,
			description: this.state.course.trainingOptions[this.state.index].name,
			image: this.state.course.courseDisplayImageUrl,
			handler: (response) => {
				var paymentDetails = {
					payment_id: response.razorpay_payment_id,
					order_id: response.razorpay_order_id,
					signature: response.razorpay_signature,
					paidFor: this.state.course.courseName,
					paidDate: Date().toLocaleString(),
					trainingMode: this.state.course.trainingOptions[this.state.index].name,
					amountPaid: this.state.course.trainingOptions[this.state.index].courseFee
				}
				this.verifySignature(response, data.id);
				const { personalDetails, socialMedia, workExperience, invoices, courseSubscriptions, certificates, badges, payments } = this.state.userInfo;
				this.props.firebase
					.user(this.props.authUser)
					.set({
						personalDetails,
						socialMedia,
						workExperience,
						invoices,
						courseSubscriptions: [...courseSubscriptions, this.state.course.courseName],
						certificates,
						badges,
						payments: [...payments, paymentDetails]
					})
				axios.get("https://aviaterobotics.firebaseio.com/payments/" + this.state.course.courseName + "/" + this.state.course.trainingOptions[this.state.index].name +"/"+ this.state.userInfo.personalDetails.email.split('@', 1) + ".json").then((user) => {
					// if (user.data == null) {
						axios.post("https://aviaterobotics.firebaseio.com/payments/" + this.state.course.courseName + "/" + this.state.course.trainingOptions[this.state.index].name + "/" + this.state.userInfo.personalDetails.email.split('@', 1) + ".json",
							{
								user: this.state.userInfo.personalDetails.userName,
								email: this.state.userInfo.personalDetails.email,
								mobile: this.state.userInfo.personalDetails.mobile,
								paymentInfo: paymentDetails
							}
						).then((success) => {
							console.log();
						}).catch((e) => {
							console.log(e);
						})
					// }
				}).catch((error) => {

				})
				
				// this.props.history.push('/payment-confirmed');
			},
			prefill: {
				name: this.state.userInfo.personalDetails.userName,
				email: this.state.userInfo.personalDetails.email,
				phone_number: this.state.userInfo.personalDetails.mobile
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()
	}

	render() {
		return (
			<div className="App">

				{this.state.paymentConfirmed
				?<PaymentConfirmation courseFee = {this.state.course.trainingOptions[this.state.index].courseFee} course ={this.state.course.courseName} trainingMode = {this.state.course.trainingOptions[this.state.index].name}/>
				:<button
					className="btn btn-success mt-3"
					onClick={this.displayRazorpay}
					target="_blank"
					rel="noopener noreferrer"
				>
					Pay  &#8377; {this.props.course.trainingOptions[this.props.index].courseFee}
				</button>}

			</div>
		)
	}
}

const RazorPayForm = compose(
	withRouter,
	withFirebase,
)(RazorPayFormBase);

export default RazorPayPage;
export { RazorPayForm };