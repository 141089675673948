/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {

    Button,
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Popover, PopoverHeader, PopoverBody 
} from "reactstrap";


import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// core components
import CourseContents from './courseContents';
import CoursesJson from './coursesJson';
import ToolsCoursesJson from './toolsCoursesJson';
import RequestCallBack from './courserequestcallback';
import { withAuthentication, withEmailVerification } from '../../session';
import { AuthUserContext } from '../../session';
import { compose } from 'recompose';
import Register from "./registerdemo";

class CourseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDemo: false,
            modalRegister: false,
            popoverOpen : false,
            setPopoverOpen : false,
            course: {
                certificationName: "",
                courseName: "",
                active: "",
                nextBatchStart: "",
                demoPosterUrl: "",
                courseDisplayImageUrl: "",
                navigateUrl: "",
                duration: "",
                prerequisites: "",
                youtubeUrl: "",
                courseDetails: {
                    courseDescription: {
                        name: "",
                        introduction: ""
                    },
                    instructor: {
                        name: "",
                        introduction: ""
                    },
                    handouts: {
                        name: "",
                        handouts: []
                    },
                    assignments: {
                        name: "",
                        assignements: []
                    }
                },
                relatedCourses: [],
                certificates: [],
                faq: [],
                projectDetails: [],
                trainingOptions: [],
                courseOverview: {
                    heading: "",
                    description: "",
                    keyFeaturesHeading: "",
                    courseKeyFeatures: [],
                    skillsCoveredHeading: "",
                    skillsCoveredList: []
                },
                benefits: {
                    title: "",
                    benefitsList: []

                },
                whyAviate: [],
                testimonials: [],
                courseCurriculum: {
                    heading: "",
                    description: "",
                    prerequisites: "",
                    courseContents: []
                }
            }
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
        var findCourse = "";
        if (window.location.pathname.startsWith("/internship/robotics")) {
            findCourse = CoursesJson.find(course => course.navigateUrl === window.location.pathname);
        }
        else if (window.location.pathname.startsWith("/courses/programming-application-tools-domain")) {
            findCourse = ToolsCoursesJson.find(course => course.navigateUrl === window.location.pathname);
        }
        this.setState({ course: findCourse })

    }
    
    toggleNextBatchStarts = () => this.setState({popoverOpen : !this.state.popoverOpen});

    toggleDemo = () => this.setState({ modalDemo: !this.state.modalDemo });

    toggleRegister = () => this.setState({ modalRegister: !this.state.modalRegister });

    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section section-lg section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <Container className="py-lg-md d-flex ">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="7">
                                        <h1 className="display-3 text-white mt-5">
                                            {this.state.course.certificationName}
                                        </h1>
                                        <p className="lead text-white">
                                            Prerequisites : {this.state.course.prerequisites}
                                        </p>
                                        <Row style={{ backgroundColor: "rgb(255,204,0)", marginLeft: "0.5%", marginRight: "0.5%", marginBottom: "5%" }} className=" floating">

                                            <Col lg="12">
                                                <div className="progress-wrapper">
                                                    <Row className="progress-info">
                                                        <Col lg="8" className="progress-label text-center ">
                                                            <h4>Register for a demo class!</h4>
                                                        </Col>
                                                        <Col lg="4" className="text-center"><Button
                                                            className="btn-black text-white btn-icon mb-3"
                                                            color="default"
                                                            onClick={this.toggleRegister}
                                                        >
                                                            <span className="btn-inner--icon mr-1">
                                                            </span>
                                                            <span className="btn-inner--text">
                                                                Register
                                                            </span>
                                                        </Button>
                                                            <Modal isOpen={this.state.modalRegister} modalTransition={{ timeout: 700 }} centered backdropTransition={{ timeout: 1300 }} toggle={this.toggleRegister}>
                                                                <ModalHeader toggle={this.toggleRegister}>{this.state.course.courseName} </ModalHeader>
                                                                <ModalBody className="text-left">
                                                                    <h4>What will you learn in the workshop?</h4>
                                                                    <ol className='description'>
                                                                        <li>10 Reasons why Robotics is the Future.</li>
                                                                        <li>Common misconceptions about Robotics.</li>
                                                                        <li>How important is it to learn <b>{this.state.course.courseName}</b>.</li>
                                                                        <li>Why Aviate Robotics is a ONE-STOP SOLUTION for Robotics enthusiastic students.</li>
                                                                        <li>Detailed discussion on the course syllabus.</li>
                                                                        <li>Importance of Aviate's Infographics</li>
                                                                    </ol>
                                                                    <Register courseName={this.state.course.courseName} />
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    <Button color="secondary" onClick={this.toggleRegister}>Cancel</Button>
                                                                </ModalFooter>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Button id="Popover1" type="button" className="floating mt-1 mb-5 pl-5 pr-5 ">
                                                Next batch starts in <span className="text-primary ml-3"><b>{this.state.course.nextBatchStart}</b></span>
                                            </Button>
                                            <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggleNextBatchStarts}>
                                                <PopoverHeader>New Batch Starts in    <b>{this.state.course.nextBatchStart}</b></PopoverHeader>
                                                <PopoverBody>Sessions will be conducted weekly twice in between 7-9 pm slots for this batch. Each session will be of 1 hour mostly and will be 2 hours for some sessions. Schedule will be given once you register and confirm the payment. </PopoverBody>
                                            </Popover>
                                        </div>
                                        <div className="btn-wrapper">
                                            <Button
                                                className="btn-icon mb-3 mb-sm-0"
                                                color="info"
                                                onClick={this.toggleDemo}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                </span>
                                                <span className="btn-inner--text">View Infographics</span>
                                            </Button>
                                            <Modal isOpen={this.state.modalDemo} modalTransition={{ timeout: 700 }} centered backdropTransition={{ timeout: 1300 }} toggle={this.toggleDemo}>
                                                <ModalHeader >{this.state.course.courseName}</ModalHeader>
                                                <ModalBody>
                                                    <div className="shadow-lg  bg-dark transform-perspective-top" style={{ padding: "3vh", marginBottom: "10vh", borderRadius: "3vh" }}>
                                                        <video width="100%" height="auto" type="video/mp4" autoplay controls loop controlsList="nodownload nofullscreen nopictureinpicture" src={this.state.course.demoPosterUrl} />
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" onClick={this.toggleDemo}>Cancel</Button>
                                                </ModalFooter>
                                            </Modal>
                                            <Button
                                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                                color="default"
                                                href="#payment"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                </span>
                                                <span className="btn-inner--text">
                                                    Enroll Now
                                                 </span>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg="1">

                                    </Col>
                                    <Col lg="4">
                                        <AuthUserContext.Consumer>
                                            {authUser => authUser
                                                ? <RequestCallBack userDetails={authUser.personalDetails} course={this.state.course.courseName} />
                                                : <RequestCallBack userDetails={null} course={this.state.course.courseName} />}
                                        </AuthUserContext.Consumer>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>

                    </section>
                    <section className="section section-lg" >
                        <CourseContents course={this.state.course} />
                    </section>

                </main>
            </>
        );
    }
}

export default compose(
    withEmailVerification, withAuthentication)(CourseDetail);
