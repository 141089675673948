/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";
// core components

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section pb-10 pt-0">
                        <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                            <div className="mt-5 ">
                                <h3 className="">Aviate Privacy Policy</h3>
                                <p>Last Updated: Sep 5, 2020</p>
                                <p className="description"><i>Our mission at Aviate is to provide access to high-quality Technical courses for everyone, everywhere at affordable cost.</i></p>
                                <p>Aviate is a good adherent of the Student Privacy Pledge to safeguard student privacy.</p>
                            </div>
                            <div className="mt-5">
                                <h3>Aviate Privacy Principles</h3>
                                <div className="pl-5 pr-5">
                                    <p><b>Our Privacy Policy is charged by our commitment to the following Privacy Principles:</b></p>
                                    <ul>
                                        <li className="mt-3 description">
                                            We’re deeply committed to creating a safe and secure online environment for you.
                                    </li>
                                        <li className="mt-3 description">
                                            We do not sell your personal information to third parties.
                                    </li>
                                        <li className="mt-3 description">
                                            We strive to provide you with access to and control over the information you give us, and we take the protection of your information very seriously.
                                    </li>
                                        <li className="mt-3 description">
                                            We do not display third party advertising on Aviate. Our mission is to provide you with a better learning experience.
                                    </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mt-5">
                                <h3>About Aviate and our Privacy Policy</h3>
                                <div className="pl-5 pr-5">
                                    <p className="description"> Aviate, Inc. (”Aviate”,”Aviate Robotics” “us”, or “we”) feels strongly about protecting your privacy.
                                        We understand how important privacy is to you, and we are committed to creating a safe and secure
                                        environment for learners of all ages. This Privacy Policy applies to aviaterobotics.com, and online
                                        services (collectively, our “Service”), which are all owned and operated by Aviate.
                                        This Privacy Policy describes how Aviate collects and uses the information you provide on our Service,
                                        and describes the choices available to you regarding our use of your personal information and
                                    how you can access and update this information.</p>
                                    <p className="description" >We use the data we collect to provide you with a better experience and fulfill our mission of providing
                                    access to high-quality Technical courses for everyone, everywhere at affordable cost. </p>
                                    <p className="description">Our Privacy Policy describes what information we gather from our members and others who visit and use our Service,
                                        how we use that information, and what we do to protect it. By visiting or using our Service, you consent to the
                                    information handling practices described in this Privacy Policy.</p>
                                    <p className="description">Your use of our Service and any information you provide on our Service are subject to the terms of this Privacy Policy, which is incorporated into and subject to our Terms of Service. Any capitalized terms used but not defined within this Privacy Policy have the meaning given to them in our Terms of Service.", tos_url=("/termsofuse"))</p>
                                </div>
                            </div>

                                <div className="mt-5">
                                    <p className="description">Please read this Privacy Policy carefully, as it governs how you use Aviate or its affiliate products. If you do not agree to this Privacy Policy, please do not use Aviate.</p>
                                    <p><b>Notice to End Users</b></p>
                                    <div className="pl-5 br-5">
                                        <p className="description ">In some cases an administrator may create an account in Aviate on the behalf of a “End-User” and may provide his/her information, including Personal Information. In such cases the administrator who may be your Employer or a Aviate Authorised Training partner (ATP) is our “Customer.” We collect Information under the direction of our customers and have no direct relationship with the End-User whose personal data we process.</p>
                                        <p className="description">A End-user’s use of the Aviate platform may be subject to the Customer’s policies, if any.</p>
                                        <p className="description">Our Customer must have the authority to act on behalf of the End-User and to consent to the collection and use of their Personal Information as described in this Privacy Policy.</p>
                                        <p className="description">If you are an End-user using our platform, please direct your privacy inquiries to your administrator. Aviate is not responsible for the privacy or security practices of our customers, which may differ from those set forth in this privacy statement.</p>
                                        <p className="description">Aviate does not send personalized advertisement or Marketing communication to end users.</p>
                                    </div>
                                    <p><b>Use of your Personal Information and Other Data</b></p>
                                    <p className="description pl-5 br-5">Aviate uses this Privacy Policy to govern the use of personal information you provide to us. We use your personal information solely to provide our services to you. We  use your Personal Identifiable Information (PII) to help us better understand our users and to improve the content and functionality of our site.</p>

                                    <p><b>Personal Identifiable Information</b></p>
                                    <p className="description pl-5 br-5">We may collect the following personal information from you in the following situations</p>
                                    <div className="pl-5 br-5">
                                        <ul >
                                            <li><p className="description">When you visit our website  and voluntarily provide the following information via one of our contact forms, via a chat or phone session, or as part of a purchase of one of our courses or register on our site:</p>
                                                <ul >
                                                    <li><p className="description">Contact Information,such as name, email address, mailing address, phone number, IP</p></li>
                                                    <li><p className="description">Information about your business, such as company name, company size, business type</p></li>
                                                    <li><p className="description">Billing Information, such as credit card number and billing address;</p></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="pl-5 br-5"><i>Note : All payment transactions are processed through secure payment gateway providers.</i></p>
                                    <p className="description pl-5 br-5">Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>

                                    <p><b>Use</b></p>
                                    <p className="description pl-5 br-5">We may use the personal information collected from within the Websites or when you use the Service(s) to:</p>
                                    <div className="pl-5 br-5">
                                        <ul>
                                            <li><p className="description">Provide you with the Service(s).</p></li>
                                            <li><p className="description">Send you communication from the Service(s)</p></li>
                                            <li><p className="description">Assess the needs of your business to determine or suggest suitable products.</p></li>
                                            <li><p className="description">Send you requested product or service information.</p></li>
                                            <li><p className="description">Respond to customer service requests.</p></li>
                                            <li><p className="description">Administer your account.</p></li>
                                            <li><p className="description">Send you promotional and marketing communications.</p></li>
                                            <li><p className="description">Facilitate your transactions with other users when you use our Service(s).</p></li>
                                            <li><p className="description">Perform statistical analyses of user behaviour and characteristics at an aggregate level in order to measure interest in and use of the various areas of the site.</p></li>
                                        </ul>
                                    </div>
                                    <p className="description pl-5 br-5">When you provide us with personal information about your contacts we will only use this information for the specific reason for which it is provided.</p>

                                    <p><b>Share</b></p>
                                    <div className="pl-5 br-5">
                                        <p className="description">If your subscription was provided by your employer or a Aviate Authorised training partner, we will share information, including personal information with them.</p>
                                        <p className="description">We may transfer personal information to our Group Companies and sub-contractors that help us provide our Service(s). Transfers to subsequent third parties are covered by the service agreements with our data-processors. We reserve the right to disclose your personal data as required by applicable law, in response to lawful requests by public authorities, including meeting national security or law enforcement requirements and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or other legal process served on us.</p>
                                        <p className="description">In the event Aviate goes through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account and personal data will likely be among the assets transferred. You will be notified via email and via a prominent notice on our Websites of any such change in ownership or control. We may also disclose your personal information to any other third party with your prior consent.</p>
                                    </div>


                                    <p><b>Data from Third Parties</b></p>
                                    <p className="description pl-5 br-5">We also obtain data from third parties. We protect data obtained from third parties according to the practices described in this statement, plus any additional restrictions imposed by the source of the data. These third-party sources vary over time, but have included:</p>
                                    <div className ="pl-5 br-5">
                                    <ul>
                                        <li><p className="description">Data brokers from which we purchase demographic data to supplement the data we collect.</p></li>
                                        <li><p className="description">Social networks when you grant permission to access your data on one or more networks.</p></li>
                                        <li><p className="description">Existing or past learners who participate in our referral program</p></li>
                                        <li><p className="description">Service providers that help us determine a location based on your IP address in order to customize certain products to your location.</p></li>
                                        <li><p className="description">Partners with which we offer co-branded services or engage in joint marketing activities, and</p></li>
                                        <li><p className="description">Publicly-available sources such as open government databases or other data in the public domain.</p></li>
                                    </ul>
                                    </div>
                                    <p className="description pl-5 pr-5">We may receive Personal Information about you from other sources like postal mail, telephone or fax. We may add this information to the information we have already collected from you via the Site in order to improve the products and services we provide.</p>
                                    
                                    <p><b>Correction, Removal or Export of Personal Information</b></p>
                                    <p className="description pl-5 br-5">Aviate acknowledges that you have the right to access, edit and delete your personal information at any time. Upon request, we will provide you with reasonable access to the personal information we collect about you. You will have the opportunity to correct, update, modify or delete this information as follows:</p>
                                    <div className ="pl-5 br-5">
                                    <ul>
                                        <li><p className="description"><b>If you have independently signed up with Aviate.</b><br/>
                                            You may access, update or export your Personal Information included in your online Aviate account at any time by logging into your account and going to your profile or by reaching out to us</p></li>
                                       </ul>
                                    </div>

                                    <p><b>Exceptions in Deletion:</b></p>
                                    <p className="description pl-5 br-5">If you request access to your personal information and discover that that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes. We aim to maintain our services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our services, we may not immediately delete residual copies from our active servers or backup systems</p>
                                    
                                    <p><b>Exception in Data Portability:</b></p>
                                    <p className="description pl-5 br-5">There may be limits to the amount of information we can practically provide. For example, we may limit an individual’s access to personal information where the burden or expense of providing access would be disproportionate to the risks to the individual’s privacy or where doing so would violate others’ rights.</p>
                                    
                                    <p><b>Exception in Data Correction: </b></p>
                                    <p className="description pl-5 br-5">Even after updates to your Personal Information, we may maintain and store older versions of such information within our databases, access logs and other records in order to comply with other routine data audits.</p>
                                    

                                    <p><b>Cookies and Other Tracking Technologies</b></p>
                                    <p className="description pl-5 br-5">Aviate and our third-party partners, such as our advertising and analytics partners, (listed here) use cookies and other tracking technologies (e.g., web beacons, device identifiers and pixels) to provide functionality and to recognize you across different Services and devices.</p>
                                    <p className="description pl-5 br-5">The types of cookies used by Aviate includes</p>
                                    <div className ="pl-5 br-5">
                                    <ul>
                                        <li><p className="description"><b>Essential Cookies:</b> These cookies are essential for the basic functionalities offered by the Site and Service(s). These class of cookies helps in keeping a user logged in to the Service(s) and remember relevant information when they return to the Service(s).</p></li>
                                        <li><p className="description"><b>Insight Cookies:</b> These are used for tracking the user activities within the Service(s), which in turn helps us in improving your user experience.</p></li>
                                        <li><p className="description"><b>Marketing Cookies:</b> These are used for providing you with customized and interest-based ads based on your browsing behavior and other similar activities on our Websites.</p></li>
                                    </ul>
                                    </div>
                                    <p className="description pl-5 br-5">You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since each browser is different, look at your browser's Help Menu to learn the correct way to modify your cookies.</p>
                                    <p className="description pl-5 br-5">If you turn cookies off, some of the features that make your site experience more efficient may not function properly.</p>
                                
                                    <p><b>Advertisement</b></p>
                                    <p className="description pl-5 br-5">We partner with third parties to manage our advertising on other sites. Our third party partners may use technologies such as cookies to gather information about your activities on our Website and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking here. Please note this does not opt you out of being served ads. You will continue to receive generic ads.</p>
                                
                                    <p><b>Do Not Track.</b></p>
                                    <p className="description pl-5 br-5">Currently, various browsers — including Internet Explorer, Firefox, and Safari — offer a “do not track” or “DNT” option that relies on a technology known as a DNT header, which sends a signal to Web sites’ visited by the user about the user's browser DNT preference setting. Aviate does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. Aviate takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.</p>
                                
                                    <p><b>Links to Third Party Sites</b></p>
                                    <p className="description pl-5 br-5">Our Websites contain links to other websites that are not owned or controlled by Aviate. Please be aware that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to be aware when you leave our Websites and to read the privacy policies of each and every website that collects personal information.</p>
                                
                                    <p><b>Single Sign-On & Other social Media Features</b></p>
                                    <p className="description pl-5 br-5">Our Websites include social media features, such as the Facebook “Like” button, the “Share This” button or interactive mini-programs. Where we have your consent, these features may collect your IP address, which page you are visiting on our Websites, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Websites. Your interactions with these features are governed by the privacy policy of the company providing them.</p>
                                    <p className="description pl-5 br-5">You can log in to our Websites using sign-in services by LinkedIn. The services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address.</p>

                                
                                    <p><b>Information Security</b></p>
                                    <p className="description pl-5 br-5">Our Websites and Service(s) have industry standard security measures in place to protect against the loss, misuse, and alteration of the information under our control. When you provide us with sensitive information (such as credit card information or login credentials), we will encrypt that information via Secure Socket Layer (SSL).</p>
                                    <p className="description pl-5 br-5">Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Websites or via the Service(s) and any transmission is at your own risk. Once we receive your personal information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                                    <p className="description pl-5 br-5">We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
                                    <div className ="pl-5 br-5">
                                    <ul>
                                        <li><p className="description">Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p></li>
                                        <li><p className="description">Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</p></li>
                                        <li><p className="description">All payment transactions are processed through secure gateway providers and are not stored or processed on our servers</p></li>
                                    </ul>
                                    </div>
                                
                                    <p><b>Testimonials</b></p>
                                    <p className="description pl-5 br-5">We post customer testimonials/comments/reviews on our Websites which may contain personal information. Prior to posting the testimonial, we obtain the customer's consent to post their name along with their testimonial. If you want your testimonial removed, please reach out to us</p>
                                
                                    <p><b>Children's Personal Information</b></p>
                                    <p className="description pl-5 br-5">Aviate does not knowingly collect any personal information from children under the age of 16. If you are under the age of 16, please do not submit any personal information through our Websites or Service(s). We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide personal information through our Service(s) or Websites without their permission. If you have reason to believe that a child under the age of 16 has provided personal information to us through our Websites or Service(s), please contact us and we will endeavor to delete that information and terminate the child's account from our databases.</p>
                                
                                    <p><b>Amendments</b></p>
                                    <p className="description pl-5 br-5">Aviate has the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. Your continued use of this Websites or the Service(s) following the posting of any amendment, modification, or change to this Policy shall constitute your acceptance of the amendments to this Policy. You can choose to discontinue use of the Websites or Service(s), if you do not accept the terms of this Policy, or any modified version of this Policy.</p>
                                
                                    <p><b>Contacting Aviate</b></p>
                                    <p className="description pl-5 br-5">If you have any questions about this privacy policy or your dealings with the Aviate,You can also reach out to us through Email at <a href="mailto:team@aviaterobotics.com">team@aviaterobotics.com</a>.</p>
                                </div>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}

export default PrivacyPolicy;