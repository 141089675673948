import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  Col,
  Media
} from "reactstrap";
// core components
import { withAuthentication } from '../../session';

class CoursesDisplayCard extends React.Component {
  componentDidMount() {

  }

  render() {
    var navigateUrl = "";
    if(this.props.element.courseName === "Robotics Modelling and Control using MATLAB" || this.props.element.courseName === "Image Processing Theory and Practice using matlab"){
      navigateUrl = ""
    }else{
      navigateUrl = this.props.element.navigateUrl
    }
    return (
      <>
        <Col key={this.props.i} lg="4" className="mt-5">
          <Media  href={navigateUrl} >
            <Card className="card-lift--hover shadow border-0 pb-10"  >
              <CardImg alt="..." height="100vh" width="100vw" className="mt-3" src={this.props.element.courseDisplayImageUrl} top />
              <CardBody className="py-3">
                <h6 className="text-primary text-uppercase text-center" style={{height : "12vh"}}>
                  {this.props.element.courseName}
                </h6>
                <h6 className="mt-3 text-left" style={{height : "15vh"}}>
                  {this.props.element.certificationName}
                </h6>
                <div>
                  <Badge color="success" pill className="mr-1 mb-5">
                    {this.props.element.duration}
                  </Badge>
                  {navigateUrl===""?<div style={{ backgroundColor: "rgb(255,204,0)"}} className="text-center floating">
                                                    <h6 className="">Launching Soon...!</h6>
                                                </div>:""}
                </div>
              </CardBody>
            </Card>
          </Media>
        </Col>
      </>
    )
  }
}
export default withAuthentication(CoursesDisplayCard);