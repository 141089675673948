import React, { Component } from 'react';
// import { Card } from 'primereact/card';
import Expand from 'react-expand-animated';
import {
    Container,
    Row,
    Col,
    Card
} from "reactstrap";

class FAQCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    render() {
        return (
            <section className="bg-secondary">
                <Container fluid = {true} style={{ paddingLeft: "8%", paddingRight: "8%" }} >
                    <Row >
                        <Card className="mt-3 mb-3">
                            <Row>
                                <Col lg="1" sm = "2" xs ="2"  className="order-md-1">
                                     <img alt="Card" className = "mt-3 mb-3 ml-3" width ="30vw" height = "30vh"  onClick={this.toggle} src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Ffaq.svg?alt=media&token=1ba8fcfc-f9ae-4a0b-b062-aeb80a348d0a" />
                                </Col>
                                <Col lg="10" sm ="9" xs ="8" className="order-md-2">
                                <p className = "mt-3 mb-3 text-justify" onClick={this.toggle}> {this.props.cardDetails.question}</p>
                                    <Expand open={this.state.open}>
                                        <p> {this.props.cardDetails.answer}</p>
                                    </Expand>
                                </Col>
                                <Col lg="1" sm = "1" xs= "1" className="order-md-3">
                                    <img  alt="Card"  className = "mt-3 mb-3 ml--3"  width ="30vw" height = "30vh" onClick={this.toggle} src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Fexpand-arrow.svg?alt=media&token=f84ad2b2-0eb7-4815-aa61-c4af185eb382" />
                                </Col>
                            </Row>
                        </Card>

                    </Row>
                    </Container>
                    </section>
        )
    }
}

export default FAQCard;