import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import { Row, Container, Col ,Button} from 'reactstrap';


const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');


const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase.doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <section className="section section-lg section-shaped ">
                <div className="shape shape-style-1 shape-default">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="py-lg-md d-flex text-white" fluid="true" style={{ height:"80vh",marginTop:"5%", paddingLeft: "10%", paddingRight: "10%" }}>
                  <div className="col px-0">
                    <Row>
                      <Col lg="2" className="order-md-1"></Col>
                      <Col lg="8" className="order-md-2 mt-5 text-center">
                        {this.state.isSent ? (
                          <div>
                          <h1 className="text-white">E-Mail confirmation sent again</h1> 
                          <h6 className="text-white">Check your E-Mails (Spam
                            folder included) for a confirmation E-Mail.
                            Refresh this page once you confirmed your E-Mail.
                          </h6>
                          </div>
                        ) : (
                          <div>
                            <h1 className="text-white">
                              Please Verify your E-Mail to continue 
                            </h1>
                            <h6 className="text-white">Check your E-Mails (Spam folder
                              included) for a confirmation E-Mail or send
                              another confirmation E-Mail. Refresh this page once you confirmed your E-Mail.
                            </h6> 
                            </div>
                          )}

                        <Button
                          type="button"
                          onClick={this.onSendEmailVerification}
                          disabled={this.state.isSent}
                          className="mt-5"
                        >
                          Resend confirmation E-Mail
                        </Button>
                      </Col>
                      <Col lg="2" className="order-md-3"></Col>

                    </Row>
                  </div>
                </Container>
              </section>
            ) : (
                <Component {...this.props} />
              )
          }

        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;