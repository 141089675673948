const testimonialsJson = {
    videoTestimonials :[
        {
            name : "Yashwanth Borse",
            review : "Aviate Robotics gives us a chance to upgrade our skills and stand confidently to tackle the competitive world. I want to express my gratitude to our mentor sir Ravi teja for the efforts he has taken during the online sessions. I appreciate the way the doubts he cleared doubts of every student. I would surely suggest Aviate Robotics platform without any doubt to anyone who wants to be on the top of his career.",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FYashwanth%20Borse_Final.mp4?alt=media&token=61c2fd9e-aa52-4945-840b-4e49da873a76"
        },
        {
            name : "Kanuparthi Sakya",
            review : "The course was just seems so intresting. The whole learning process was fun and interactive. We had no doubts left at the end of the sessions and they are very understanding. I can easily recommend anyone interested in robotics to take up one of their courses.",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FSakya_Final.mp4?alt=media&token=161c8362-832b-420d-a3e1-75fd8434d1b4"
        },
        {
            name : "Jyothi Swaroop",
            review : "I had learnt about various micro controller boards like arduino and also worked on single board computers like raspberry pi. I was also taught basics on image processing. I thank the entire team of Aviate Robotics for taking in very much good efforts and making us understand the concepts.",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FSwaroop_Final.mp4?alt=media&token=de9c4f4e-2eff-442c-924f-8a1b020a6c78"
        },
        {
            name : "Aishwarya Shukla",
            review : "I have enrolled the online internship course in Aviate Robotics and I learned so many things like programming, practically how a proejct works. The trainers are highly supportive and I recommend everyone to enroll in this course and get  such a experience. ",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FAishwarya_SHukla.mp4?alt=media&token=0f91a554-feab-4b25-8e77-de6155124ffd"
        },
        {
            name : "Yashwanth",
            review : "The instructors are very good at teaching and taught us everything from scratch as they promised at the beginning of the course. The hand on sessions were really amazing. I m really happy learning at aviate.",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FYashwanth_Final.mp4?alt=media&token=1c5fabdd-3447-4dcd-997b-93e0ddad9015"
        },
        {
            name : "Ashish Solanki",
            review : "My experience with aviate was good and things i like the most is focus on practical knowledge more than theoritical.",
            videoUrl : "https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/testimonials%2FAshish%20Solanki_Final.mp4?alt=media&token=fa6c49c8-c1ec-4e6e-a8db-76c1311e6c48"
        },

],
textTestimonials : [
    {
        name : "Saadia Hassan",
        course : "",
        review : "The journey with Aviate was very insightful. The mentors were always ready to clarify any kind of vacuous doubts.There can't be a better place than Aviate to start your journey of actual learning. ",
        designation : "",
        rating : ""
    },
    {
        name : "Vishnu",
        course : "",
        review : "The course is really helpful for beginners who are interested to learn robotics. I would recommend this course provided by Aviate Robotics for thosewho are really interested in robotics.",
        designation : "",
        rating : ""
    },
    {
        name : "Vinay",
        course : "",
        review : "Aviate Robotics helped me building basics on robotics. The course structure was very good. They are good at teaching programming. Good experience learning with aviate.",
        designation : "",
        rating : ""
    },
    {
        name : "Mounika",
        course : "",
        review : "Had a great journey with Aviate Robotics. The course provided precise content and will be a basic guide for those beginners like me. Great Experience",
        designation : "",
        rating : ""
    },
]
}
export default testimonialsJson;