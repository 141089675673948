/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { AuthUserContext } from '../../session';
import SignOutButton from '../../views/examples/signout';
// import * as ROLES from '../../constants/roles';
import { withAuthentication } from '../../session';


const Navigation = (props) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) =>
  (
    <Nav className="navbar-nav-hover align-items-lg-left ml-lg-auto mt-3"  navbar>      
      <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <span style={{fontSize :"16px", color:"rgb(3,3,3)"}} >{authUser.displayName }</span>
          <i style={{fontSize :"16px", color:"rgb(3,3,3)"}} className="ni ni-circle-08 ml-3"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-md">
          <div className="dropdown-menu-inner">
          {/* {!!authUser.roles[ROLES.ADMIN] && (
            <div className="d-flex align-items-center ml-3 text-primary text-center"><Link to="/admin">Admin</Link></div>
          )} */}
            <div className="d-flex align-items-center ml-3 mt-2 text-primary text-center"><a href="/profile">Dashboard</a></div>
            <div className="d-flex align-items-center ml-3 mt-2 text-primary text-center"><a href="/profile">My Courses</a></div>
            <div className="d-flex align-items-center ml-3 mt-2 text-primary text-center"><a href="/profile">My Certificates</a></div>
            <div className="d-flex align-items-center ml-3 mt-2 text-primary text-center"><Link to="#" ><SignOutButton /></Link></div>
         </div>
        </DropdownMenu>
      </UncontrolledDropdown>
      
    </Nav>
  );

const NavigationNonAuth = () => (
  <Nav className="align-items-lg-right ml-lg-auto  mt-3" navbar>
    <NavItem>
      <NavLink className="nav-link-icon ml-1  mr-1 " href="/login" style={{fontSize :"16px", color:"rgb(3,3,3)"}} >Sign In</NavLink>
    </NavItem>
    <NavItem>
      <NavLink className="nav-link-icon ml-1  mr-1 " href="/register" style={{fontSize :"16px", color:"rgb(3,3,3)"}} >Sign Up</NavLink>
    </NavItem>
  </Nav>
);

class DemoNavbar extends React.Component {
  componentDidMount() {
    
  }
  

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-light"
            expand="lg"
            id="navbar-main"
          >
            <Container  className="text-dark mt--2">
              <NavItem className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Flogo.png?alt=media&token=f488d9ba-ce1d-409e-bfd2-90198adf104c"
                  width ="180"
                  height = "60"
                   />
              </NavItem>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <NavLink href="/">
                        <img
                          alt="..."
                          src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Flogo.png?alt=media&token=f488d9ba-ce1d-409e-bfd2-90198adf104c"
                          width ="180"
                          height = "60"
                        />
                      </NavLink>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center mt-3" navbar>
                  <UncontrolledDropdown nav >
                    <DropdownToggle nav>
                      <span className="nav-link-icon ml-1  mr-1 mt-2" style={{fontSize :"16px", color:"rgb(3,3,3)"}} >Courses</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl" style={{zIndex:2}}>
                      <div className="dropdown-menu-inner"> 
                          <Media  href="/internship/robotics"
                            className="d-flex align-items-center"
                          >
                            <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                              <i className="ni ni-spaceship" />
                            </div>
                            <Media body className="ml-3">
                              <h5 className="heading text-warning mb-md-1">
                                Robotics
                            </h5>
                              <p className="description d-none d-md-inline-block mb-0">
                                Learn how to assemble robotic hardware, write logic, deploy the code, test functionality and finally build project.
                            </p>
                            </Media>
                          </Media>
                          <Media href="/courses/programming-application-tools-domain"
                            className="d-flex align-items-center mt-3"
                          >
                            <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                              <i className="ni ni-laptop" />
                            </div>
                            <Media body className="ml-3">
                              <h5 className="heading text-warning mb-md-1">
                                Programming and Application Tools
                            </h5>
                              <p className="description d-none d-md-inline-block mb-0">
                                Learn more about wide variety of programming and application tools,
                                core functionalities of tools and welcome them to your professional career.
                            </p>
                            </Media>
                          </Media>
                        {/* <Link to="#">
                          <Media
                            className="d-flex align-items-center mt-3"
                          >
                            <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                              <i className="ni ni-ui-04" />
                            </div>
                            <Media body className="ml-3">
                              <h5 className="heading text-warning mb-md-1">
                                K12 Learning (Expected Soon!)
                            </h5>
                              <p className="description d-none d-md-inline-block mb-0">
                                Launching K12 curriculum wise syllabus soon!.
                            </p>
                            </Media>
                          </Media>
                        </Link> */}
                      </div>
                    </DropdownMenu>

                  </UncontrolledDropdown>
                  {/* <NavItem>
                    <NavLink to="/" className="nav-link-icon" tag={Link}> AR Club</NavLink>
                  </NavItem> */}
                  <NavItem> 
                    <Media href="/edutainment" className="nav-link-icon ml-1  mr-1" ><span style={{fontSize :"16px", color:"rgb(3,3,3)"}} >Edutainment</span></Media>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className="nav-link-icon" to='/discussion-forum' tag ={Link}
                    >Roboticists</NavLink>
                  </NavItem> */}
                </Nav>
                <Navigation />
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

// export default compose(
//   withEmailVerification,withAuthentication)(DemoNavbar);

export default withAuthentication(DemoNavbar);