/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container, Row, Col
} from "reactstrap";
// core components

class Investor extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <main className="profile-page" ref="main">
                    <section className="section pb-10 pt-0">
                        <Container fluid={true} style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                            <div className="mt-5 ">
                                <h3 className="text-center">Our Investor</h3>
                                <Row>
                                    <Col lg="4" className="order-md-1"></Col>
                                    <Col lg="4" className="order-md-2 text-center">
                                        <img alt ="investor" className="mt-5 mb-5" height = "200vh" width = "200vh" style={{borderRadius: "200%"}} src="https://firebasestorage.googleapis.com/v0/b/aviaterobotics.appspot.com/o/assets%2Finvestor.jpg?alt=media&token=877e586a-6431-4194-b2f9-254b9c48cc15" />
                                    </Col>
                                    <Col lg="4" className="order-md-3"></Col>
                                </Row>
                                <h6 className="text-center mb-5">Linkedin Profile : <a target = "blank" href="https://www.linkedin.com/in/dr-ayyagari-rama-murthy-ph-d-2a0389b">Dr. Ayyagari Rama Murthy pH.D</a></h6>
                                <p className="pl-2 pr-2">I love to Dig Deep into problems in Pharma Companies and try to solve them with Modern Technology. I always find my way helping young minds who are passionate enough to solve the real world problems. I hold a pHD in Chemistry with a 24 years of experience in Analytical development Laboratory/Department with great Organizations like Aurobindo Pharma Research Center (APLRC),Cadila Pharmaceuticals Limited, Alembic Research Center, Sun Pharmaceuticals Research Center(SPARC), Oman Pharmaceuticals Products L.L.C (Sultanate of Oman; Salalah).</p>
                                <p className="pl-2 pr-2">My experience with lot of laboratories introduced many pain points and hurdles where a modern and advanced technology is the solution which is a different field of study for me. So being a Chemistry pHD Candidate I strongly believe that Modern Laboratories with more advanced technology with young, talented & dynamic generation can bring out new inventions quick and hustle free. </p>
                                <p className="pl-2 pr-2">As a Constant Technology peer with my diverse experience in Marketing , Research and Development made me to invest in AVIATE ROBOTICS PRIVATE LIMITED which would become the next big change of Robotics in India which is the next Future we are waiting for.</p>
                            </div>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}

export default Investor;