import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row } from "reactstrap";
import { AuthUserContext } from '../../session';
import CoursesJson from '../examples/coursesJson';
import CoursesDisplayCard from "../examples/CoursesDisplayCard";


class CourseSubscriptions extends Component {

    componentDidMount() {

    }

    render() {
        return <section >
            <Container fluid={true} className="pb-250 pt-5" style={{ height: "auto" }}>
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? <div className="text-center" style={{ marginBottom: "5vh" }}>
                            <Row style={{ marginBottom: "5vh" }}>
                                {CoursesJson.map((element, i) => {
                                    return authUser.courseSubscriptions.indexOf(element.courseName) !== -1 ? <CoursesDisplayCard key={i} element={element} i={i} /> : ""
                                })}
                            </Row>
                            {authUser.courseSubscriptions.length < 2 
                            ? <div className="text-center">
                                <h5>Your Courses shelf is waiting for you to fill it.</h5>
                                <p>Why not pick something to learn from our courses and get certified?</p>
                                <Button className="btn-primary" ><Link to="/internship/robotics">View All Courses</Link></Button>
                            </div> 
                            :  <Button className="btn-primary" ><Link to="/internship/robotics">Enroll other Courses</Link></Button>
                        }
                            
                        </div> : ""}
                </AuthUserContext.Consumer>
            </Container>
        </section>
    }
}

export default CourseSubscriptions;